import { createWebHistory, createRouter } from "vue-router";

// All Pages
import HomePageOne from '../components/pages/HomePageOne'
import AboutPage from '../components/pages/AboutPage'
import PortofolioPage from '../components/pages/Portofolio'
import SingleCaseStudy from '../components/pages/SingleCaseStudy'
import Hosting from '../components/pages/Hosting'
import VpsServers from '../components/pages/VpsServers'
import CloudServers from '../components/pages/CloudServers'
import PrivateServers from '../components/pages/PrivateServers'
import Services from '../components/pages/MiskServices'
import DevelopmentPage from '../components/pages/DevelopmentPage'
import SeoPage from '../components/pages/SeoPage'
import MobileDevelopment from '../components/pages/MobileDevelopment'
import BrandIdentityDesign from '../components/pages/BrandIdentityDesign'
import CyberSecurity from '../components/pages/CyberSecurity'
import DigitalMarketing from '../components/pages/DigitalMarketing'
import Jobs from '../components/pages/Jobs'
import RoleOverview from '../components/pages/RoleOverview'
import OverviewTab from '../components/Jobs/OverviewTab'
import Application from '../components/RoleOverview/Application'
import Products from '../components/pages/Products'
import ResetPassword from '../components/pages/ResetPassword'
import Subscribe from '../components/pages/Subscribe'
import SetUpDomain from '../components/pages/SetUpDomain'
import PersonalDetails from '../components/pages/PersonalDetails'
import ELearning from '../components/pages/ELearning'
import ProjectManagement from '../components/pages/ProjectManagement'
import QualityManagement from '../components/pages/QualityManagement'
import Testmonial from '../components/pages/Testmonial'
import ContactPage from '../components/pages/ContactPage'
import LogInPage from '../components/pages/LogInPage'
import SignUpPage from '../components/pages/SignUpPage'
import PrivacyPolicyPage from '../components/pages/PrivacyPolicyPage'
import ErrorPage from '../components/pages/ErrorPage'

const routes = [
    {path: '/', component: HomePageOne},
    {path: '/about', component: AboutPage},
    {path: '/portofolio', component: PortofolioPage},
    {path:'/single-case-study',component:SingleCaseStudy},
    {path:'/hosting',component:Hosting},
    {path:'/vps-servers', component:VpsServers},
    {path:'/cloud-servers',component: CloudServers},
    {path:'/private-servers',component: PrivateServers},
    {path:'/services',component: Services},
    {path:'/services-development',component: DevelopmentPage},
    {path:'/services-seo',component: SeoPage},
    {path: '/services-mobile-development' , component:MobileDevelopment},
    {path:'/services-brand-design',component: BrandIdentityDesign},
    {path:'/services-cyber-security',component: CyberSecurity},
    {path:'/services-digital-marketing', component:DigitalMarketing},
    {path:'/jobs', component:Jobs},
    {path:'/job-overview', component: RoleOverview},
    {path:'/reset-password', component:ResetPassword},
    {path:'/subscribe', component: Subscribe},
    {path:'/setup-domain' , component: SetUpDomain},
    {path:'/personal-details' , component:PersonalDetails},
    {path:'/testmonial' , component:Testmonial},
    {
      path: '/overview/:tabName', // Use a dynamic segment to capture the tab name
      name: 'overview',
      component: OverviewTab,
      props: true // Pass route params as props to the component
    },
    {
      path: '/application',
      name: 'application',
      component: Application
    },
    {
      path: '/e-learning', component : ELearning
    },
    {
      path: '/project-management', component : ProjectManagement
    },
    {
      path: '/quality-management', component : QualityManagement
    },
    {path: '/products', component: Products},

    {path: '/contact', component: ContactPage},
    {path: '/log-in', component: LogInPage},
    {path: '/sign-up', component: SignUpPage},

    {path: '/privacy-policy', component: PrivacyPolicyPage},
    {path: "/:catchAll(.*)", component: ErrorPage},
]
    
const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
    scrollBehavior() {
      return { top: 0 };
    },
  });
  
export default router;