<template>
    <div class="container pb-100">
        <div class="contact-section">
            <div>
                    <div class="contact-text">
                        <h3>let's Talk</h3>
                    </div>

                    <div class="contact-form">
                        <form id="contactForm">
                            <div class="row ">
                                <div class="col-lg-6 ">
                                    <div class="form-group">
                                        <label><img src="../../assets/images/user-icon.svg"></label>
                                        <input type="text" name="name" id="name" class="form-control icon-padding" required placeholder="Your Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 mt-3 mt-lg-0">
                                    <div class="form-group">
                                        <label><img src="../../assets/images/email-icon.svg"></label>
                                        <input type="email" name="email" id="email" class="form-control icon-padding" required placeholder="Your Email">
                                    </div>
                                </div>
                                <div class="col-lg-6 mt-3 mt-lg-0">
                                    <div class="form-group">
                                    <label><img src="../../assets/images/phone-icon.svg"></label>
                                    <input type="tel" name="phone" class="form-control icon-padding" id="phone" required placeholder="Your  phone">
                                    </div>
                                    
                                </div>
                                <div class="col-lg-6 mt-3 mt-lg-0">
                                    <div class="form-group">
                                        <label class="d-none">Subject</label>
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject">
                                    </div>
                                
                                </div>
                            </div>
                           
                            <div class="form-group">
                                <label class="d-none">Message</label>
                                <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Your Message"></textarea>
                            </div>
                            <div class="d-flex justify-content-center align-items-center">
                                <div class="send-btn">
                                    <button type="submit" class="default-btn">
                                        Send Message
                                    </button>
                                </div>
                            </div>
                            
                        </form>
                    </div>
          

            </div>
        </div>
        <div class="pop-up" v-if="togglePopupShow === true">
                <div>
                    <button @click="togglePopupShow = false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
                        <path d="M45.9317 71.9366L76.4817 41.3866L70.415 35.3199L45.9317 59.8033L33.5817 47.4533L27.515 53.5199L45.9317 71.9366ZM51.9984 95.3366C46.0039 95.3366 40.3706 94.1984 35.0984 91.9219C29.8262 89.6484 25.24 86.5616 21.34 82.6616C17.44 78.7616 14.3533 74.1755 12.0797 68.9032C9.80326 63.631 8.66504 57.9977 8.66504 52.0033C8.66504 46.0088 9.80326 40.3755 12.0797 35.1033C14.3533 29.831 17.44 25.2449 21.34 21.3449C25.24 17.4449 29.8262 14.3567 35.0984 12.0803C40.3706 9.8067 46.0039 8.66992 51.9984 8.66992C57.9928 8.66992 63.6261 9.8067 68.8984 12.0803C74.1706 14.3567 78.7567 17.4449 82.6567 21.3449C86.5567 25.2449 89.6435 29.831 91.917 35.1033C94.1935 40.3755 95.3317 46.0088 95.3317 52.0033C95.3317 57.9977 94.1935 63.631 91.917 68.9032C89.6435 74.1755 86.5567 78.7616 82.6567 82.6616C78.7567 86.5616 74.1706 89.6484 68.8984 91.9219C63.6261 94.1984 57.9928 95.3366 51.9984 95.3366Z" fill="#00B79D"/>
                    </svg>
                    </span>
                    <p>Thank You!</p>
                    <p>
                        Your message has been sent.
                    </p>
                </div>
            </div>
    </div>
</template>

<script>
export default{
    name: 'Contact',
    data() {
    return {
        togglePopupShow: false,
    };
  },
  methods: {
  
    submitForm() {
      // Handle form submission logic here
      this.togglePopupShow = true;
     
    },

  }
}
</script>