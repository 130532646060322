<template>
    <div>
    <header class="page-title-area item-bg-1">
        <!----<div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="page-title-content">
                        <h2>Portofolio</h2>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                   
                            <li>Portofolio</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>-->
    </header>
    <div class="text-center header-lap-img position-relative">
        <div class="top-left-img">
            <img src="../../assets/images/portfolio-top-left-img.svg" width="130" height="192" alt="mobile">
        </div>
     
            <img src="../../assets/images/portfolio-lap.svg" alt="Portofolio header laptop" width="573" height="417">
        <div class="bottom-right-img">
            <img src="../../assets/images/portfolio-bottom-right-img.svg"  width="130" height="192" alt="mobile">
        </div>
    </div>
</div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>