<template>
    <div>
       <navbar-style-two></navbar-style-two>
        <servers-header></servers-header>
        <servers-difference></servers-difference>
        <server-pricing></server-pricing>
        <Specification />
        <server-features></server-features>
        <private-servers-features></private-servers-features>
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import ServersHeader from '../PrivateServers/ServersHeader'
    import ServersDifference from '../PrivateServers/ServersDifference'
    import ServerPricing from '../PrivateServers/ServerPricing'
    import Specification from '../PrivateServers/Specification'
    import ServerFeatures from '../PrivateServers/ServerFeatures'
    import Footer from '../Layout/Footer'
    import PrivateServersFeatures from '../PrivateServers/PrivateServersFeatures'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'PrivateServers',
        components: {
            NavbarStyleTwo,
            ServersHeader,
            ServersDifference,
            ServerPricing,
            Specification,
            ServerFeatures,
            PrivateServersFeatures,
            Footer,

               
        },
        setup(){
            useHead({
                title: 'Misk - Private Servers',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        },
          setup(){
            useHead({
                title: 'Misk - Private Servers',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }

    }
</script>