<template>
    <div>
        <Navbar />
        <set-up-page-title></set-up-page-title>
        <set-up></set-up>
        <Footer />
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import Footer from '../Layout/Footer'
    import SetUpPageTitle from '../Subscribe/SetUppageTitle'
    import SetUp from '../Subscribe/SetUp'
    import { useHead } from '@vueuse/head'


    export default {
        name: 'SetUpDomain',
        components: {
            Navbar,
            SetUpPageTitle,
            Footer,
            SetUp,
        
        },
        setup(){
            useHead({
                title: 'Misk - Set Up Domain',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>