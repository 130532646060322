<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <ui-header></ui-header>
        <seo-features></seo-features>
        <ui-overview></ui-overview>
        <design-process></design-process>
        <Digital></Digital>
        <ui-work></ui-work>   
        <ui-work-with-us></ui-work-with-us>
        <Faq/>
        <Footer />
     
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import UiHeader from '../SeoServices/UiHeader'
    import UiOverview from '../SeoServices/UiOverview'
    import DesignProcess from '../SeoServices/DesignProcess'
    import UiWork from '../SeoServices/UiWork'

    import Digital from '../SeoServices/Digital'
    import SeoFeatures from '../SeoServices/SeoFeatures'
    import UiWorkWithUs from '../SeoServices/UiWorkWithUs'
    import Footer from '../Layout/Footer'
    import Faq from '../SeoServices/Faq'
    import { useHead } from '@vueuse/head'
    export default{
        components:{
            NavbarStyleTwo,
            UiHeader,
            UiWork,
            UiOverview,
            DesignProcess,
            UiWorkWithUs,
            SeoFeatures,
            Digital,
            Footer,
            Faq
        },
        setup(){
            useHead({
                title: 'Misk - Seo Service',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>