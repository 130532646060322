<template>
    <div>
        <Section class="container qa-overview pt-35 pb-35 mt-3">
            <div class="row d-flex justify-content-center align-items-start ">
                <div class="col-lg-6 text-center  mt-3 mt-lg-0 ">
                    <!----<img src="../../assets/images/Images/slider-bg-2.webp" alt="overview image" width="1920" height="1000"/>-->
                    <img src="../../assets/images/Images/image-1.webp" alt="overview image">

                </div>
                <div class="col-lg-6 mt-4">
                    <header class=" px-2 py-3 rounded-2">
                        <h2>5 Reasons to have a Mobile App for your business</h2>
                        <!----<p>
                            The software testing & QA services we offer work perfectly for applications
                             that are either stand-alone, client/server or web-based. 
                             Our staff is well trained and applies proven system testing and quality 
                             assurance methodologies and techniques.
                        </p>-->
                    </header>
                    <div class="bar"></div>
                        <div class="about-list-tab">
                            <div class="tabs">
                                <ul class="list-group list-unstyled">
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                            Connect with your clients globally, enabling access at any time and from anywhere.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                            Evaluate and strategize based on client behavior and preferences for effective engagement.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        Broaden your customer base by specifically targeting mobile users.
                                       </div>
                                    </li>

                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        Enhance customer satisfaction through a user-friendly app interface.
                                       </div>
                                    </li>

                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        Facilitate seamless communication and provide continuous support to your clients.
                                       </div>
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>
                    
                </div>
                
            </div>
            
        </Section>
    </div>
</template>