<template>
  <div>
    <div class="container pt-35 pb-70 clients">
        <div class="section-title">
            <h2>Success Partners</h2>
            <p>
              By working closely with them and meticulously implementing the projects they entrusted to us, we were able to help them realize their aspirations. 
Let's work together to create a success story that's uniquely yours.

            </p>
            <div class="bar"></div>
        </div>
      </div>
      <div class="container-fluid our-clients">
        <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000" :wrap-around="true">
        <Slide v-for="slide in slides" :key="slide">
            <div class="carousel__item">
                <img :src="slide.imgUrl" :alt="slide.altText" :width="slide.width" :height="slide.height" >
            </div>
        </Slide>
       
        <template #addons>
            <Pagination />
            <!----<navigation>
              <template #next>     
                <button class="client-next client-navigation"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                      <path d="M0.644505 9.00052C0.644505 9.14219 0.668116 9.27205 0.715338 9.3901C0.76256 9.50816 0.845198 9.62621 0.963254 9.74427L7.97575 16.7568C8.16464 16.9457 8.41846 17.0342 8.73721 17.0224C9.05596 17.0106 9.30978 16.9102 9.49867 16.7214C9.73478 16.4852 9.83513 16.2314 9.79971 15.9599C9.76429 15.6884 9.65214 15.4464 9.46325 15.2339L3.22992 9.00052L9.46325 2.76719C9.65214 2.5783 9.75839 2.32448 9.782 2.00573C9.80561 1.68698 9.69936 1.43316 9.46325 1.24427C9.27436 1.00816 9.02645 0.90781 8.7195 0.943228C8.41256 0.978645 8.15284 1.0908 7.94034 1.27968L0.963254 8.25677C0.845198 8.37483 0.76256 8.49288 0.715338 8.61094C0.668116 8.72899 0.644505 8.85885 0.644505 9.00052Z" fill="#00B79D"/>
                    </svg>
                </button>
              </template>
              <template #prev>
                  <button class="client-prev client-navigation"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                      <path d="M0.644505 9.00052C0.644505 9.14219 0.668116 9.27205 0.715338 9.3901C0.76256 9.50816 0.845198 9.62621 0.963254 9.74427L7.97575 16.7568C8.16464 16.9457 8.41846 17.0342 8.73721 17.0224C9.05596 17.0106 9.30978 16.9102 9.49867 16.7214C9.73478 16.4852 9.83513 16.2314 9.79971 15.9599C9.76429 15.6884 9.65214 15.4464 9.46325 15.2339L3.22992 9.00052L9.46325 2.76719C9.65214 2.5783 9.75839 2.32448 9.782 2.00573C9.80561 1.68698 9.69936 1.43316 9.46325 1.24427C9.27436 1.00816 9.02645 0.90781 8.7195 0.943228C8.41256 0.978645 8.15284 1.0908 7.94034 1.27968L0.963254 8.25677C0.845198 8.37483 0.76256 8.49288 0.715338 8.61094C0.668116 8.72899 0.644505 8.85885 0.644505 9.00052Z" fill="#00B79D"/>
                    </svg>
                  </button>
              </template>
          </navigation>-->
        </template>
        </Carousel>
      </div>  
  </div>
    
    
  </template>
  
  <script>
  import { defineComponent } from 'vue'
  import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
  
  import 'vue3-carousel/dist/carousel.css'
  
  export default defineComponent({
    name:'OurClients',
    components: {
        Pagination,
        Carousel,
        Slide,
        Navigation,
    },
    data(){
        return{
            slides:[
              
                {
                    imgUrl: require('../../assets/images/Images/elite-minds.webp'),
                    altText: 'elite minds',
                    width: 134,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/taam.webp'),
                    altText: 'taam',
                    width: 140,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/pm-tricks.webp'),
                    altText: 'pm tricks',
                    width: 250,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/mohandsi.webp'),
                    altText: 'mohandsi logo',
                    width: 150,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/ideal-way.webp'),
                    altText: 'ideal way logo',
                    width: 134,
                    height: 118,
                },
                {
                  imgUrl: require('../../assets/images/Images/pm-tricks.webp'),
                    altText: 'pm tricks',
                    width: 250,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/academy.webp'),
                    altText: 'academy',
                    width: 100,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/BUILDERS-CN.webp'),
                    altText: 'BUILDERS CN logo',
                    width: 230,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/mucaz-Logo.webp'),
                    altText: 'mucaz-Logo',
                    width: 134,
                    height: 118,
                },
                {
                  imgUrl: require('../../assets/images/Images/pm-tricks.webp'),
                    altText: 'pm tricks',
                    width: 250,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/edara.webp'),
                    altText: 'edara',
                    width: 134,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/handasa-tricks.webp'),
                    altText: 'handasa tricks logo',
                    width: 134,
                    height: 118,
                },
           
                {
                    imgUrl: require('../../assets/images/Images/mcp-academy.webp'),
                    altText: 'mcp academy logo',
                    width: 180,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/Images/st-tricks.webp'),
                    altText: 'st tricks logo',
                    width: 134,
                    height: 118,
                },
                
                {
                    imgUrl: require('../../assets/images/Images/king-saud.webp'),
                    altText: 'king saud logo',
                    width: 200,
                    height: 118,
                },
               
                /*{
                    imgUrl: require('../../assets/images/kaspersky.png'),
                    altText: 'team image',
                    width: 134,
                    height: 118,
                },
                {
                    imgUrl: require('../../assets/images/logo-login-lsw.png'),
                    altText: 'team image',
                    width: 134,
                    height: 118,
                },
                /*{
                    imgUrl: require('../../assets/images/EnomLogo.jpg'),
                    altText: 'team image',
                    width: 134,
                    height: 118,
                }*/
            ],
            settings: {
              itemsToShow: 1,
              snapAlign: 'center',
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
              // 700px and up
              700: {
                itemsToShow: 2,
                snapAlign: 'center',
              },
              900: {
                itemsToShow: 3,
                snapAlign: 'center',
              },
              // 1024 and up
              1024: {
                itemsToShow: 4,
                snapAlign: 'center',
              },
            },/*********/ 
        }
    }
  })
  </script>
  
  <style scoped>
  .carousel__viewport {
    perspective: 2000px;
  }
  .carousel__slide{
    position: relative;
  }
  .carousel__pagination{
    margin-top: 10px;
  }
  .client-navigation{
    position: absolute;

  }
  .carousel__slide{
    border:1px solid #b3b3ac;
    border-radius: 5px;
    margin-right: 5px;
    padding-top:10px;
    padding-bottom: 10px;
  }
 
  </style>
  