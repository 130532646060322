<template>
    <div class="choose-section  pt-35 pb-35">
        <div class="container">
            <div class="section-title">
                <h2>Why is having a website important?</h2>
                <p>
                    Having a website is essential for your business to establish a strong online presence, expand reach, and achieve strategic objectives in today's digital world.
                </p>
                <div class="bar"></div>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="choose-content">
                        <div class="icon">
                            <i class="flaticon-shared-folder"></i>
                        </div>
                        <h3>Global Accessibility </h3>
                        <p>
                            A website is crucial for your business to reach a global audience 24/7, eliminating geographical barriers and enabling clients worldwide to access products/services.
                        </p>
                    </div>

                    <div class="choose-content">
                        <div class="icon">
                            <i class="flaticon-blog"></i>
                        </div>
                        <h3>Credibility and Trust </h3>
                        <p>
                            A professional website showcases your products or services and reflects your commitment to quality, instilling confidence in your brand. 
                        </p>
                    </div>

                    <div class="choose-content">
                        <div class="icon">
                            <i class="flaticon-quality"></i>
                        </div>
                        <h3>Efficient Online Marketing </h3>
                        <p>
                            Through SEO techniques and social media integration, you can drive organic traffic, engage with audiences, and use digital marketing strategies to boost growth and revenue.
                        </p>
                    </div>

                    <!----<div class="choose-content">
                        <div class="icon">
                            <i class="flaticon-target"></i>
                        </div>
                        <h3>Location Targeting</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p>
                    </div>-->
                </div>

                <div class="col-lg-6">
                    <div class="choose-image">
                        <img src="../../assets/images/Images/choose.webp" alt="image" width="830" height="750">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Choose'
    }
</script>