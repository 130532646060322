<template>
  <div class="container">
    <div class="row testmonial-pagination">
      <section v-for="item in paginatedData" :key="item.id" class="col-lg-6 testmonial-col">
      <!--  card content  -->
        <div class="testmonial-card">
          
            <div class="text-center">
              <img src="../../assets/images/client-1.jpg" class="card-img-top" alt="">
            </div>
            <div class="d-flex "> 
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="36" viewBox="0 0 48 36" fill="none">
                <path d="M47.8008 35.8809H27.9008V15.9609L35.8808 0.000858307H45.8008L37.8608 15.9609H47.8008V35.8809Z" fill="#00B79D"/>
                <path d="M19.9004 35.8809H0.000389099V15.9609L7.98039 0.000858307H17.9004L9.96039 15.9609H19.9004V35.8809Z" fill="#00B79D"/>
              </svg>
            </div>
          <div class="testmonial-body">
            <p class="testmonial-card-text">
              Try a lot of agencies some of the big-name even with 10
              yrs' experience and none is comparable to canny. Misk is 
              very detailed and knowledgeable, they have managed to
              reduce my cost per click from FB and 
            </p>
            <h5>
              {{ item.name }}
            </h5>
            <span class="role">Designer</span>
          </div>
        </div>
        </div>
 
    </section>
    </div>
    <div class="navigator text-center">
        <button @click="prevPage" :disabled="currentPage === 1" :class="{ activeNext: currentPage !== 1 }" class="nav-btn"><i class="fa-solid fa-angle-left"></i></button>
        <span v-for="page in totalPages" :key="page">
          <button @click="goToPage(page)" :class="{ activeNumber: currentPage === page }" class="pagenumbers">{{ page }}</button>
        </span>
        <button @click="nextPage" :disabled="currentPage === totalPages" :class="{ activeNext: currentPage !== totalPages }" class="nav-btn"> <i class="fa-solid fa-angle-right"></i> </button>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: [], // Your fetched data from the API
      currentPage: 1,
      itemsPerPage: 6
    };
  },
  computed: {
    paginatedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.data.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.data.length / this.itemsPerPage);
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    }
  },
  created() {
    // Fetch data from your API and store it in this.data
    // Example using fetch API
    fetch('https://jsonplaceholder.typicode.com/users')
      .then(response => response.json())
      .then(data => {
        this.data = data;
      });
  }
};
</script>

<style>
button.active {
  font-weight: bold;

}
</style>
