<template>
    <div>
        <div class="container pt-70 pb-35 subscribtion-form">
            <header >
                <h2 class="text-center">Create your account</h2>
            </header>
            <section class="row">
                <form @submit.prevent method="POST" action="">
                    <!------------Personal information Start ------------->
                    <fieldset class="mb-4 personal">
                        <legend>Account Information</legend>
                        <p class="all">All fields are required unless otherwise noted.</p>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="input-container">
                                    <label for="name">Your Name</label>
                                    <input type="text" class="form-control" id="name" name="name" required>
                                </div>
                                
                            </div>

                            <div class="col-lg-6">
                                <div class="input-container start-padding">
                                    <label for="businessname">Business Name(Optional)</label>
                                    <input type="text" class="form-control" id="businessname" name="businessname">
                                </div>
                          
                            </div>

                            <div class="col-lg-6">
                                <div class="input-container ">
                                    <label for="Country">Country</label>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected></option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>
                                
                            </div>

                            <div class="col-lg-6">
                                <div class="input-container start-padding">
                                    <label for="inputAddress2" class="form-label ">Street Address</label>
                                    <input type="text" class="form-control" id="inputAddress2">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="input-container">
                                <label for="inputCity" class="form-label ">City</label>
                                <input type="text" class="form-control" id="inputCity">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="input-container start-padding ">
                                 <label for="inputState" class="form-label ">State</label>
                                   
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected></option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                              
                                </div>
                                
                            </div>
                            <div class="col-lg-6">
                                <div class="input-container ">
                                <label for="inputZip" class="form-label">Zip Code</label>
                                <input type="text" class="form-control" id="inputZip">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="input-container start-padding">
                                <label for="phone" class="form-label">Phone Number</label>
                                <input type="tel" class="form-control" id="phone">
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="input-container">
                                <label for="inputEmail4" class="form-label">Email</label>
                                    <div>
                                        <input type="email" class="form-control" id="inputEmail4">
                                        <p class="mt-3">*Your receipt will be sent to this address.</p>
                                    </div> 
                                </div>
                                
                            </div>
                        </div>
                    </fieldset>


                    <!--------------------Package Start------------------------------->
                    <div class="row">
                        <div class="col-lg-5 ">
                            <fieldset class="backage pt-70">
                                <legend>Package Information</legend>

                                <div class="input-container">
                                    <label for="AccountPlan" class="form-label">Account Plan</label>
                                    <select class="form-select" aria-label="Default select example">
                                        <option selected></option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div>

                                <div class="input-container">
                                   <label for="inputEmail4" class="form-label">Hosting Price</label>
                                    <div>
                                        <p>$35.40 ($2.95 for 12 months)</p>
                                    </div>    
                                </div>
                               
                            </fieldset>
                        </div>
                        <div class="col-lg-6 offset-lg-1">
                            <fieldset class="package-extra pt-70">
                                <legend>Package Extras</legend>
                                <div class="package-item">
                                    <div class="form-check">
                                        <label class="form-check-label" for="option3">
                                            Codeguard Basic
                                        </label>
                                        <div class="check-container ">
                                            <input class="form-check-input" type="checkbox" id="option3" value="option3">
                                            <p>$ 2.99 per month (Billed annually at $35.88/yr)
                                            <a href="#" class="d-block">More Information</a>
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="package-item">
                                    <div class="form-check">
                                        <label class="form-check-label" for="option3">
                                            SiteLock Security - Essential
                                        </label>
                                        <div class="check-container ">
                                            <input class="form-check-input" type="checkbox" id="option3" value="option3">
                                            <p>$ 2.99 per month (Billed annually at $35.88/yr)
                                            <a href="#" class="d-block">More Information</a>
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="package-item">
                                    <div class="form-check">
                                        <label class="form-check-label" for="option3">
                                            Single Domain SSL
                                        </label>
                                        <div class="check-container ">
                                            <input class="form-check-input" type="checkbox" id="option3" value="option3">
                                            <p> $ 3.33 per month
                                            <a href="#" class="d-block">More Information</a>
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>
                                    
                                <div class="package-item">
                                    <div class="form-check">
                                        <label class="form-check-label" for="option3">
                                            Savings
                                        </label>
                                        <div class="check-container ">
                                            
                                            <p class="savings"> $84.48 (70% off)
                                            
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>

                                <div class="package-item">
                                    <div class="form-check">
                                        <label class="form-check-label" for="option3">
                                            Total
                                        </label>
                                        <div class="check-container ">
                                            <p class="savings">  $107.16
                                            <a href="#" class="d-block">More Information</a>
                                            </p>
                                        </div>
                                        
                                    </div>
                                </div>   
                            </fieldset>
                            
                        </div>
                        <div class="col-lg-6">
                                <fieldset class="payment">
                                    <legend>Payment Method</legend>
                                    <div class="d-flex justify-content-start">
                                        <div class="img-container align-self-baseline">
                                            <img src="../../assets/images/visa.svg" alt="visa">
                                        </div>
                                        <div class="img-container">
                                            <img src="../../assets/images/master-card.svg" alt="master card">
                                        </div>
                                        <div class="img-container">
                                            <img src="../../assets/images/paypal.svg" alt="paypal">
                                        </div>
                                        <div class="img-container">
                                            <img src="../../assets/images/sadad.svg" alt="sadad">
                                        </div>
                                        <div class="img-container">
                                            <img src="../../assets/images/cash.svg" alt="cash">
                                        </div>
                                    </div>
                                    <div >
                                        <div class="input-container">
                                            <label for="cardnumber" class="form-label">Credit Card Number</label>
                                            <input type="text" class="form-control" id="caardnumber">
                                        </div>
                                    </div>

                                    <div >
                                        <div class="input-container">
                                            <label for="cardname" class="form-label">Name on card</label>
                                            <input type="text" class="form-control" id="cardname">
                                        </div>
                                    </div>

                                    <div >
                                        <div class="input-container">
                                            <label for="cardcode" class="form-label">CVV Code</label>
                                            <input type="text" class="form-control" id="cardcode">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="input-container">
                                            <label>Expiration Date</label>
                                      
                                        <div class="date">
                                            <select class="form-select" id="month" aria-label="Month">
                                            <option value="01">January</option>
                                            <option value="02">February</option>
                                            <option value="03">March</option>
                                            <option value="04">April</option>
                                            <option value="05" selected>May</option>
                                            <option value="06">June</option>
                                            <option value="07">July</option>
                                            <option value="08">August</option>
                                            <option value="09">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                            </select>
                                        </div>
                                       
                                        <div class="date">
                                           
                                            <input type="text" class="form-control" id="year" placeholder="YYYY">
                                        </div>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                </fieldset>
                            </div>
                            <div class="col-lg-6 d-flex align-items-center justify-content-center">
                                <img src="../../assets/images/sub-img.png" alt="sub-img" height="382.85" width="500">
                            </div>
                            <div class="col-lg-10 last-text">
                                <p>
                                    Auto Renewal Terms: Introductory prices apply to the first term. Money-back guarantee applies to hosting and domain privacy. All plans and products automatically renew unless you cancel. The renewal will be for the same term length and at the regular rates reflected in your Control Panel. The payment method you provide today, or we have on file, will be used for renewals, unless you change it or cancel. You may cancel at any time, prior to your renewal date, by logging into your account online or by calling customer support at +1 855 984 4546.
                                </p>
                            </div>
                            <div class="agreed">
                                <div>
                                    <input type="checkbox">
                                    <p>
                                        I have read and agree to Bluehost's Auto Renewal Terms, Terms of Service and Cancellation Policy and acknowledge <br>receipt of the Privacy Notice.
                                    </p>
                                </div>
                            </div>
                    </div>
                    
                    <button type="submit" @click="setUp">Submit</button>
                </form>
            </section>
        </div>
    </div>
</template>
<script>
import router from '../../router';

export default{
    methods:{
        setUp(){
            router.push('/setup-domain')
        }
    }
}
</script>
