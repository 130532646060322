
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import  store  from './Store';
import {createHead} from '@vueuse/head'

const head = createHead()
import "./assets/custom.scss";

createApp(App).use(head).use(store).use(router).mount("#app");