<template>
    <div>
        <div class="container">
            <Section class="digital-overview pt-35 pb-35">
            <div class="row ">
                <div class="col-lg-6 hover-effect">
                    <header class="pt-1 pt-lg-5">
                     
                        <h2 class="span-overview">Get quick brand visibility online</h2>
                    </header>
                    <p>
                        Through targeted campaigns and strategic content placement, digital marketing allows brands to rapidly reach their target audience, boosting brand awareness and recognition in a shorter time frame compared to traditional marketing methods
                    </p>
                    <p>
                        Moreover, with the ability to track and analyze campaign performance in real-time, you can optimize your digital marketing efforts continuously to maximize visibility and drive results. 
                    </p>
                </div>
                <div class="col-lg-6 text-center  mt-3 mt-lg-0 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/Images/digital-marketing-overview.webp" alt="overview image" height="537" width="338" class="img-fluid rounded-3"/>
                </div>
            </div>
         </Section>
        </div> 
    </div>
</template>