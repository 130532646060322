<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <PageTitle />
        <!--<counter/>--->
        <About />
        <Vision/>
        <about-value></about-value>
        <Features/>
        <last-slider></last-slider>
      
        <our-growing></our-growing>
        <our-history></our-history>
        <ClientsOverview/>
        <Footer />
    
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import PageTitle from '../About/PageTitle'
    /*import Counter from '../About/Counter'*/
    import About from '../About/About'
    import Vision from '../About/Future'
    import AboutValue from '../About/AboutValue'
    import Features from '../HomeOne/Features'
    import LastSlider from '../About/LastSlider'
    import OurGrowing from '../About/OurGrowing'
    import OurHistory from '../About/OurHistory'
    import Footer from '../Layout/Footer'
    import ClientsOverview from '../HomeOne/ClientsOverview'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'AboutPage',
        components: {
            'navbar-style-two':NavbarStyleTwo,
            PageTitle,
            About,
           /* Counter,*/
            Vision,
            OurGrowing,
            Footer,
            OurHistory,
            LastSlider,
            AboutValue,
            ClientsOverview,
            Features
        },
        setup(){
            useHead({
                title: 'Misk - About Page',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ],
                script: [
                            {
                            type: 'application/ld+json',
                            innerHTML: `
                            {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement":
                            [
                                {
                                "@type": "ListItem",
                                "position": 1,
                                "item":
                                {
                                "@id": "https://example.com/dresses",
                                "name": "Home"
                                }
                                },
                                {
                                "@type": "ListItem",
                                "position": 2,
                                "item":
                                {
                                "@id": "https://example.com/dresses/real",
                                "name": "About"
                                }
                                }
                            ]
                            }
                            `
                            }
                        ]
            })
        }
       
    }
</script>