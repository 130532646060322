<template>
    <div>
       <Navbar />
   
        <PageTitle />
        <ContactBox />
        <Contact />
        <TestBootstrap></TestBootstrap>
        <Footer />
        
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import PageTitle from '../Contact/PageTitle'
    import ContactBox from '../Contact/ContactBox'
    import Contact from '../Contact/Contact'
    import Footer from '../Layout/Footer'
    import TestBootstrap from '../Contact/TestBootstrap'

    import { useHead } from '@vueuse/head'
    export default {
        name: 'ContactPage',
        components: {
         Navbar,
        PageTitle,
        ContactBox,
        Contact,
        Footer,
        TestBootstrap
                
    },
    setup(){
            useHead({
                title: 'Misk - Contact Us',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>