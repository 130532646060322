<template>
   
   <div class="footer-container">
    <footer class="footer-section">
        <div class="container">
            <div class="row">
                <aside class="col-lg-3 col-md-6 col-sm-6  mt-3 mt-lg-1">
                    <div class="single-footer-widget">
                        <div class="footer-heading">
                            <h3>
                                <router to="/">
                                    <img src="../../assets/images/misk-light-logo.svg" alt="Misk light logo"
                                        height="60" width="107">
                                </router>
                            </h3>
                        </div>
                        <p>
                            Misk workers have been working on various large-scale & complex projects, always meeting
                            clients’ expectations.
                        </p>

                        <ul class="footer-social">
                            <li>
                                <a href="#" target="_blank">
                                    <i class="fa-brands fa-square-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="fa-brands fa-square-x-twitter"></i>
                                </a>

                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="fa-brands fa-instagram"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" target="_blank">
                                    <i class="fa-brands fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </aside>

                <!--Start Of contact info section-->
                <aside class="col-lg-3 col-md-6 col-sm-6 ps-xl-3 mt-3 mt-lg-1">
                    <div class="single-footer-widget">
                        <div class="footer-heading">
                            <h3>Contact</h3>
                            <hr>
                        </div>
                        <ul class="footer-info-contact m-0 p-0">
                            <li>
                                <span class="d-inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                                    <path d="M9.00001 0.5C6.81276 0.50258 4.71584 1.3726 3.16923 2.91922C1.62261 4.46584 0.752589 6.56276 0.750009 8.75C0.747389 10.5374 1.33124 12.2763 2.41201 13.7C2.41201 13.7 2.63701 13.9963 2.67376 14.039L9.00001 21.5L15.3293 14.0353C15.3623 13.9955 15.588 13.7 15.588 13.7L15.5888 13.6978C16.669 12.2747 17.2526 10.5366 17.25 8.75C17.2474 6.56276 16.3774 4.46584 14.8308 2.91922C13.2842 1.3726 11.1873 0.50258 9.00001 0.5ZM9.00001 11.75C8.40666 11.75 7.82665 11.5741 7.3333 11.2444C6.83995 10.9148 6.45543 10.4462 6.22837 9.89805C6.00131 9.34987 5.9419 8.74667 6.05765 8.16473C6.17341 7.58279 6.45913 7.04824 6.87869 6.62868C7.29825 6.20912 7.83279 5.9234 8.41474 5.80764C8.99668 5.69189 9.59988 5.7513 10.1481 5.97836C10.6962 6.20542 11.1648 6.58994 11.4944 7.08329C11.8241 7.57664 12 8.15666 12 8.75C11.999 9.54535 11.6826 10.3078 11.1202 10.8702C10.5578 11.4326 9.79535 11.749 9.00001 11.75Z" fill="white"/>
                                </svg>
                                </span>
                                <span><a href="geo:latitude,longitude">Qussier-Red Sea-Egypt</a></span>
                            </li>
                            <li>
                                <span class="d-inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M22 8.608V16.75C22.0001 17.5801 21.6824 18.3788 21.1123 18.9822C20.5422 19.5856 19.7628 19.948 18.934 19.995L18.75 20H5.25C4.41986 20.0001 3.62117 19.6824 3.01777 19.1123C2.41437 18.5422 2.052 17.7628 2.005 16.934L2 16.75V8.608L11.652 13.664C11.7594 13.7202 11.8788 13.7496 12 13.7496C12.1212 13.7496 12.2406 13.7202 12.348 13.664L22 8.608ZM5.25 4H18.75C19.5556 3.9999 20.3325 4.299 20.93 4.83927C21.5276 5.37954 21.9032 6.12248 21.984 6.924L12 12.154L2.016 6.924C2.09352 6.15431 2.44305 5.43752 3.00175 4.90246C3.56045 4.36741 4.29168 4.04919 5.064 4.005L5.25 4H18.75H5.25Z" fill="white"/>
                                </svg>
                                </span>
                                <span><a href="mailto:hello@valzo.com">info@meskits.com</a></span>
                            </li>
                            <li>
                                <span class="d-inline-block text-light whats-icon">
                                <!----<img src="../../assets/images/logos_whatsapp-icon.png" alt="whatsapp icon" width="24"
                                height="24" />-->
                                <i class="fa-brands fa-whatsapp"></i>
                                </span>
                                <span><a href="tel:+882-569-756">+123(456)123</a></span>
                            </li>
                            <li>
                                <span class="d-inline-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M20.7075 14.704C20.5923 13.9036 19.9743 13.2796 19.1787 13.0936C16.0863 12.3688 15.3111 10.588 15.1803 8.61279C14.6319 8.51079 13.6563 8.40039 12.0003 8.40039C10.3443 8.40039 9.36868 8.51079 8.82028 8.61279C8.68948 10.588 7.91428 12.3688 4.82188 13.0936C4.02628 13.2808 3.40828 13.9036 3.29308 14.704L2.69668 18.8344C2.48668 20.2888 3.55468 21.6004 5.04028 21.6004H18.9603C20.4447 21.6004 21.5139 20.2888 21.3039 18.8344L20.7075 14.704ZM12.0003 18.5908C10.3263 18.5908 8.96908 17.2468 8.96908 15.5908C8.96908 13.9348 10.3263 12.5908 12.0003 12.5908C13.6743 12.5908 15.0315 13.9348 15.0315 15.5908C15.0315 17.2468 13.6731 18.5908 12.0003 18.5908ZM23.9403 7.20039C23.9115 5.40039 19.3299 2.40159 12.0003 2.40039C4.66948 2.40159 0.0878785 5.40039 0.0602785 7.20039C0.0326785 9.00039 0.0854786 11.3428 3.10228 10.9528C6.63148 10.4956 6.41428 9.26319 6.41428 7.50159C6.41428 6.27279 9.28468 5.97639 12.0003 5.97639C14.7159 5.97639 17.5851 6.27279 17.5863 7.50159C17.5863 9.26319 17.3691 10.4956 20.8983 10.9528C23.9139 11.3428 23.9679 9.00039 23.9403 7.20039Z" fill="white"/>
                                </svg>
                                </span>
                                <span><a href="tel:+882-569-756">+123(456)123</a></span>
                            </li>
                        </ul>
                    </div>
                </aside>
                <!--End Of Contact Info Section----->
                <!-----Start of bottom nav ----------->
                <aside class="col-lg-3 col-md-6 col-sm-6  ps-lg-3  mt-3 mt-lg-1">
                    <div class="single-footer-widget">
                        <div class="footer-heading">
                            <h3>Featured Service</h3>
                            <hr>
                        </div>
                        <ul class="footer-quick-links">
                            <li>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path class="greater-than"
                                            d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                            fill="white" />
                                    </svg>
                                </i>
                                <router-link to="/">Home</router-link>
                            </li>
                            <li>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path class="greater-than"
                                            d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                            fill="white" />
                                    </svg>
                                </i>
                                <router-link to="/about"> About Us</router-link>
                            </li>
                            <li>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path class="greater-than"
                                            d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                            fill="white" />
                                    </svg>
                                </i>
                                <router-link to="/">Hosting</router-link>
                            </li>
                            <li>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path class="greater-than"
                                            d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                            fill="white" />
                                    </svg>
                                </i>
                                <router-link to="/">Solutions</router-link>
                            </li>
                            <li>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path class="greater-than"
                                            d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                            fill="white" />
                                    </svg>
                                </i>
                                <router-link to="/services">Services</router-link>
                            </li>

                        </ul>
                    </div>
                </aside>
                <!-----end of bottom nav ----------->
                <!-------------Start Of Privacy and Policy----------------------->
                <aside class="col-lg-3 col-md-6 col-sm-6  ps-lg-3  mt-3 mt-lg-1">
                    <div class="single-footer-widget">
                        <div class="footer-heading">
                            <h3>Important Links</h3>
                            <hr>
                        </div>

                        <ul class="footer-quick-links">
                            <li>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path class="greater-than"
                                            d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                            fill="white" />
                                    </svg>
                                </i>
                                <router-link to="/privacy-policy">Terms of services</router-link>
                            </li>
                            <li>
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path class="greater-than"
                                            d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                            fill="white" />
                                    </svg>
                                </i>
                            <router-link to="/privacy-policy">Refund policy</router-link>
                        </li>
                        <li>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                    fill="none">
                                    <path class="greater-than"
                                        d="M4.80019 12.0004L8.80019 8.00039L4.8002 4.00039L5.6002 2.40039L11.2002 8.00039L5.60019 13.6004L4.80019 12.0004Z"
                                        fill="white" />
                                </svg>
                            </i>
                            <router-link to="/privacy-policy">Privacy Ploicy</router-link>
                        </li>
                        <!---<li>
                            <img src="../../assets/images/visa-icon.png" width="41" height="26" alt="Visa logo" class="white-img">
                            <img src="../../assets/images/master-card.png" width="38" height="30"
                                alt="master card logo" class="white-img">
                            <img src="../../assets/images/paypal-icon.png" width="35" height="36" alt="paypal logo" class="white-img">

                        </li>-->
                        <li class="paying-methods">
                            <span class="me-3"><i class="fa-brands fa-cc-visa"></i></span>
                            <span class="me-3"><i class="fa-brands fa-cc-mastercard"></i></span>
                            <span><i class="fa-brands fa-paypal"></i></span>
                        </li>
                    </ul>
                </div>
            </aside>
            <!-------------End Of Privacy and Policy----------------------->
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    
    </footer>
    <copy-right></copy-right>
    </div>
    

</template>

<script>

import CopyRight from './CopyRight'
export default {
  components: { CopyRight },
    name: 'Footer',
    
}
</script>
<Style scoped> 
   
</Style>