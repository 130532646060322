<template>
    <div>
        <Navbar />
        <product-header></product-header>
        <PageTitle />
       <ProductOverview/>
       <ProductFeature />
       <ProductDashboard/>
       <OnlineLecture />
       <ProductsFooter />
        <Footer />
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import PageTitle from '../Products/PageTitle'
    import ProductHeader from '../Products/ProductHeader'
    import ProductOverview from '../Products/ProductOverview'
    import ProductFeature from '../Products/ProductFeature'
    import ProductDashboard from '../Products/ProductDashboard'
    import OnlineLecture from '../Products/OnlineLecture'
    import ProductsFooter from '../Products/ProductsFooter'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'ProductsPage',
        components: {
            Navbar,
            PageTitle,
            ProductHeader,
            ProductOverview,
            ProductFeature,
            ProductDashboard,
            OnlineLecture,
            ProductsFooter,
            Footer,
        },
        setup(){
            useHead({
                title: 'Misk - Project Management',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>