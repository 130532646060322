<template>
    <div>
        <Navbar />
        <vps-header></vps-header>
        <vps-difference></vps-difference>
        <vps-pricing></vps-pricing>
        <vps-features></vps-features>
        <Footer />
      
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import VpsHeader from '../Vps Servers/VpsHeader'
    import VpsDifference from '../Vps Servers/VpsDifference'
    import VpsFeatures from '../Vps Servers/VpsFeatures'
    import VpsPricing from '../Vps Servers/VpsPricing'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'SignUpPage',
        components: {
            Navbar,
            VpsHeader,
            VpsDifference,
            VpsFeatures,
            VpsPricing,
            Footer,
            
        },
        setup(){
            useHead({
                title: 'Misk - Vps Servers',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>