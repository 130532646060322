<template>
    <div>
        <header class="vps-header">
            <div class="container">
                <section class="d-flex flex-column justify-content-center align-items-center">    
                    <div class="text-center">
                        <img src="../../assets/images/rocket-img.svg" alt="rocker-img">
                    </div>
                    <h1 class="text-center">
                        vps servers at flying speed
                    </h1>
                    <p>
                        VPS servers are faster than any other server I've tried
                    </p>  
                   
                </section>
            </div>
        </header>
    </div>
</template>
<script>
    export default{
        name: 'VpsHeader',

        computed: {
            test () {
             return this.$store.state.counter
            }
        }
       
    }
</script>
