<template>
    <div class="about-section pt-35 pb-35">
        <div class="container">
            <div class="row">

                <section class="col-lg-5 p-0 text-center text-lg-start mt-4 mt-lg-0">
                    <div >
                        <img src="../../assets/images/Images/build-for-future.webp" alt="build-for-future" width="372" height="372">
                    </div>
                </section>

                <section class="col-lg-7 d-flex align-items-center">
                    <div class="about-title">
                        <h1>Our Features of SEO service</h1>
                        <div class="bar"></div>
                        <div class="about-list-tab">
                            <div class="tabs">
                                <ul class="list-group list-unstyled">
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                            High experience in SEO.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                            We have a highly experienced team specializing in various aspects of SEO.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                         Providing customized solutions based on your website's needs.
                                       </div>
                                    </li>

                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        Continuous collaboration and communication with you to solve your issues.
                                       </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="mt-4">
                                <router-link to="/contact" class="default-btn py-2">Start Now</router-link>
                            </div>
                        </div>
                    </div>
                </section>

                
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
/*
            tabs: [
                {
                    id: 1,
                    name: 'Our History',
                    title: 'How To Generate Your Creative Idea With IT Business',
                    lists: [
                        {
                            id: 1,
                            icon: 'flaticon-tick',
                            text: 'The Philosophy of Business Analytics',
                        },
                        {
                            id: 2,
                            icon: 'flaticon-tick',
                            text: 'Fast Track Your Business',
                        },
                        {
                            id: 3,
                            icon: 'flaticon-tick',
                            text: 'Lies & Damn Lies About Your Business',
                        },
                        {
                            id: 4,
                            icon: 'flaticon-tick',
                            text: 'The Ultimate Deal on Business',
                        },
                    ],
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                    btnText: 'Discover More',
                    // btnLink: '',
                },
                {
                    id: 2,
                    name: 'Our History',
                    title: 'How To Generate Your Creative Idea With IT Business',
                    lists: [
                        {
                            id: 1,
                            icon: 'flaticon-tick',
                            text: 'The Philosophy of Business Analytics',
                        },
                        {
                            id: 2,
                            icon: 'flaticon-tick',
                            text: 'Fast Track Your Business',
                        },
                        {
                            id: 3,
                            icon: 'flaticon-tick',
                            text: 'Lies & Damn Lies About Your Business',
                        },
                        {
                            id: 4,
                            icon: 'flaticon-tick',
                            text: 'The Ultimate Deal on Business',
                        },
                    ],
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                    btnText: 'Discover More',
                    // btnLink: '',
                },
                {
                    id: 3,
                    name: 'Our History',
                    title: 'How To Generate Your Creative Idea With IT Business',
                    lists: [
                        {
                            id: 1,
                            icon: 'flaticon-tick',
                            text: 'The Philosophy of Business Analytics',
                        },
                        {
                            id: 2,
                            icon: 'flaticon-tick',
                            text: 'Fast Track Your Business',
                        },
                        {
                            id: 3,
                            icon: 'flaticon-tick',
                            text: 'Lies & Damn Lies About Your Business',
                        },
                        {
                            id: 4,
                            icon: 'flaticon-tick',
                            text: 'The Ultimate Deal on Business',
                        },
                    ],
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                    btnText: 'Discover More',
                    // btnLink: '',
                },
            ]*/
        }
    },
    methods: {
       /* selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }*/
    },
  /*  mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },*/
}
</script>