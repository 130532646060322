<template>
    <section class="pt-100 pb-100 history-section">
        <div class="container">
        <!---Clients Slider Start-->
        <transition>
            <Carousel v-bind="settings" class="mt-4 pt-4" :autoplay="2000" :wrap-around="true">
                <Slide  v-for="slide in carouselItems" 
                    :key="slide.id">
                    <div class="carousel__item gap-3 history-card" > 
                        <div class="history-image">
                            <img :src="slide.image.imgUrl" :alt="slide.image.altText" :width="slide.image.width" :height="slide.image.height">
                        </div>
                        <div>
                            <h4>{{ slide.heading }}</h4>
                            <p>{{ slide.description }} </p>
                        </div>
                        <div class="year">{{ slide.Year }}</div>
                    </div>
                </Slide>
                <template #addons>
                    <Pagination />
                    <navigation>
                    <template #next>     
                        <button class="client-overview-next client-overview-navigation"> 
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                            <path d="M0.644505 9.00052C0.644505 9.14219 0.668116 9.27205 0.715338 9.3901C0.76256 9.50816 0.845198 9.62621 0.963254 9.74427L7.97575 16.7568C8.16464 16.9457 8.41846 17.0342 8.73721 17.0224C9.05596 17.0106 9.30978 16.9102 9.49867 16.7214C9.73478 16.4852 9.83513 16.2314 9.79971 15.9599C9.76429 15.6884 9.65214 15.4464 9.46325 15.2339L3.22992 9.00052L9.46325 2.76719C9.65214 2.5783 9.75839 2.32448 9.782 2.00573C9.80561 1.68698 9.69936 1.43316 9.46325 1.24427C9.27436 1.00816 9.02645 0.90781 8.7195 0.943228C8.41256 0.978645 8.15284 1.0908 7.94034 1.27968L0.963254 8.25677C0.845198 8.37483 0.76256 8.49288 0.715338 8.61094C0.668116 8.72899 0.644505 8.85885 0.644505 9.00052Z" fill="#00B79D"/>
                        </svg>
                        </button>
                    </template>
                    <template #prev>
                        <button class="client-overview-prev client-overview-navigation"> 
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" viewBox="0 0 10 18" fill="none">
                            <path d="M0.644505 9.00052C0.644505 9.14219 0.668116 9.27205 0.715338 9.3901C0.76256 9.50816 0.845198 9.62621 0.963254 9.74427L7.97575 16.7568C8.16464 16.9457 8.41846 17.0342 8.73721 17.0224C9.05596 17.0106 9.30978 16.9102 9.49867 16.7214C9.73478 16.4852 9.83513 16.2314 9.79971 15.9599C9.76429 15.6884 9.65214 15.4464 9.46325 15.2339L3.22992 9.00052L9.46325 2.76719C9.65214 2.5783 9.75839 2.32448 9.782 2.00573C9.80561 1.68698 9.69936 1.43316 9.46325 1.24427C9.27436 1.00816 9.02645 0.90781 8.7195 0.943228C8.41256 0.978645 8.15284 1.0908 7.94034 1.27968L0.963254 8.25677C0.845198 8.37483 0.76256 8.49288 0.715338 8.61094C0.668116 8.72899 0.644505 8.85885 0.644505 9.00052Z" fill="#00B79D"/>
                            </svg>
                        </button>
                    </template>
                </navigation>
                </template>
            </Carousel>
        </transition>
        </div>
        <!--Clients Slider End-->
    </section>
    
  </template>
  
  <script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({

  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },

    carouselItems: [
            {
                id: 1,
                heading: `We were growing fast`,
                description: 'We began as a startup to spread awareness about new techs and what are the benefits of them.',
                Year:`2010`,
                image: { 
                    imgUrl: require('../../assets/images/growing-fast.svg'),
                    altText: 'team image',
                    width: 330,
                    height: 220,
                }
            },
            {
                id: 2,
                heading: `We were growing fast`,
                description: 'We began as a startup to spread awareness about new techs and what are the benefits of them.',
                Year:`2012`,
                image: { 
                    imgUrl: require('../../assets/images/history-slide-2012.svg'),
                    altText: 'team image',
                    width: 330,
                    height: 220,
                }
            },
            {
                id: 3,
                heading: `We were growing fast`,
                description: 'We began as a startup to spread awareness about new techs and what are the benefits of them.',
                Year:`2012`,
                image: { 
                    imgUrl: require('../../assets/images/history-slide-2012.svg'),
                    altText: 'team image',
                    width: 330,
                    height: 220,
                }
            },
            {
                id: 4,
                heading: `We were growing fast`,
                description: 'We began as a startup to spread awareness about new techs and what are the benefits of them.',
                Year:`2015`,
                image: { 
                    imgUrl: require('../../assets/images/history-slide-2015.svg'),
                    altText: 'team image',
                    width: 330,
                    height: 220,
                }
            },
            {
                id: 5,
                heading: `We were growing fast`,
                description: 'We began as a startup to spread awareness about new techs and what are the benefits of them.',
                Year:`2019`,
                image: { 
                    imgUrl: require('../../assets/images/history-slide-2019.svg'),
                    altText: 'team image',
                    width: 330,
                    height: 220,
                }
            },
            {
                id: 6,
                heading: `We were growing fast`,
                description: 'We began as a startup to spread awareness about new techs and what are the benefits of them.',
                Year:`2021`,
                image: { 
                    imgUrl: require('../../assets/images/history-slide-2021.svg'),
                    altText: 'team image',
                    width: 330,
                    height: 220,
                }
            },
        ]
  }),
})

  </script>
  <style scoped>
 
  </style>
