<template>
    <div class="design-section pt-35 pb-35">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 text-center text-lg-start">
                    <div class="design-image">
                        <img src="../../assets/images/Images/meeting.webp" alt="meeting image" width="480" height="394">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="design-content">
                        <h3 class="solutions">
                            <span class="solutions">Why Misk ? </span>
                          <!--Provide Best <span class="solutions">IT Solutions </span>-->
                        </h3>
                        <div class="bar"></div>
                        <p>
                            We have several features that make us the ideal partner for your success.

                        </p>
                        <ul class="design-list d-block d-md-flex">
                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M17.2 23.6L12.85 19.25C12.4833 18.8833 12.0333 18.7 11.5 18.7C10.9667 18.7 10.5 18.9 10.1 19.3C9.73333 19.6667 9.55 20.1333 9.55 20.7C9.55 21.2667 9.73333 21.7333 10.1 22.1L15.8 27.8C16.1667 28.1667 16.6333 28.35 17.2 28.35C17.7667 28.35 18.2333 28.1667 18.6 27.8L29.95 16.45C30.3167 16.0833 30.5 15.6333 30.5 15.1C30.5 14.5667 30.3 14.1 29.9 13.7C29.5333 13.3333 29.0667 13.15 28.5 13.15C27.9333 13.15 27.4667 13.3333 27.1 13.7L17.2 23.6ZM20 40C17.2333 40 14.6333 39.4747 12.2 38.424C9.76667 37.3747 7.65 35.95 5.85 34.15C4.05 32.35 2.62533 30.2333 1.576 27.8C0.525334 25.3667 0 22.7667 0 20C0 17.2333 0.525334 14.6333 1.576 12.2C2.62533 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.62467 12.2 1.574C14.6333 0.524667 17.2333 0 20 0C22.7667 0 25.3667 0.524667 27.8 1.574C30.2333 2.62467 32.35 4.05 34.15 5.85C35.95 7.65 37.3747 9.76667 38.424 12.2C39.4747 14.6333 40 17.2333 40 20C40 22.7667 39.4747 25.3667 38.424 27.8C37.3747 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.3747 27.8 38.424C25.3667 39.4747 22.7667 40 20 40Z" fill="#00B49C"/>
                                </svg>
                               <span> Skilled Team </span>
                            </li>

                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M17.2 23.6L12.85 19.25C12.4833 18.8833 12.0333 18.7 11.5 18.7C10.9667 18.7 10.5 18.9 10.1 19.3C9.73333 19.6667 9.55 20.1333 9.55 20.7C9.55 21.2667 9.73333 21.7333 10.1 22.1L15.8 27.8C16.1667 28.1667 16.6333 28.35 17.2 28.35C17.7667 28.35 18.2333 28.1667 18.6 27.8L29.95 16.45C30.3167 16.0833 30.5 15.6333 30.5 15.1C30.5 14.5667 30.3 14.1 29.9 13.7C29.5333 13.3333 29.0667 13.15 28.5 13.15C27.9333 13.15 27.4667 13.3333 27.1 13.7L17.2 23.6ZM20 40C17.2333 40 14.6333 39.4747 12.2 38.424C9.76667 37.3747 7.65 35.95 5.85 34.15C4.05 32.35 2.62533 30.2333 1.576 27.8C0.525334 25.3667 0 22.7667 0 20C0 17.2333 0.525334 14.6333 1.576 12.2C2.62533 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.62467 12.2 1.574C14.6333 0.524667 17.2333 0 20 0C22.7667 0 25.3667 0.524667 27.8 1.574C30.2333 2.62467 32.35 4.05 34.15 5.85C35.95 7.65 37.3747 9.76667 38.424 12.2C39.4747 14.6333 40 17.2333 40 20C40 22.7667 39.4747 25.3667 38.424 27.8C37.3747 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.3747 27.8 38.424C25.3667 39.4747 22.7667 40 20 40Z" fill="#00B49C"/>
                                </svg>
                                <span>After-sales services</span>
                            </li>

                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M17.2 23.6L12.85 19.25C12.4833 18.8833 12.0333 18.7 11.5 18.7C10.9667 18.7 10.5 18.9 10.1 19.3C9.73333 19.6667 9.55 20.1333 9.55 20.7C9.55 21.2667 9.73333 21.7333 10.1 22.1L15.8 27.8C16.1667 28.1667 16.6333 28.35 17.2 28.35C17.7667 28.35 18.2333 28.1667 18.6 27.8L29.95 16.45C30.3167 16.0833 30.5 15.6333 30.5 15.1C30.5 14.5667 30.3 14.1 29.9 13.7C29.5333 13.3333 29.0667 13.15 28.5 13.15C27.9333 13.15 27.4667 13.3333 27.1 13.7L17.2 23.6ZM20 40C17.2333 40 14.6333 39.4747 12.2 38.424C9.76667 37.3747 7.65 35.95 5.85 34.15C4.05 32.35 2.62533 30.2333 1.576 27.8C0.525334 25.3667 0 22.7667 0 20C0 17.2333 0.525334 14.6333 1.576 12.2C2.62533 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.62467 12.2 1.574C14.6333 0.524667 17.2333 0 20 0C22.7667 0 25.3667 0.524667 27.8 1.574C30.2333 2.62467 32.35 4.05 34.15 5.85C35.95 7.65 37.3747 9.76667 38.424 12.2C39.4747 14.6333 40 17.2333 40 20C40 22.7667 39.4747 25.3667 38.424 27.8C37.3747 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.3747 27.8 38.424C25.3667 39.4747 22.7667 40 20 40Z" fill="#00B49C"/>
                                </svg>
                                <span>Creative solutions</span>
                            </li>

                            <li>
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M17.2 23.6L12.85 19.25C12.4833 18.8833 12.0333 18.7 11.5 18.7C10.9667 18.7 10.5 18.9 10.1 19.3C9.73333 19.6667 9.55 20.1333 9.55 20.7C9.55 21.2667 9.73333 21.7333 10.1 22.1L15.8 27.8C16.1667 28.1667 16.6333 28.35 17.2 28.35C17.7667 28.35 18.2333 28.1667 18.6 27.8L29.95 16.45C30.3167 16.0833 30.5 15.6333 30.5 15.1C30.5 14.5667 30.3 14.1 29.9 13.7C29.5333 13.3333 29.0667 13.15 28.5 13.15C27.9333 13.15 27.4667 13.3333 27.1 13.7L17.2 23.6ZM20 40C17.2333 40 14.6333 39.4747 12.2 38.424C9.76667 37.3747 7.65 35.95 5.85 34.15C4.05 32.35 2.62533 30.2333 1.576 27.8C0.525334 25.3667 0 22.7667 0 20C0 17.2333 0.525334 14.6333 1.576 12.2C2.62533 9.76667 4.05 7.65 5.85 5.85C7.65 4.05 9.76667 2.62467 12.2 1.574C14.6333 0.524667 17.2333 0 20 0C22.7667 0 25.3667 0.524667 27.8 1.574C30.2333 2.62467 32.35 4.05 34.15 5.85C35.95 7.65 37.3747 9.76667 38.424 12.2C39.4747 14.6333 40 17.2333 40 20C40 22.7667 39.4747 25.3667 38.424 27.8C37.3747 30.2333 35.95 32.35 34.15 34.15C32.35 35.95 30.2333 37.3747 27.8 38.424C25.3667 39.4747 22.7667 40 20 40Z" fill="#00B49C"/>
                                </svg>
                                <span>Efficient communication</span>
                            </li>
                        </ul>
                        <div class="audience-btn">
                            <router-link  to="/contact" class="text-capitalize">Contact Us</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Design'
    }
</script>