<template>
    <div class="digital-section pt-70 pb-35">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 ">
                    <div class="digital-image text-center text-lg-start">
                        <img src="../../assets/images/digital.png" alt="image" class="w-90">
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="digital-content">
                        <h3>Our Team's <span>Advantages</span></h3>
                        <div class="bar"></div>
                        <!----<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices  Lorem Ipsum is simply dummy tex printing and typesetting industry. Lorem Ipsum has been the industry</p>
                    -->
                        <ul class="digital-list">
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    Previous experiences and in-depth knowledge in various SEO aspects.
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    Monitoring updates from different search engines and adapting SEO strategies accordingly.
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    Regularly monitoring website performance to identify any areas that need improvement.
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    Specialization in protecting your website from sudden external attacks.
                                </div>
                            </li>
                            <li class="hover-effect-2 d-flex justify-content-start">
                                <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                </svg>
                                </span>
                                <div class="ms-3">
                                    Expertise in search engine advertising.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Digital'
    }
</script>