<template>
    <div class="container">
       <header>
            <div class="text-center header-lap-img position-relative">
                <div class="top-left-img">
                    <img src="../../assets/images/Images/portfolio-top-left-img.webp" width="130" height="192" alt="mobile">
                </div>
            
                    <img src="../../assets/images/Images/portfolio-lap.webp" alt="Portofolio header laptop" width="573" height="417">
                <div class="bottom-right-img">
                    <img src="../../assets/images/Images/portfolio-bottom-right-img.webp"  width="130" height="192" alt="mobile">
                </div>
            </div>
       </header>
    </div>
</template>
<style lang="scss" scoped>
    .header-lap-img{
        margin-top: 0
    }
</style>