<template>
    <div>
        <section class="container computer-security-section pt-35 pb-35">
            <div class="row mt-lg-5">
                <section class="hover-effect rounded-1 col-md-4 text-center mt-3 mt-lg-0">
                    <div>
                        <img src="../../assets/images/computer-security-1.svg">
                    </div>
                    <div>
                        <h3>Computer Security</h3>
                        <p>
                            We deploy cutting-edge antivirus software, intrusion detection systems, and endpoint security solutions to detect and neutralize potential threats before they wreak havoc on your systems.
                        </p>
                    </div>
                </section>
                <section class="hover-effect rounded-1  col-md-4 text-center mt-3 mt-lg-0">
                    <div>
                        <img src="../../assets/images/computer-security-2.svg">
                    </div>
                    <div>
                        <h3>Secure Cloud Storage</h3>
                        <p>
                            Whether you're storing sensitive documents, customer records, or intellectual property, our cloud security measures ensure that your data remains protected against unauthorized access or cyber-attacks.
                        </p>
                    </div>
                </section>
                <section class="hover-effect rounded-1  col-md-4 text-center mt-3 mt-lg-0">
                    <div>
                        <img src="../../assets/images/computer-security-3.svg">
                    </div>
                    <div>
                        <h3>Data Protection</h3>
                        <p>
                            We implement advanced data protection measures, including encryption protocols, and data loss prevention strategies to ensure the confidentiality of your critical information.
                        </p>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>