<template>
    <div class="company-section pt-35 pb-35">
        <div class="container ">
            <div class="row align-items-center">
                <div class="col-lg-6 mt-3">
                    <section class="company-section-content">
                        <div>
                            <span>Our Company</span>
                            <h2>Who are we?</h2>
                            <p>
                                Misk ITS. is a leading technology company in digital industries.We are specialized in E-learning platforms and Organizations Management Systems. We are expanding and seeking fresh and experienced developers to be a part of our talented software team that works on mission-critical applications. We are looking for Full Stack Developers, Back End Developers, Front End Developers, UI/UX Designers, Digital Marketing Specialists, Sales Agents, and Receiptionts. For more info, you can reach us on our 
                                <a href="#" target="_blank">website: https://www.misk.com.eg/wp</a> .
                            </p>
                        </div>
                        <footer>
                            <a href="#" target="_blank"><i class="fa-brands fa-square-facebook"></i></a>
                            <a href="#" target="_blank"><i class="fa-brands fa-square-x-twitter"></i></a>
                            <a href="#" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                            <a href="#" target="_blank"><i class="fa-brands fa-square-snapchat"></i></a>
                            <a href="#" target="_blank"><i class="fa-brands fa-square-youtube"></i></a>
                        </footer>
                    </section>
                </div>
                <div class="col-lg-6 mt-5 mt-lg-3">
                    <div class="support-image text-center">
                        <img src="../../assets/images/Images/job-company.webp" alt="image" width="409" height="331">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'OurCompany'
    }
</script>