<template>
    <div class="container">
        <div class="single-case-study-feature">
            <section class="single-case-study-text">
                <h2 class="feature">App Feature</h2>
                <div class="row">
                    <ul class="col-lg-4 col-md-6">
                        <li class="d-flex justify-content-start">
                            <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                        <li class="d-flex justify-content-start">
                            <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                        <li class="d-flex justify-content-start">
                            <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                        
                    </ul>
                    <ul class="col-lg-4 col-md-6">
                        <li class="d-flex justify-content-start">
                            <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                        <li class="d-flex justify-content-start">
                              <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                        <li class="d-flex justify-content-start">
                            <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                    </ul>
                    <ul class="col-lg-4 col-md-6">
                        <li class="d-flex justify-content-start">
                            <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                        <li class="d-flex justify-content-start">
                            <span class="d-block">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M17.3551 2.49023L20 5.1352L7.62759 17.5076L0 9.88018L2.64488 7.23521L7.62759 12.2178L17.3551 2.49023Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            Our redesigning process included meeting.
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    </div>
</template>