<template>
    <div>
        <Navbar />
        <RoleHeader></RoleHeader>
        <OverviewApp/>
        <Footer />
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import RoleHeader from '../RoleOverview/RoleHeader'
    import OverviewApp from '../RoleOverview/OverviewApp'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'RoleOverview',
        components: {
            Navbar,
            RoleHeader,
            OverviewApp,
            Footer,

        },
        data(){
           return{
            TabChanged: false,
           }
        },
        setup(){
            useHead({
                title: 'Misk - Job Overview',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        },
        mounted(){
        localStorage.setItem('TabChanged', this.TabChanged.toString())
        }
     
    }
</script>