<template>
    <div>
        <Navbar />
        <services-header></services-header>
        <best-software></best-software>
        <!----<Services></Services>-->
        <new-services></new-services>
        
        <Footer />

    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import ServicesHeader from '../MiskServices/ServicesHeader'
    import BestSoftware from '../MiskServices/BestSoftware'
    /*import Services from '../HomeOne/Services'*/
    import NewServices from '../HomeOne/NewServices'
    /*import Faq from '../MiskServices/Faq'*/
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default {
     
        components: {
            Navbar,
            BestSoftware,
          /*  Services,*/
           /* Faq,*/
            Footer,
            ServicesHeader,
            NewServices,
        },
        setup(){
            useHead({
                title: 'Misk - Services Page',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>