<template>
    <section class="vps-difference ptb-100">
        <div class="container position-relative">
            <div class="top-left">
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                <path d="M72 72L6.29444e-06 72L72 0L72 72Z" fill="#17D7B5"/>
                </svg>
            </div>
            <h2>What is the difference between the<br>
                VBS and the private server?
            </h2>
            <p>
                The virtual server is considered a small part of the private server or the full server, so its resources are less,<br>
                and there is less control over it. It lives up to the private server and your site visits are not borne by normal shared hosting
            </p>
            <div class="bottom-right">
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 72 72" fill="none">
                <path d="M72 72L6.29444e-06 72L72 0L72 72Z" fill="#17D7B5"/>
                </svg>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'VpsDifference'
}
</script>