<template>
    <div class="audience-section pt-35 pb-35">
        <div class="container">
            <div class="section-title">
                <span>Last Work</span>
                <h2>Our Work</h2>
                <div class="bar"></div>
            </div>
            <!------Start Of First section-->
            <section class="row align-items-center justify-content-center justify-content-lg-start text-center 
            text-lg-start mt-4 mt-lg-1">
                <div class="col-lg-6">
                    <div class="audience-content">
                        <h3>Elbalaty Website </h3>
                        <span class="me-3">Web Design</span> 
                        <span>Web Development</span>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori.
                        </p>

                        <div class="audience-btn">
                            <router-link tag="button" to="/" class="text-capitalize">View case study</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mt-4 mt-lg-0">
                    <div class="audience-image">
                        <img src="../../assets/images/home-Macbook.png" alt="image" height="340" width="602">
                    </div>
                </div>
            </section>
            <!--------End of First section------------->
            <!--------Start Of Second section------->
            <section class="row align-items-center second-section justify-content-center justify-content-lg-start 
            text-center text-lg-start">
                <div class="col-lg-6 mt-1 mb-md-3 mb-lg-0 order-2 order-lg-1">
                    <div class="audience-image mx-auto mt-4 m-lg-0 ">
                        <img src="../../assets/images/home-mobile.png" alt="image" height="479" width="304">
                    </div>
                </div>
                <div class="col-lg-6 mt-3 order-1 order-lg-2">
                    <div class="audience-content">
                        <h3>Elbalaty Website </h3>
                        <span class="me-3">Web Design</span> 
                        <span>Web Development</span>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori.
                        </p>

                        <div class="audience-btn">
                            <router-link tag="button" to="/" class="text-capitalize">View case study</router-link>
                        </div>
                    </div>
                </div>
            </section>
            <!--------End Of Second Section--------->
            <!-----Start of Third section-------------->
            <section class="row align-items-center third-section justify-content-center justify-content-lg-start 
            text-center text-lg-start ">
                <div class="col-lg-6 mb-1 mb-md-3 mb-lg-0">
                    <div class="audience-content">
                        <h3>Elbalaty Website </h3>
                        <span class="me-3">Web Design</span> 
                        <span>Web Development</span>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco labori.
                        </p>

                        <div class="audience-btn">
                            <router-link tag="button" to="/" class="text-capitalize">View case study</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="audience-image">
                        <img src="../../assets/images/home-Macbook.png" alt="image" height="340" width="602">
                    </div>
                </div>
            </section>
            <!-----End of third section---------------->
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'UiWork'
    }
</script>