<template>
  <div>
    <navbar-style-two></navbar-style-two>
    <qa-header></qa-header>
    <qa-overview></qa-overview>
    <!----<testing-process></testing-process>
    <testing-steps></testing-steps>-->
    <app-services></app-services>
    <app-process></app-process>
    <qa-work-with-us></qa-work-with-us>
    <Faq />
    <Footer />
   
  </div>
</template>

<script>
import NavbarStyleTwo from "../Layout/NavbarStyleTwo";
import QaHeader from "../AppDevelopment/QaHeader";
import QaWorkWithUs from "../AppDevelopment/QaWorkWithUs";
import QaOverview from "../AppDevelopment/QaOverview";
/*import TestingProcess from "../AppDevelopment/TestingProcess";
import TestingSteps from "../AppDevelopment/TestingSteps";*/
import AppServices from "../AppDevelopment/AppServices.vue";
import AppProcess from "../AppDevelopment/AppProcess.vue";
import Faq from '../AppDevelopment/Faq'
import Footer from "../Layout/Footer";
import { useHead } from "@vueuse/head";
export default {
  components: {
    NavbarStyleTwo,
    QaHeader,
    QaWorkWithUs,
    QaOverview,
    /*TestingProcess,
    TestingSteps,*/
    Faq,
    AppServices,
    AppProcess,
    Footer,
  },
  setup(){
            useHead({
                title: 'Misk - Quality Assurance',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
};
</script>
