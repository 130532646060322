<template>
    <div class="container mb-5">
        <section class=" job-benefits">
            <header class="text-center">
                <h4>Benefits</h4>
            </header>
            <div class="row">
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-1">
                    <div>
                        <h4>Flexible working</h4>
                        <ul>
                            <li>Work from home or in our awesome Christchurch office.</li>
                            <li>
                                Flexible hours to suit your lifestyle
                            </li>
                        </ul>
                    </div>
                </section>
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-1">
                    <div>
                        <h4>Competitive renumeration</h4>
                        <ul>
                            <li>Work alongside a team of smart creative people.</li>
                            <li>
                                Regular R&D time to stay ahead of the game
                            </li>
                        </ul>
                    </div>
                </section>
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-5">
                    <div>
                        <h4>Bonus bits</h4>
                        <ul>
                            <li>Unlimited data, call and text phone plan.</li>
                            <li>
                                Spotify subscription.
                            </li>
                        </ul>
                    </div>
                </section>
                <section class="col-lg-6 job-benefits-content mt-3 mt-lg-5">
                    <div>
                        <h4>Stay fuelled</h4>
                        <ul>
                            <li>Fresh fruit and snacks provided.</li>
                            <li>
                                Barista coffee machine and soft and slightly hard drinks.
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
            <p class="last mt-4 mt-lg-5">If you think you fit the bill please send us a covering letter and your CV.</p>
        </section>
    </div>
</template>