<template>
    <div class="services-section pt-35 pb-35  server-features">
        <div class="container">
            <header class="section-title">
                <div class="plan">Features</div>
                <h2>Why choose to host you in <span class="fw-bolder">Misk?</span></h2>
                <p>
                    Exclusive features provided by <span > Misk</span> to its customers
                </p>
                <div class="bar"></div>
            </header>

            <section class="row">
                <section class="col-lg-4 col-md-6">
                    <div class="single-services-two">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <g clip-path="url(#clip0_1_5258)">
                                    <path d="M2.66602 32V40C2.66602 40.7072 2.94697 41.3855 3.44706 41.8856C3.94716 42.3857 4.62544 42.6667 5.33268 42.6667H42.666C43.3733 42.6667 44.0515 42.3857 44.5516 41.8856C45.0517 41.3855 45.3327 40.7072 45.3327 40V32H2.66602ZM39.9994 38.6667H34.666V36H39.9994V38.6667Z" fill="#00B79D"/>
                                    <path d="M17.3335 13.2275L22.6669 8.00086V24.0009C22.6669 24.3545 22.8073 24.6936 23.0574 24.9437C23.3074 25.1937 23.6466 25.3342 24.0002 25.3342C24.3538 25.3342 24.693 25.1937 24.943 24.9437C25.1931 24.6936 25.3335 24.3545 25.3335 24.0009V8.00086L30.6669 13.2675C30.7914 13.3911 30.9392 13.4889 31.1017 13.5552C31.2641 13.6216 31.4381 13.6552 31.6135 13.6542H31.7602C30.8156 12.2564 30.2421 10.6413 30.0935 8.96086L24.0002 2.88086L15.4802 11.3342C15.2737 11.5883 15.1679 11.9095 15.1831 12.2366C15.1982 12.5637 15.3331 12.8737 15.5622 13.1077C15.7912 13.3417 16.0983 13.4832 16.425 13.5053C16.7517 13.5274 17.0751 13.4285 17.3335 13.2275Z" fill="#00B79D"/>
                                    <path d="M41.1598 17.9333C40.7747 17.9779 40.3874 18.0001 39.9998 18C37.84 18.001 35.7386 17.2989 34.0131 16H27.9998V24C27.9998 25.0609 27.5783 26.0783 26.8282 26.8284C26.078 27.5786 25.0606 28 23.9998 28C22.9389 28 21.9215 27.5786 21.1713 26.8284C20.4212 26.0783 19.9998 25.0609 19.9998 24V16H9.42643C8.87024 15.9781 8.32205 16.138 7.86491 16.4556C7.40778 16.7732 7.06661 17.2311 6.8931 17.76C3.8131 26.24 2.98643 28.5733 2.75977 29.3333H45.2398C45.0264 28.5733 44.1998 26.2667 41.1598 17.9333Z" fill="#00B79D"/>
                                    <path d="M40.0007 14.6673C43.6825 14.6673 46.6673 11.6825 46.6673 8.00065C46.6673 4.31875 43.6825 1.33398 40.0007 1.33398C36.3188 1.33398 33.334 4.31875 33.334 8.00065C33.334 11.6825 36.3188 14.6673 40.0007 14.6673Z" fill="#00B79D"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1_5258">
                                    <rect width="48" height="48" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        <h3>Innovative mail solutions</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec sagittis dolor.
                        </p>
                        
                    </div>
                </section>

                <section class="col-lg-4 col-md-6">
                    <div class="single-services-two bg-fc9ba7">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                            <path d="M29.27 38.0617C27.7128 38.6806 26.006 38.8277 24.3647 38.4846C22.7234 38.1414 21.2212 37.3233 20.0476 36.1335C18.874 34.9437 18.0816 33.4355 17.7704 31.7991C17.4591 30.1626 17.643 28.4714 18.2987 26.9386L23.864 32.4643L28.369 27.9893L22.8058 22.4636C24.3751 21.7989 26.1099 21.6201 27.7832 21.9504C29.4565 22.2807 30.9905 23.1047 32.1844 24.3148C33.3784 25.5248 34.1766 27.0644 34.4748 28.7323C34.773 30.4002 34.5573 32.1185 33.8558 33.6626L37.9634 37.741C39.9433 34.9197 41.0032 31.5629 41 28.1243C40.9695 22.708 39.7122 17.368 37.3212 12.4996C34.9302 7.63121 31.4668 3.35922 27.1875 0C28.0954 3.30593 28.6229 6.70337 28.76 10.1273C28.8355 11.1233 28.7049 12.124 28.3763 13.068C28.0477 14.012 27.5281 14.8793 26.8493 15.6165C26.1705 16.3537 25.347 16.9451 24.4295 17.3545C23.5119 17.7639 22.5196 17.9826 21.5138 17.997C20.4835 17.9945 19.4641 17.7877 18.5154 17.3887C17.5668 16.9896 16.708 16.4065 15.9894 15.6734C15.2709 14.9403 14.707 14.0721 14.3311 13.1197C13.9551 12.1673 13.7746 11.1499 13.8001 10.1273L13.8638 9.36773C9.41677 14.619 6.98528 21.2635 7.00007 28.1243C7.00063 31.1775 7.83527 34.1733 9.4151 36.7927C10.9949 39.4121 13.2608 41.557 15.9713 42.999C18.6818 44.4409 21.7355 45.1259 24.8071 44.981C27.8787 44.836 30.8532 43.8666 33.4138 42.1759L29.27 38.0617Z" fill="#00B79D"/>
                            </svg>
                        </div>
                        <h3>Innovative mail solutions</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec sagittis dolor.
                        </p>
                    </div>
                </section>

                <section class="col-lg-4 col-md-6 d-flex">
                    <div class="single-services-two bg-2cdcf7">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M8.94141 36.0279L39.0591 25.9883V14.7949L8.94141 24.8345V36.0279Z" fill="#00B79D"/>
                                <path d="M39.0591 10.793L8.94141 21.0113V22.8523L39.0591 12.8127V10.793Z" fill="#00B79D"/>
                                <path d="M39.0591 8.80376V6.51106C39.0591 4.83482 38.0539 3.34965 36.4962 2.72659C35.8666 2.47529 35.2943 1.62824 35.2943 0.941176C35.2943 0.420706 34.8736 0 34.3532 0H13.6473C13.1268 0 12.7061 0.431059 12.7061 0.951529C12.7061 1.62918 12.1339 2.47529 11.5042 2.72659C9.94658 3.34965 8.94141 4.83482 8.94141 6.51106V19.0231L39.0591 8.80376ZM22.1179 5.64706H34.3532C34.8736 5.64706 35.2943 6.06776 35.2943 6.58823C35.2943 7.10871 34.8736 7.52941 34.3532 7.52941H22.1179C21.5974 7.52941 21.1767 7.10871 21.1767 6.58823C21.1767 6.06776 21.5974 5.64706 22.1179 5.64706ZM13.6473 5.64706H18.3532C18.8736 5.64706 19.2943 6.06776 19.2943 6.58823C19.2943 7.10871 18.8736 7.52941 18.3532 7.52941H13.6473C13.1268 7.52941 12.7061 7.10871 12.7061 6.58823C12.7061 6.06776 13.1268 5.64706 13.6473 5.64706Z" fill="#00B79D"/>
                                <path d="M8.94141 39.7938L39.0591 29.7543V27.9727L8.94141 38.0122V39.7938Z" fill="#00B79D"/>
                                <path d="M8.96289 41.7693C9.06924 43.3279 10.0358 44.686 11.5041 45.2742C12.1347 45.5246 12.7069 46.3716 12.7069 47.0587C12.7069 47.5792 13.1276 47.9999 13.6481 47.9999H34.354C34.8744 47.9999 35.2951 47.5688 35.2951 47.0483C35.2951 46.3707 35.8674 45.5246 36.497 45.2733C38.0537 44.6502 39.0598 43.165 39.0598 41.4888V31.7363L8.96289 41.7693ZM18.3539 43.294H13.6481C13.1276 43.294 12.7069 42.8733 12.7069 42.3528C12.7069 41.8323 13.1276 41.4116 13.6481 41.4116H18.3539C18.8744 41.4116 19.2951 41.8323 19.2951 42.3528C19.2951 42.8733 18.8744 43.294 18.3539 43.294ZM34.354 43.294H22.1187C21.5982 43.294 21.1775 42.8733 21.1775 42.3528C21.1775 41.8323 21.5982 41.4116 22.1187 41.4116H34.354C34.8744 41.4116 35.2951 41.8323 35.2951 42.3528C35.2951 42.8733 34.8744 43.294 34.354 43.294Z" fill="#00B79D"/>
                            </svg>
                        </div>
                        <h3>Innovative mail solutions</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec sagittis dolor.
                        </p>
                    </div>
                </section>

                <section class="col-lg-4 col-md-6 d-flex">
                    <div class="single-services-two bg-009af0">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M21.0125 31.875C21.9125 32.775 23.075 33.2153 24.5 33.1957C25.925 33.1777 26.975 32.6625 27.65 31.65L37.1562 17.4187C37.4938 16.8937 37.4465 16.416 37.0145 15.9855C36.584 15.5535 36.1062 15.5063 35.5812 15.8438L21.35 25.35C20.3375 26.025 19.8035 27.0563 19.748 28.4438C19.691 29.8313 20.1125 30.975 21.0125 31.875ZM8.975 42C8.1875 42 7.4375 41.8125 6.725 41.4375C6.0125 41.0625 5.45 40.5375 5.0375 39.8625C4.0625 38.175 3.3125 36.3848 2.7875 34.4918C2.2625 32.5972 2 30.6 2 28.5C2 25.3875 2.591 22.4715 3.773 19.752C4.9535 17.034 6.566 14.6528 8.6105 12.6082C10.6535 10.5652 13.0437 8.95275 15.7812 7.77075C18.5187 6.59025 21.425 6 24.5 6C27.6125 6 30.5278 6.59025 33.2458 7.77075C35.9653 8.95275 38.3465 10.5652 40.3895 12.6082C42.434 14.6528 44.0465 17.034 45.227 19.752C46.409 22.4715 47 25.3875 47 28.5C47 30.6 46.7472 32.5777 46.2417 34.4332C45.7347 36.2902 44.975 38.1 43.9625 39.8625C43.55 40.5375 42.9875 41.0625 42.275 41.4375C41.5625 41.8125 40.8125 42 40.025 42H8.975Z" fill="#00B79D"/>
                            </svg>
                        </div>
                        <h3>Innovative mail solutions</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec sagittis dolor.
                        </p>
                    </div>
                </section>

                <section class="col-lg-4 col-md-6 d-flex">
                    <div class="single-services-two bg-f4d62c">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M24 2L6 10V22C6 33.1 13.68 43.48 24 46C34.32 43.48 42 33.1 42 22V10L24 2ZM24 23.98H38C36.94 32.22 31.44 39.56 24 41.86V24H10V12.6L24 6.38V23.98Z" fill="#00B79D"/>
                            </svg>
                        </div>
                       <h3>Innovative mail solutions</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec sagittis dolor.
                        </p>
                    </div>
                </section>

                <section class="col-lg-4 col-md-6 d-flex">
                    <div class="single-services-two">
                        <div class="icon">
                           <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                <path d="M20 10L24 6M16 32L32 16M24 42L28 38M33 38C34.3261 38 35.5979 37.4732 36.5355 36.5355C37.4732 35.5979 38 34.3261 38 33C38 31.6739 37.4732 30.4021 36.5355 29.4645C35.5979 28.5268 34.3261 28 33 28C31.6739 28 30.4021 28.5268 29.4645 29.4645C28.5268 30.4021 28 31.6739 28 33C28 34.3261 28.5268 35.5979 29.4645 36.5355C30.4021 37.4732 31.6739 38 33 38V38ZM15 20C16.3261 20 17.5979 19.4732 18.5355 18.5355C19.4732 17.5979 20 16.3261 20 15C20 13.6739 19.4732 12.4021 18.5355 11.4645C17.5979 10.5268 16.3261 10 15 10C13.6739 10 12.4021 10.5268 11.4645 11.4645C10.5268 12.4021 10 13.6739 10 15C10 16.3261 10.5268 17.5979 11.4645 18.5355C12.4021 19.4732 13.6739 20 15 20V20ZM11 42C12.3261 42 13.5979 41.4732 14.5355 40.5355C15.4732 39.5979 16 38.3261 16 37C16 35.6739 15.4732 34.4021 14.5355 33.4645C13.5979 32.5268 12.3261 32 11 32C9.67392 32 8.40215 32.5268 7.46447 33.4645C6.52678 34.4021 6 35.6739 6 37C6 38.3261 6.52678 39.5979 7.46447 40.5355C8.40215 41.4732 9.67392 42 11 42V42ZM37 16C38.3261 16 39.5979 15.4732 40.5355 14.5355C41.4732 13.5979 42 12.3261 42 11C42 9.67392 41.4732 8.40215 40.5355 7.46447C39.5979 6.52678 38.3261 6 37 6C35.6739 6 34.4021 6.52678 33.4645 7.46447C32.5268 8.40215 32 9.67392 32 11C32 12.3261 32.5268 13.5979 33.4645 14.5355C34.4021 15.4732 35.6739 16 37 16Z" stroke="#00B79D" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                           </span>
                        </div>
                        <h3>Innovative mail solutions</h3>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec sagittis dolor.
                        </p>
                    </div>
                </section>
            </section>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServerFeatures'
    }
</script>