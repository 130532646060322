<template>
    <div class="overview-section pt-70 pb-35">
        <div class="container">
            <div class="section-title">
                <span>Our Process</span>
                <h2>Our Working  Process</h2>
                <p>
                    At Misk, we measure our success by yours, and we are committed to helping you succeed every step of the journey.
                </p>
                <div class="bar"></div>
            </div>

            <div class="row align-content-center justify-content-between mt-3">
                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content text-center">
                        <div class="icon">
                            <img src="../../assets/images/find-new-ideas.svg" alt="find new Ideas">
                        </div>

                        <h3>Discussion</h3>
                        <p>
                            Your journey with us begins by meeting with our team to discuss your needs and goals, generating innovative ideas that set you apart from your competitors.
                        </p>
                        <div class="first-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                    
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/digital-analysis.svg" alt="find new Ideas">
                        </div>

                        <h3>Analysis</h3>
                        <p>
                            Our team then analyzes the market and studies competitors to provide effective ideas and strategies for the success of your project.
                        </p>
                        <div class="second-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/market-research.svg" alt="find new Ideas">
                        </div>

                        <h3>Planning</h3>
                        <p>
                            We identify the appropriate steps to implement your project in the best possible way, ensuring the achievement of your goals.
                        </p>
                        <div class="third-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-creation.svg" alt="find new Ideas">
                        </div>

                        <h3>Development</h3>
                        <p>
                            We develop your project based on the defined steps, paying attention to all the details and quality standards that ensure the success of your project.
                        </p>
                        <div class="forth-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-launching.svg" alt="find new Ideas">
                        </div>

                        <h3>Follow-up</h3>
                        <p>
                            Our customer service team continuously communicates with you to ensure your satisfaction with our services and address any issues you may encounter.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Overview'
    }
</script>