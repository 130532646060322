<template>
    <div class="product-footer">
        <section class="container">
            <div class="row">
                <div class="col-lg-6 order-2 order-lg-1 ">
                    <h4>Integrated with your favorite tools!</h4>
                    <p>
                        If you use Zoom for virtual meetings, Mailchimp for email marketing, PayPal and Stripe for payments, WhatsApp for communication, or Zapier and Twilio for automation and messaging, you'll be happy to know that our systems are built to work seamlessly with your preferred tools. This integration simplifies your workflow and improves efficiency.
                    </p>
                </div>
                <div class="col-lg-6 order-1 order-lg-2 mt-4 mt-lg-0">
                    <div>
                        <img src="../../assets/images/Images/test.webp" alt="vendors" width="641" height="452">
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>