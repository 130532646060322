<template>
    <div class="container">
        <section class="product-feature pt-35 pb-35">
            <div class="row">
                <div class="col-lg-6">
                    <h3>What makes our E-Learning Systems different?</h3>
                    <ul class="unstayled-list">
                        <li class="d-flex align-items-baseline">
                            <diV class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                                    <path d="M14.7518 1.86719L17 3.85091L6.48345 13.1302L0 7.40965L2.24815 5.42592L6.48345 9.16283L14.7518 1.86719Z" fill="#00B79D"/>
                                </svg>
                            </diV>
                            <div class="list-text">
                                <span>Ability to upload different types of courses on the website.</span>
                            </div>
                        </li>
                        <li class="d-flex align-items-baseline">
                            <diV class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                                    <path d="M14.7518 1.86719L17 3.85091L6.48345 13.1302L0 7.40965L2.24815 5.42592L6.48345 9.16283L14.7518 1.86719Z" fill="#00B79D"/>
                                </svg>
                            </diV>
                            <div class="list-text">
                                <span> Activating the interactive electronic testing system.</span>
                            </div>
                        </li>
                        <li class="d-flex align-items-baseline">
                            <diV class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                                    <path d="M14.7518 1.86719L17 3.85091L6.48345 13.1302L0 7.40965L2.24815 5.42592L6.48345 9.16283L14.7518 1.86719Z" fill="#00B79D"/>
                                </svg>
                            </diV>
                            <div class="list-text">
                                <span>Possibility of electronic payment.</span>
                            </div>
                        </li>
                        <li class="d-flex align-items-baseline">
                            <diV class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                                    <path d="M14.7518 1.86719L17 3.85091L6.48345 13.1302L0 7.40965L2.24815 5.42592L6.48345 9.16283L14.7518 1.86719Z" fill="#00B79D"/>
                                </svg>
                            </diV>
                            <div class="list-text">
                                <span>Activating an affiliate marketing system.</span>
                            </div>
                        </li>
                        <li class="d-flex align-items-baseline">
                            <diV class="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="15" viewBox="0 0 17 15" fill="none">
                                    <path d="M14.7518 1.86719L17 3.85091L6.48345 13.1302L0 7.40965L2.24815 5.42592L6.48345 9.16283L14.7518 1.86719Z" fill="#00B79D"/>
                                </svg>
                            </diV>
                            <div class="list-text">
                                <span>Create control panels for different users.</span>
                            </div>
                        </li>
                    </ul>
                    <div >
                        <a href="#" class="try-btn">Try it now</a>
                    </div>
                </div>

                <div class="col-lg-6 d-flex justify-content-center align-items-center mt-4 mt-lg-0 col-">
                    <img src="../../assets/images/Images/product-features.webp" alt='product features' width="727" height="351"> 
                </div>
            </div>
        </section>
    </div>
</template>