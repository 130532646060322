<template>
    <div>
        <div class="container">
            <Section class="ui-overview pt-35 pb-35">
            <div class="row ">
                <div class="col-lg-6">
                    <header>
                        <!----<span class="span-overview">Overview</span>-->
                        <h2>Why is it important to establish a professional brand identity?</h2>
                    </header>
                    <p>
                        Establishing a professional brand identity is crucial for businesses looking to stand out in today's competitive market. At Misk, we understand the significance of strong brand identity and offer comprehensive brand identity design services to help you achieve your goals. Here's why investing in professional brand identity is important:
                    </p>
               
                </div>
                <div class="col-lg-6 text-center  mt-3 mt-lg-0 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/Images/ui-overview.webp" alt="overview image" height="475" width="382" class="rounded-3"/>
                </div>
            </div>
            <div class="row">
                <ul class="graphic-overview">
                        <li class="hover-effect-2">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            <span>
                                Build trust and credibility in your products or services.
                            </span>
                        </li>
                        <li class="hover-effect-2">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            <span>
                                Stand out from competitors.
                            </span>
                        </li>
                        <li class="hover-effect-2">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            <span>
                                Easy recognition of your brand.
                            </span>
                        </li>
                        <li class="hover-effect-2">
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                    <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                </svg>
                            </span>
                            <span>
                                Strengthen relationships with customers and partners.
                            </span>
                        </li>
                    </ul>
            </div>
        </Section>
        </div>
        
    </div>
</template>