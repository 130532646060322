<template>
    <div class="container pt-70 pb-70">
        <section class="product-dashboard">
            <div class="row">  
                <div class="col-lg-6 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/Images/dashboardimg.webp" alt="" width="638" height="341">
                </div>

                <div class="col-lg-6 d-flex align-items-center ">
                    <div class="ps-lg-5 mt-3">   
                        <h3>User Friendly Dashboard</h3>
                        <p>
                            One of the standout features of our educational systems is the user-friendly interface, which ensures smooth navigation and operation. 
                        <br>
                            This interface allows users of all levels, whether administrators, instructors, or learners, to effortlessly access and manage various aspects of the platform. Focusing on simplicity and efficiency, our seamless control panel enhances the overall user experience, making it easier for everyone to effectively engage with the platform.
                        </p>
                    </div>
                    
                </div>

            </div>
        </section>
    </div>
</template>