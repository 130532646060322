<template>
    <aside class="sidebar-area mb-5" id="secondary">
        <div class="widget widget_search">
            <h3 class="widget-title">Find a job</h3>
            <p>
                Create a job alert now and never miss any job update.
            </p>
            <form class="search-form search-top">
            
                <div class="form-group">
                    <label for="exampleInputEmail1" class="d-none">Email address</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                </div>
                <button type="submit" class="create-job" >
                    Create job alert
                </button>
            </form>
        </div> 

        <div class="widget widget_categories">
            <h3 class="widget-title">Job Overview</h3>
            <ul>
                <li>
                    <span>Job type :</span>
                    <span>Part Time</span>
                </li>
                <li>
                    <span>Experience: </span>
                    <span>3 Year(s)</span>
                </li>
                <li>
                    <span>Job Location:</span>
                    <span>PEl Quesier, Red Sea</span>
                </li>
                <li>
                    <span>Category:</span>
                    <span>Programming</span>
                </li>
                <li>
                    <span>Gender :</span>
                    <span>Both</span>
                </li>
                <li>
                    <span>Category :</span>
                    <span>Programming</span>
                </li>
                <li>
                    <span>Application Due:</span>
                    <span>02.02.2022</span>
                </li>
            </ul>
        </div>

        <div class="widget widget_categories">
            <h3 class="widget-title">Company Overview</h3>
            <div>
                <img src="../../assets/images/Images/map.webp" alt="our location" width="278" height="199" class="w-100">
            </div>
            <ul>
                <li>
                    <span>Established: </span>
                    <span>2000</span>
                </li>
                <li>
                    <span>Experience: </span>
                    <span>12 Year(s)</span>
                </li>
                <li>
                    <span>Employees:</span>
                    <span>50+ </span>
                </li>
                <li>
                    <span>Location:</span>
                    <span>Red Sea</span>
                </li>
                <li>
                    <span>Phone Number:</span>
                    <span>(+20) 01050931981</span>
                </li>
                <li>
                    <span>Category :</span>
                    <span>Programming</span>
                </li>
                <li>
                    <span>Email:</span>
                    <span>info@meskits.com</span>
                </li>
            </ul>
        </div>

    </aside>
</template>

<script>
    export default {
        name: 'AppSidebar'
    }
</script>