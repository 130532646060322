<template>
    <div>
        <div class="container pt-35 pb-35">
            <div class="row operation-center-section">
                <section class="col-lg-5">
                    <div class="top-image">
                        <img src="../../assets/images/Images/operaion-center-img-1.webp" alt="operation center first image" width="527.06" height="239.14">
                    </div>
                    <div class="d-flex justify-content-center align-items-center mt-2">
                        <div class="me-2">
                            <img src="../../assets/images/Images/operaion-center-img-2.webp" alt="operation center second image" width="252.77" height="239.14">
                        </div>
                        <div class="">
                            <img src="../../assets/images/Images/operaion-center-img-3.webp" alt="operation center third image" width="252.77" height="239.14" class="w-100">
                        </div>
                    </div>
                </section>
                <section class="col-lg-6 operation-center-content ps-lg-5 pe-xl-4 mt-4 mt-lg-0">
                    <div>
                        <h3>Our 24/7 Cybersecurity Operations Center</h3>
                        <p>
                            Our comprehensive Information Security Consultancy services cover both technical and administrative aspects of cybersecurity. From securing your networks and applications to implementing robust safety procedures. What's more, we conduct awareness sessions for all employees to ensure that everyone in your organization is equipped to recognize and mitigate potential security risks.
                        <br><br>
                        Our cybersecurity services include:
                        </p>
                        
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <ul>
                                <li class="hover-effect-2">
                                    <span class="operation-icon  me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                        </svg>
                                    </span>
                                   <span>  Malware Detection and Removal.</span>
                                </li>
                                <li class="hover-effect-2">
                                    <span class="operation-icon  me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                        </svg>
                                    </span>
                                   <span> Cybersecurity Threat Identification.</span>
                                </li>
                               
                                <li class="hover-effect-2">
                                    <span class="operation-icon  me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                        </svg>
                                    </span>
                                   <span>  Content Delivery Network (CDN).</span>
                                </li>
                            </ul>
                        </div>

                        <div class="col-md-6">
                            <ul>
                                <li class="hover-effect-2">
                                    <span class="operation-icon  me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                        </svg>
                                    </span>
                                   <span> Server Security.</span>
                                </li>
                                <li class="hover-effect-2">
                                    <span class="operation-icon  me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                        </svg>
                                    </span>
                                   <span> Security Management</span>
                                </li>
                                <li class="hover-effect-2">
                                    <span class="operation-icon  me-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                        <path d="M16.0013 2.66699C8.6413 2.66699 2.66797 8.64033 2.66797 16.0003C2.66797 23.3603 8.6413 29.3337 16.0013 29.3337C23.3613 29.3337 29.3346 23.3603 29.3346 16.0003C29.3346 8.64033 23.3613 2.66699 16.0013 2.66699ZM13.3346 22.667L6.66797 16.0003L8.54797 14.1203L13.3346 18.8937L23.4546 8.77366L25.3346 10.667L13.3346 22.667Z" fill="#00B79D"/>
                                        </svg>
                                    </span>
                                   <span> SIEM Threat Detection</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        name: 'OperationCenter'
    }
</script>