<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <cyber-header></cyber-header>
        <computer-security></computer-security>
        <OperationCenter></OperationCenter>
        <new-cyber-services></new-cyber-services>
        <cyber-security-features></cyber-security-features>

        <CyberWorkWithUs></CyberWorkWithUs>
        <Faq></Faq>
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import CyberHeader from '../CyberSecurity/CyberHeader'
    import ComputerSecurity from '../CyberSecurity/ComputerSecurity'
    import OperationCenter from '../CyberSecurity/OperationCenter'
    import NewCyberServices from '../CyberSecurity/NewCyberServices'
    import CyberWorkWithUs from '../CyberSecurity/CyberWorkWithUs'
    import Faq from '../CyberSecurity/Faq'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'
import CyberSecurityFeatures from '../CyberSecurity/CyberSecurityFeatures.vue'
    export default{
        components:{
            NavbarStyleTwo,
            CyberHeader,
            ComputerSecurity,
            CyberWorkWithUs ,
            Footer,
            OperationCenter,
            NewCyberServices,
            Faq,
                CyberSecurityFeatures
           /* SecurityServices*/
        },
        setup(){
            useHead({
                title: 'Misk - Cyber Security Service',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>