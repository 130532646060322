<template>
    <div class="server-specification">
        <section class="container my-4">
            <div class="row d-flex align-items-center text-center text-lg-start ">
                <div class="server-image col-lg-1 align-self-center">
                    <img src="../../assets/images/right-server.png" alt="server image" width="98" height="98">
                </div>
                <div class="server-heading col-lg-9 mt-3 mt-lg-0 align-sel">
                    <h3>Choose your server specifications</h3>
                    <p>
                        Now design your own server with specifications that suit your requirements and needs,
                         with a wide range of CPU, RAM, Storage, Bandwidth and many other additional services.
                    </p>
                </div>
                <div class="col-lg-2 mt-3 mt-lg-0 text-align-center">
                    <button class="mx-auto">
                        <router-link to="/">subscribe now</router-link>
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name:'Specification'
}
</script>