<template>
    <div>
        <navbar-style-two></navbar-style-two>
        
        <privacy-parent></privacy-parent>
        <Footer />
      
    </div>
</template>

<script>
   import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import PrivacyParent from '../MiskPrivacyPolicy/PrivacyParent'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'
   
    export default {
        name: 'TermsConditionsPage',
        components: {
            NavbarStyleTwo,
            PrivacyParent,
            Footer
        },
        setup(){
            useHead({
                title: 'Misk - Privacy Policy',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>