<template>
    <div>
        <div class="container">
            <Section class="digital-overview pt-35 pb-35">
            <div class="row ">
                <div class="col-lg-6 hover-effect">
                    <header class="pt-2">
    
                        <h2>Track your marketing campaigns easily</h2>
                    </header>
                    <p>
                        With digital marketing tools and platforms, you can monitor the performance of their campaigns in real-time and gain valuable insights into various metrics such as website traffic, conversion rates, engagement levels, and ROI.
                    </p>
                    <p>
                        This allows you to assess the effectiveness of their strategies promptly and make data-driven decisions to optimize campaign performance. 
                    </p>
                </div>
                <div class="col-lg-6 text-center  mt-3 mt-lg-0 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/Images/SEO-Optimization-Image.webp" alt="overview image" height="527" width="285" class="img-fluid rounded-3"/>
                </div>
            </div>
         </Section>
        </div> 
    </div>
</template>