<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <dev-header></dev-header>
        <dev-custom></dev-custom>
        <DevChoose/>
        <dev-technology></dev-technology>
        <work-with-us></work-with-us>
        <development-process></development-process>
        <Faq></Faq>
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import DevHeader from '../Development/DevHeader'
    import DevCustom from '../Development/DevCustom'
    import DevTechnology from '../Development/DevTechnology'
    import DevChoose from '../Development/DevChoose'
    import WorkWithUs from '../Development/WorkWithUs'
    import Faq from '../Development/Faq'
    import DevelopmentProcess from '../Development/DevelopmentProcess'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default{
        components:{
            NavbarStyleTwo,
            DevHeader,
            DevCustom,
            DevChoose,
            Faq,
            DevTechnology,
            WorkWithUs,
            Footer,
            DevelopmentProcess,
        },
        setup(){
            useHead({
                title: 'Misk - Development Services page',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>