<template>
    <div>
        <Navbar />
        <page-title></page-title>
        <SubscribeForm/>
        <Footer />
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import SubscribeForm from '../Subscribe/SubscribeForm'
    import Footer from '../Layout/Footer'
    import PageTitle from '../Subscribe/PageTitle'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'Subscribe',
        components: {
            Navbar,
            SubscribeForm,
            PageTitle,
            Footer
        },
        setup(){
            useHead({
                title: 'Misk - Subscribe',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>