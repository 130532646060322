<template>
    <div class="login-section ptb-100 ">
      <div class="container">
        <section class="login-form">
          <div class="row">
            <div class="col-lg-6 text-center">
                <img
                  src="../../assets/images/Images/sign-up.webp"
                  alt="log in vector"
                  width="528"
                  height="459"
                />
            </div>
            <div class="col-lg-6">
              <div class="login-title d-flex justify-content-center align-items-center">
              <h3>Sign Up</h3>
              </div>
              <form @submit.prevent>
                <div class="row">
                    <div class="col-lg-6">
                    <div class="form-group">
                      <label> First Name</label>
                      <input
                        type="text"
                        class="form-control" name="firstname"
                      />
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        class="form-control" name="lastname"
                      />
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group">
                      <label> Email Address</label>
                      <input
                        type="email"
                        class="form-control"
                      />
                    </div>
                  </div>
  
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        class="form-control"
                      />
                    </div>
                  </div>
                  
                  <div class="col-lg-12">
                    <div class="form-group position-relative">
                      <label>Repeat Password</label>
                      <input
                      :type="showPassword ? 'text' : 'password'"
                        class="form-control"
                        name="password"
                      />
                      <button @click="togglePasswordVisibility" id="showPassword">
                    
                        <i :class="showPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"></i>
                    </button>
                    </div>
                  </div>
  
                  <div class="col-lg-12 d-flex justify-content-center align-items-center flex-column">
                    <div class="send-btn">
                      <button type="submit" class="default-btn">
                        Sign Up
                        <span></span>
                      </button>
                    </div>
                    <br />
                    <span
                      >Already a Member?
                      <router-link to="/log-in">Sign In</router-link></span
                    >
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
  
      <div class="default-shape">
        <div class="shape-1">
          <img src="../../assets/images/shapes/shape-4.png" alt="image" />
        </div>
  
        <div class="shape-2 rotateme">
          <img src="../../assets/images/shapes/shape-5.svg" alt="image" />
        </div>
  
        <div class="shape-3">
          <img src="../../assets/images/shapes/shape-6.svg" alt="image" />
        </div>
  
        <div class="shape-4">
          <img src="../../assets/images/shapes/shape-7.png" alt="image" />
        </div>
  
        <div class="shape-5">
          <img src="../../assets/images/shapes/shape-8.png" alt="image" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Signup",
    data(){
        return {
            password: '',
            showPassword: false,
        }
    },
    methods:{
        togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    }
  };
  </script>
  