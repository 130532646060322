<template>
    <div>
        <section id="application" class="application-tab" >
            <section class="container">
                <div>
                    <h3>Full Stack Developer Form</h3>
                </div>
                <form @submit.prevent="submitForm">
                    <div class="row">
                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="formGroupExampleInput">Full Name <span>*</span></label>
                                <input type="text" class="form-control" id="formGroupExampleInput">
                            </div>
                        </div>
                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="formGroupExampleInput2">Last Name <span>*</span></label>
                                <input type="text" class="form-control" id="formGroupExampleInput2">
                            </div>
                        </div>
                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="email">Email<span>*</span></label>
                                <input type="email" class="form-control" id="email">
                            </div>
                        </div>
                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="phone">Phone <span>*</span></label>
                                <input type="tel" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"  class="form-control">

                            </div>
                        </div>
                        <div class="col-12 application-section">
                            <div class="form-group">
                                <label for="address">Address<span>*</span></label>
                                <input type="text" class="form-control" id="address" name="address">
                            </div>
                        </div>


                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="city">City<span>*</span></label>
                                <input type="text" class="form-control" id="city" name="city">
                            </div>
                        </div>
                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="country">country <span>*</span></label>
                                <input type="text" id="country" name="country"  class="form-control" >

                            </div>
                        </div>

                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="info">How did you hear about this job? <span>*</span></label>        
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Please Select</option>
                                    <option>from a friend</option>
                                    <option>facebook</option>
                                    <option>linked in</option>
                                    <option>others</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-6 application-section">
                            <div class="form-group">
                                <label for="gender">Gender  <span>*</span></label>
                                <select class="form-select" aria-label="Default select example">
                                    <option selected>Please Select</option>
                                    <option>Male </option>
                                    <option>Female</option>
                                </select>
                            </div>
                        </div>

                        <div class="col-md-12 application-section">
                            <div class="form-group custom-input text-center">
                                <label for="file" style="display: block;">Drop files here or</label>
                                <input type="file" id="file" ref="fileInput" style="display: none;" @change="updateFileName">
                                <button type="button" @click="openFileInput" class="upload">Select Files</button>
                                <span v-if="fileName">{{ fileName }}</span>
                                <button type="submit" style="display: none;">Submit</button>
                            </div>
                            <span class="warning">Max. file size: 3 MB.</span>
                        </div>
                        <div class="col-md-12 application-section">
                            <div class="form-group ">
                            <label for="Textarea">Describe your experiences and previous works<span> *</span></label>
                            <textarea class="form-control" id="Textarea" rows="5"></textarea>
                            </div>
                        </div>

                        <div class="col-md-12 application-section">
                            <div class="form-group custom-input-1">
                                <label for="resume" style="display: block;">Resume </label>
                                <input type="file" id="resume" ref="fileInput" style="display: none;" @change="updateFileName"
                                accept=".pdf, .doc, .docx,.docx,.jpg,.txt,.jpeg,.png">
                                <button type="button" @click="openFileInput" class="choose">Choose Files</button>
                                <span v-if="fileName">{{ fileName }}</span>
                                <button type="submit" style="display: none;">Submit</button>
                            </div>
                            <span class="warning">Max. file size: 3 MB
                                (Allowed extensions : pdf , doc , docx , jpg , jpeg , png , txt)</span>
                        </div>
                        <div class="text-center mt-5">
                            <input type="Submit">
                        </div>
                    </div>   
                </form>
                
            </section>
        </section>
       
            <div class="pop-up" v-if="togglePopupShow === true">
                <div>
                    <button @click="togglePopupShow = false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M18 6L6 18" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 6L18 18" stroke="#303030" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                    <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="104" height="104" viewBox="0 0 104 104" fill="none">
                        <path d="M45.9317 71.9366L76.4817 41.3866L70.415 35.3199L45.9317 59.8033L33.5817 47.4533L27.515 53.5199L45.9317 71.9366ZM51.9984 95.3366C46.0039 95.3366 40.3706 94.1984 35.0984 91.9219C29.8262 89.6484 25.24 86.5616 21.34 82.6616C17.44 78.7616 14.3533 74.1755 12.0797 68.9032C9.80326 63.631 8.66504 57.9977 8.66504 52.0033C8.66504 46.0088 9.80326 40.3755 12.0797 35.1033C14.3533 29.831 17.44 25.2449 21.34 21.3449C25.24 17.4449 29.8262 14.3567 35.0984 12.0803C40.3706 9.8067 46.0039 8.66992 51.9984 8.66992C57.9928 8.66992 63.6261 9.8067 68.8984 12.0803C74.1706 14.3567 78.7567 17.4449 82.6567 21.3449C86.5567 25.2449 89.6435 29.831 91.917 35.1033C94.1935 40.3755 95.3317 46.0088 95.3317 52.0033C95.3317 57.9977 94.1935 63.631 91.917 68.9032C89.6435 74.1755 86.5567 78.7616 82.6567 82.6616C78.7567 86.5616 74.1706 89.6484 68.8984 91.9219C63.6261 94.1984 57.9928 95.3366 51.9984 95.3366Z" fill="#00B79D"/>
                    </svg>
                    </span>
                    <p>Thank You!</p>
                    <p>
                        Your application has been sent.
                    </p>
                </div>
            </div>
           
    </div>
</template>

<script>
export default{
    data() {
    return {
      fileName: '',
      togglePopupShow: false,
      
    };
  },
  methods: {
    openFileInput() {
      this.$refs.fileInput.click();
    },
    updateFileName(event) {
      const file = event.target.files[0];
      if (file) {
        this.fileName = file.name;
      } else {
        this.fileName = '';
      }
    },
    submitForm() {
      // Handle form submission logic here
      this.togglePopupShow = true;
     
    },

  }
}
</script>