<template>
    <div>
        <section  class="container">
            <div class="related-projects">
                <header>
                    <h4>Related Projects</h4>
                </header>
                <div class="row">
                    <section class="col-12 col-lg-4 mt-4 mt-lg-0">
                        <div class="card">
                            <img src="../../assets/images/related-project-1.png" class="card-img-top" alt="related project"  width="402.65" height="182">
                            <div class="card-body">
                                <h5 class="card-title">Project Name</h5>
                                <p class="card-text">
                                    Client turned to us at Elogic, their website looked as if it could use
                                </p>
                                <a href="#">View Case Study 
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M13.6281 17.2748C13.4281 17.0748 13.3321 16.8331 13.3401 16.5498C13.3488 16.2665 13.4531 16.0248 13.6531 15.8248L16.4781 12.9998H5.32812C5.04479 12.9998 4.80713 12.9038 4.61513 12.7118C4.42379 12.5205 4.32812 12.2831 4.32812 11.9998C4.32812 11.7165 4.42379 11.4788 4.61513 11.2868C4.80713 11.0955 5.04479 10.9998 5.32812 10.9998H16.4781L13.6281 8.1498C13.4281 7.9498 13.3281 7.71214 13.3281 7.4368C13.3281 7.16214 13.4281 6.9248 13.6281 6.7248C13.8281 6.5248 14.0658 6.4248 14.3411 6.4248C14.6158 6.4248 14.8531 6.5248 15.0531 6.7248L19.6281 11.2998C19.7281 11.3998 19.7991 11.5081 19.8411 11.6248C19.8825 11.7415 19.9031 11.8665 19.9031 11.9998C19.9031 12.1331 19.8825 12.2581 19.8411 12.3748C19.7991 12.4915 19.7281 12.5998 19.6281 12.6998L15.0281 17.2998C14.8448 17.4831 14.6158 17.5748 14.3411 17.5748C14.0658 17.5748 13.8281 17.4748 13.6281 17.2748Z" fill="#2A6A84"/>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </section>

                    <section class="col-12 col-lg-4 mt-4 mt-lg-0">
                        <div class="card">
                            <img src="../../assets/images/related-project-2.png" class="card-img-top" alt="related project"  width="402.65" height="182">
                            <div class="card-body">
                                <h5 class="card-title">Project Name</h5>
                                <p class="card-text">
                                    Client turned to us at Elogic, their website looked as if it could use
                                </p>
                                <a href="#">View Case Study 
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M13.6281 17.2748C13.4281 17.0748 13.3321 16.8331 13.3401 16.5498C13.3488 16.2665 13.4531 16.0248 13.6531 15.8248L16.4781 12.9998H5.32812C5.04479 12.9998 4.80713 12.9038 4.61513 12.7118C4.42379 12.5205 4.32812 12.2831 4.32812 11.9998C4.32812 11.7165 4.42379 11.4788 4.61513 11.2868C4.80713 11.0955 5.04479 10.9998 5.32812 10.9998H16.4781L13.6281 8.1498C13.4281 7.9498 13.3281 7.71214 13.3281 7.4368C13.3281 7.16214 13.4281 6.9248 13.6281 6.7248C13.8281 6.5248 14.0658 6.4248 14.3411 6.4248C14.6158 6.4248 14.8531 6.5248 15.0531 6.7248L19.6281 11.2998C19.7281 11.3998 19.7991 11.5081 19.8411 11.6248C19.8825 11.7415 19.9031 11.8665 19.9031 11.9998C19.9031 12.1331 19.8825 12.2581 19.8411 12.3748C19.7991 12.4915 19.7281 12.5998 19.6281 12.6998L15.0281 17.2998C14.8448 17.4831 14.6158 17.5748 14.3411 17.5748C14.0658 17.5748 13.8281 17.4748 13.6281 17.2748Z" fill="#2A6A84"/>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </section>

                    <section class="col-12 col-lg-4 mt-4 mt-lg-0">
                        <div class="card">
                            <img src="../../assets/images/related-project-3.png" class="card-img-top" alt="related project" width="402.65" height="182">
                            <div class="card-body">
                                <h5 class="card-title">Project Name</h5>
                                <p class="card-text">
                                    Client turned to us at Elogic, their website looked as if it could use
                                </p>
                                <a href="#">View Case Study 
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                        <path d="M13.6281 17.2748C13.4281 17.0748 13.3321 16.8331 13.3401 16.5498C13.3488 16.2665 13.4531 16.0248 13.6531 15.8248L16.4781 12.9998H5.32812C5.04479 12.9998 4.80713 12.9038 4.61513 12.7118C4.42379 12.5205 4.32812 12.2831 4.32812 11.9998C4.32812 11.7165 4.42379 11.4788 4.61513 11.2868C4.80713 11.0955 5.04479 10.9998 5.32812 10.9998H16.4781L13.6281 8.1498C13.4281 7.9498 13.3281 7.71214 13.3281 7.4368C13.3281 7.16214 13.4281 6.9248 13.6281 6.7248C13.8281 6.5248 14.0658 6.4248 14.3411 6.4248C14.6158 6.4248 14.8531 6.5248 15.0531 6.7248L19.6281 11.2998C19.7281 11.3998 19.7991 11.5081 19.8411 11.6248C19.8825 11.7415 19.9031 11.8665 19.9031 11.9998C19.9031 12.1331 19.8825 12.2581 19.8411 12.3748C19.7991 12.4915 19.7281 12.5998 19.6281 12.6998L15.0281 17.2998C14.8448 17.4831 14.6158 17.5748 14.3411 17.5748C14.0658 17.5748 13.8281 17.4748 13.6281 17.2748Z" fill="#2A6A84"/>
                                        </svg>
                                    </span>
                                </a>
                            </div>
                        </div>
                    </section>

                </div>
            </div>
        </section>
    </div>
</template>