<template>
    <div class="services-faq-section pt-35 pb-70 mt-5">
        <div class="container">
            <div class="row align-items-center">
                <section class="col-lg-6">
                    <header class="services-section-title text-center my-2 my-lg-0 text-lg-start pe-lg-5">
                        <h2>Frequently Asked Questions</h2>
                        <p>
                            In this section, we aim to address common queries and concerns regarding our brand identity design services. Whether you're new to brand identity design or seeking clarification on specific aspects, we're here to provide you with clear and comprehensive answers. 
                        </p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp"  alt="faq image" width="610" height="343">
                    </div>
                </section>

                <div class="col-lg-6 ps-lg-4">
                    <section class="services-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is Brand Identity Design?

                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Brand identity design involves developing visual elements that represent the personality, values, vision, and message of the brand. This includes the logo, color palette, fonts, icons, and other visual patterns.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        How long does it take to design our brand identity?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        The duration depends on several factors, including the project's size and complexity. Designing a brand identity can take several weeks or even months in more complex cases.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Can the brand identity be modified after designing?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Yes, your brand identity can be modified after designing.

                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is the difference between a logo and a brand identity?

                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        The logo is just one element of brand identity. Brand identity includes other elements such as colors, fonts, visual patterns, and the appropriate use of visual elements across different communication channels.
                                    </p>
                                </template>
                            </accordion-item>
                            <!----<accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Venenatis duis proin ultricies consectetur?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                                </template>
                            </accordion-item>-->
                        </accordion>
                    </section>
                </div>

              
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>