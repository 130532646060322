<template>
   
    <header class="dev-services-heading services-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-xl-9 ">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content ps-lg-5 ps-xl-0">
                        <h1>Brand Identity Design</h1>
                        <p>
                            Whether you're launching a new business or looking to redesign your current brand identity, we create custom brand identities that set you apart from your competitors and leave a lasting impression on your customers.
                        </p>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </header>
 
</template>
<script>
    export default{
        name:'UiHeader'
    }
</script>