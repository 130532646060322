<template>
    <div class="">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="overview-tab-section">
                        <div class="overview-details-desc">
                            <h3>job Description</h3>
                            <p>
                                Work with development teams and product managers to ideate software solutions Design client-side and server-side architecture Write technical documentation Build the front-end of applications through appealing visual design Develop and manage well-functioning databases and applications Work with data scientists and analysts to improve software Design and implement database Design test plans, and write test cases Test software to ensure responsiveness and efficiency Troubleshoot, debug, and upgrade software Design and construction of our REST API
                            </p>
                            
                            <h3> Skills </h3>
                            <ul class="skills-list">
                                <li>
                                    Css3
                                </li>
                                <li>
                                    Html5
                                </li>
                                <li>
                                    Photoshop
                                </li>
                                <li>
                                    Wordpress
                                </li>
                                <li>
                                    PHP
                                </li>
                                <li>
                                    java Script
                                </li>
                            </ul>
                            <div>
                                <h3>Responsibilities</h3>
                                <p>
                                    We are looking for Full Stack Developers to produce scalable web applications solutions. you’ll be part of a cross-functional team that’s responsible for the full software development life cycle, from conception to deployment.
                                </p>
                            </div>
                           
                        </div>

                        <ul class="requirements-list">
                            <li v-for="item,index of responsabilities" :key="index">
                                {{ item }}
                            </li>
                        </ul>
                       
                        <div class="text-center mb-4">
                            <button @click="redirectToApplication" class="apply-btn">Apply Now</button>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-12">
                  <app-sidebar></app-sidebar>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AppSidebar from './AppSidebar'

    export default {
       components: { 
            AppSidebar
        },
        data(){
            return{
                responsabilities:[
                    'Bachelor in Computer engineering, or computer science is preferred.',
                    '0 – 5 years overall experience in Web Development.',
                    'As a Full Stack Developer, you should be comfortable around both front-end and back-end coding languages, development frameworks, and third-party libraries:',
                    'Familiarity with databases (MySQL or PostgreSQL or MS SQL)',
                    'Familiarity with web servers (Apache)',
                    'Experience in the front-end languages and libraries (HTML/ CSS, JavaScript, XML, jQuery)',
                    'Working with hardware (e.g., communication devices, cameras, …)',
                    'Back-end programming language (e.g., #, PHP, Python)',
                    'Familiarity with databases (e.g., MongoDB,), web servers (e.g., Nginx)',
                    'Experience in the front-end languages and libraries (e.g., react, angular)',
                    

                ],
                
            }
        }
        ,
        methods:{
            redirectToApplication() {
            this.$emit('goToApplication');
            }
        }
    }
</script>