<template>
    <div>
        <section class="container job-resume pt-35 pb-35">
            <section class="section-title">
                <h3>How It Works?</h3>
                <p>job for anyone, anywhere</p>
            </section>
            <div class="row mt-lg-5">
                <section class="col-md-4 text-center mt-3 mt-lg-0">
                    <div >
                        <div>
                            <img src="../../assets/images/Images/resume.webp" height="113" width="105" alt="image">
                        </div>
                        <div>
                            <h4>Free Resume Asseessments</h4>
                            <p>
                                Employers on average spend 31 seconds scanning 
                                resumes to identify potential matches. 
                            </p>
                        </div>
                    </div>
                </section>
                <section class="col-md-4 text-center mt-3 mt-lg-0">
                    <div >
                        <div>
                            <img src="../../assets/images/Images/resume-1.webp" height="113" width="105" alt="image">
                        </div>
                        <div>
                            <h4>Free Resume Asseessments</h4>
                            <p>
                                Our new fit meter shows you which jobs are most
                                relevant to your skills and interests.
                            </p>
                        </div>
                    </div>
                    
                </section>
                <section class="col-md-4 text-center mt-3 mt-lg-0">
                    <div >
                    <div>
                        <img src="../../assets/images/Images/resume-2.webp" height="113" width="106" alt="image">
                    </div>
                    <div>
                        <h4>Help every step of the way</h4>
                        <p>
                            Our career advice section is full of information to
htify thelp you identify the right fit.
                        </p>
                    </div>
                    </div>
                </section>
            </div>
        </section>
    </div>
</template>