<template>
    <div class="overview-section pt-35 pb-35">
        <div class="container">
            <div class="section-title">
                <span>Our Process</span>
                <h2>Our Working  Process</h2>
                <div class="bar"></div>
            </div>

            <section class="row align-content-center justify-content-between mt-3">
                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content text-center">
                        <div class="icon">
                            <img src="../../assets/images/find-new-ideas.svg" alt="find new Ideas">
                        </div>

                        <h3>Discussion</h3>
                        <p>
                            You will meet with our team to understand your requirements and website goals.
                        </p>
                        <div class="first-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                    
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/digital-analysis.svg" alt="find new Ideas">
                        </div>

                        <h3>Planning</h3>
                        <p>
                            We will develop a detailed project plan based on your website's goals, structure, chosen technology, and execution stages.
                        </p>
                        <div class="second-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/market-research.svg" alt="find new Ideas">
                        </div>

                        <h3>UI Interface Design</h3>
                        <p>
                            We will create an attractive user interface design that provides an easy and effective user experience.
                        </p>
                        <div class="third-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-creation.svg" alt="find new Ideas">
                        </div>

                        <h3>Website Programming</h3>
                        <p>
                            We will develop the frontend and backend of your website.
                        </p>
                        <div class="forth-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-launching.svg" alt="find new Ideas">
                        </div>

                        <h3>Website Testing and launching</h3>
                        <p>
                            We will perform extensive testing to ensure the website works correctly on all devices and browsers. Then we’ll launch the website to servers.
                        </p>
                    </div>
                </div>

                <div class="text-center mt-3 mt-md-1">
                    <router-link to="/contact" class="default-btn py-1 rounded-2 fs-5">
                        Discuss your web idea with us
                    </router-link>
                </div>
            </section>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DevelopmentProcess'
    }
</script>