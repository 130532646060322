<template>
    <div class="services-faq-section pt-35 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <section class="col-lg-6">
                    <header class="services-section-title text-center my-2 my-lg-0 text-lg-start pe-lg-5">
                        <h2>Frequently Asked Questions</h2>
                        <p>
                            We hope this section has provided you with valuable insights into the mobile app design process. If you have any further questions or would like to discuss your mobile app project in more detail, please don't hesitate to contact us. 
                        </p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp" alt="faq image" width="610" height="343">
                    </div>
                </section>

                <div class="col-lg-6 ps-lg-4">
                    <section class="services-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        How long does it take to design my mobile apps?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        We cannot specify a duration because it depends on factors such as app type, programming language, number of languages, requirements, and modifications. Contact us for a customized execution time frame.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Can I update and modify my app after its launch?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Yes, you can update and add additional features.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is mobile app design?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                   <p>
                                    Mobile app design refers to the process of creating the user interface (UI) and user experience (UX) design for a mobile application. This includes defining the app's visual style, layout, navigation, and interaction elements to ensure a seamless and intuitive user experience.
                                   </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is the cost of mobile app design?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        The cost of mobile app design can vary widely depending on factors such as the scope of the project, the level of customization, the complexity of the app, and the expertise of the design team. 
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What factors should I consider when designing a mobile app?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        When designing a mobile app, it's essential to consider factors such as your target audience, platform compatibility (iOS, Android, or both), app functionality, branding elements, and user preferences. 
                                    </p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </section>
                </div>

              
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>