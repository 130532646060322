<template>
   
    <header class="dev-services-heading services-bg-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 ">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content">
                        <h1>Maximize your profits with our digital marketing services</h1>
                        <p>
                            Our experts understand your brand and create custom digital marketing solutions. From social media campaigns to SEO-optimized content, we make sure your brand shines brightly in the crowded digital landscape.
                        </p>
                    </div>

                </div>
                
            </div>
        </div>
    </header>
 
</template>
<script>
    export default{
        name:'UiHeader'
    }
</script>