<template>
    <div>
        <navbar-style-two></navbar-style-two>
        <PageTitle />
        <portofolio-tabs></portofolio-tabs>
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import PageTitle from '../Portofolio/PageTitle.vue'
    import PortofolioTabs from '../Portofolio/ProtofolioTabs.vue'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'PortofolioPage',
        components: {
            'navbar-style-two':NavbarStyleTwo,
            PageTitle,
            'portofolio-tabs':PortofolioTabs,
            Footer,
        },
        setup(){
            useHead({
                title: 'Misk - Portofolio',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>