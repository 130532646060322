<template>
  <div class=" pt-35 pb-35">
    <div class="container ps-lg-5 dev-technology">
      <!---Start Of Top Section-->
      <div class="section-title">
        <h3>Technologies we use</h3>
      </div>
      <!---End Of Top Section-->
      <div class="row">
        <div class="private-servers-tab mb-5 ">
          <section class="about-list-tab">
            <div class="tabs">
              <ul
                class="tabs__header list-unstyled text-center d-flex justify-content-center align-items-center flex-row"
              >
                <li
                  class=" mb-3 mb-lg-2"
                  v-for="tab in tabs"
                  v-on:click="selectTab(tab.id)"
                  v-bind:class="{ active: activeTab == tab.id }"
                  :key="tab.id"
                >
                    <span class="dev_tab_name"> {{ tab.name }}</span>

                </li>
              </ul>
              
            </div>
            <div class="tabs__container">
              <div class="tabs__list" ref="tabsList">
                <div
                  class="tabs__list-tab"
                  v-for="tab in tabs"
                  v-bind:class="{ active: activeTab == tab.id }"
                  :key="tab.id"
                >
                <Section v-if="tab.id === 1" class="mt-5">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../assets/images/mobile-swift.svg" alt="swift Logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/oop.svg" alt="oop">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/kotlin.svg" alt="kotlin">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/react.svg" alt="react logo">
                        </div>
                    </div>
                    
                </Section>
                  <!--------------Start Of Second Section------------------>
                  <Section v-if="tab.id === 2" class="tab-second-section">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../assets/images/angular.svg" alt="angular Logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/jquery.svg" alt="jquery logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/dot-net.svg" alt="dot net logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/js.svg" alt="javascript logo">
                        </div>
                    </div>
                  </Section>
                  <!-------------End Of Third Section----------------------->
                  <Section v-if="tab.id === 3" class="tab-third-section">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../assets/images/php.svg" alt="php logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/ruby.svg" alt="ruby logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/paython.svg" alt="paython logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/java.svg" alt="Java logo">
                        </div>
                    </div>
                  </Section>

                  <!--------------End Of Third Section----------------------->
                  <Section v-if="tab.id === 4" class="tab-forth-section">
                    <div class="row">
                        <div class="col-3">
                            <img src="../../assets/images/oracle.svg" alt="Oracle logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/sql.svg" alt="sql logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/paython.svg" alt="paython logo">
                        </div>
                        <div class="col-3">
                            <img src="../../assets/images/java.svg" alt="java logo">
                        </div>
                    </div>
                </Section>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DevTechnology",
  data() {
    return {
      activeTab: 1,
      offsetRight: 0,
      tabs: [
        {
          id: 1,
          name: "mobile",
          image: {
            imgUrl: require("../../assets/images/mobile-swift.svg"),
            altText: "team image",
          },
        
        },
        {
          id: 2,
          name: "web",
      
          images: [
            {
                imgUrl: require("../../assets/images/mobile-swift.svg"),
                altText: "swift image",
            }
          ]
            
        
        },
        {
          id: 3,
          name: "backend",
     
          image: {
            imgUrl: require("../../assets/images/Servers-Locations.svg"),
            altText: "team image",
          },
        },
        {
          id: 4,
          name: "database",
          image: {
            imgUrl: require("../../assets/images/Servers-Locations.svg"),
            altText: "team image",
          },
        },
      ],
    };
  },
  methods: {
    selectTab(id) {
      this.activeTab = id;
    },
  },
  mounted() {},
};
</script>
