<template>
   
    <header class="dev-services-heading services-bg-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 ">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content">
                        <h1><span>Come and Join</span> <br>our awesome team</h1>
                        <p>
                            If you're looking for a challenge and a rewarding role you've come to the right place.
                        </p>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </header>
 
</template>
<script>
    export default{
        name:'JobsHeader'
    }
</script>
<style scoped>
    span{
        color: #FFF;
font-family: Poppins;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: normal;
    }
</style>