<template>
    <div>
        <div class="container">
            <Section class="digital-overview pt-35 pb-35">
            <div class="row ">
                <div class="col-lg-6 text-center  mt-3 mt-lg-0 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/Images/digital-section-3.webp" alt="overview image" height="537" width="338" class="img-fluid rounded-3"/>
                </div>
                <div class="col-lg-6 ps-lg-5 hover-effect ">
                    <header class="pt-2">

                        <h2 class="span-overview">Expand your business reach and access new markets easily</h2>
                    </header>
                    <p>
                        By targeting specific audience segments with tailored messaging and content, digital marketing allows you to connect with potential customers who may have otherwise been inaccessible through traditional marketing methods. 
                    </p>
                    <p>
                        This expanded reach not only increases brand awareness but also opens up opportunities for your business growth and revenue generation. 
                    </p>
                </div>
                
            </div>
         </Section>
        </div> 
    </div>
</template>