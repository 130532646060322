<template>
    <div>
        <Navbar />
        <PageTitle />
        <Signup />
        <Footer />
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo.vue'
    import PageTitle from '../SignUp/PageTitle'
    import Signup from '../SignUp/Signup'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'
    export default {
        name: 'SignUpPage',
        components: {
            Navbar,
            PageTitle,
            Signup,
            Footer,
          
        },
        setup(){
            useHead({
                title: 'Misk - Sign Up',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>