<template>
    <div class="about-section pt-35 pb-35">
        <div class="container">
            <div class="row">

                <section class="col-lg-5 p-0 text-center  mt-4 mt-lg-0">
                    <div class="d-flex justify-content-start">
                        <img src="../../assets/images/cyber1.jpg" alt="build-for-future" class="img-fluid">
                    </div>
                </section>

                <section class="col-lg-7 d-flex align-items-center">
                    <div class="about-title">
                        <h1>5 reasons to adopt Cybersecurity Services</h1>
                        <div class="bar"></div>
                        <div class="about-list-tab">
                            <div class="tabs">
                                <ul class="list-group list-unstyled">
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                            Safeguard digital data and systems from cyber threats.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                             Preserve sensitive data.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        Ensure compliance with data protection laws, regulations, and cybersecurity measures. 
                                       </div>
                                    </li>

                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                         Ensure that your brand maintains a positive image by offering a secure and dependable environment for your customers.
                                       </div>
                                    </li>

                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        Protect your business from potential security threats and ensure its continuity.
                                       </div>
                                    </li>

                                </ul>
                            </div>
                            <div class="mt-4">
                                <router-link to="/contact" class="default-btn py-2">Start Now</router-link>
                            </div>
                        </div>
                    </div>
                </section>

                
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About',
    data () {
        return {
            activeTab: 1,
            offsetRight: 0,
/*
            tabs: [
                {
                    id: 1,
                    name: 'Our History',
                    title: 'How To Generate Your Creative Idea With IT Business',
                    lists: [
                        {
                            id: 1,
                            icon: 'flaticon-tick',
                            text: 'The Philosophy of Business Analytics',
                        },
                        {
                            id: 2,
                            icon: 'flaticon-tick',
                            text: 'Fast Track Your Business',
                        },
                        {
                            id: 3,
                            icon: 'flaticon-tick',
                            text: 'Lies & Damn Lies About Your Business',
                        },
                        {
                            id: 4,
                            icon: 'flaticon-tick',
                            text: 'The Ultimate Deal on Business',
                        },
                    ],
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                    btnText: 'Discover More',
                    // btnLink: '',
                },
                {
                    id: 2,
                    name: 'Our History',
                    title: 'How To Generate Your Creative Idea With IT Business',
                    lists: [
                        {
                            id: 1,
                            icon: 'flaticon-tick',
                            text: 'The Philosophy of Business Analytics',
                        },
                        {
                            id: 2,
                            icon: 'flaticon-tick',
                            text: 'Fast Track Your Business',
                        },
                        {
                            id: 3,
                            icon: 'flaticon-tick',
                            text: 'Lies & Damn Lies About Your Business',
                        },
                        {
                            id: 4,
                            icon: 'flaticon-tick',
                            text: 'The Ultimate Deal on Business',
                        },
                    ],
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                    btnText: 'Discover More',
                    // btnLink: '',
                },
                {
                    id: 3,
                    name: 'Our History',
                    title: 'How To Generate Your Creative Idea With IT Business',
                    lists: [
                        {
                            id: 1,
                            icon: 'flaticon-tick',
                            text: 'The Philosophy of Business Analytics',
                        },
                        {
                            id: 2,
                            icon: 'flaticon-tick',
                            text: 'Fast Track Your Business',
                        },
                        {
                            id: 3,
                            icon: 'flaticon-tick',
                            text: 'Lies & Damn Lies About Your Business',
                        },
                        {
                            id: 4,
                            icon: 'flaticon-tick',
                            text: 'The Ultimate Deal on Business',
                        },
                    ],
                    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.',
                    btnText: 'Discover More',
                    // btnLink: '',
                },
            ]*/
        }
    },
    methods: {
       /* selectTab (id) {
            let tabsList = this.$refs.tabsList
            this.activeTab = id
            this.offsetRight = tabsList.clientWidth * (id - 1)
            tabsList.style.right = this.offsetRight + 'px'
        }*/
    },
  /*  mounted () {
        let tabsList = this.$refs.tabsList
        tabsList.style.right = this.offsetRight + 'px'
    },*/
}
</script>