<template>
    <div>
        <Navbar />
        <multiSetpForm/>
        <Footer />
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo.vue'
    import multiSetpForm from '../Payment/MultiStepForm';
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head';
    export default {
        name: 'SignUpPage',
        components: {
            Navbar,
            multiSetpForm,
            Footer,
        },
        setup(){
            useHead({
                title: 'Misk - Information Form',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>