<template>
    <div class="services-header">
        <div class="container">
            <header>
                <div class="row d-flex align-items-center">
                    <div class="col-lg-6 text-center text-lg-start">
                        <h1><span>Misk</span> IT SERVICES</h1>
                        <p>
                            Your business needs powerful solutions and guidance to navigate today’s marketplace. 
                            With our high-quality staff and customized solutions, NanoSoft is the best choice to 
                            provide your organization with IT solutions.
                        </p>
                        <button>
                            <router-link to="/contact">Get In Touch</router-link>
                        </button>
                    </div>
                    <div class="col-lg-6 text-center mt-4 mt-lg-0">
                        <img src="../../assets/images/digital-presentation.svg" >
                    </div>
                </div>
            </header>
        </div>
    </div>
</template>
<script>
    export default{
        name: 'ServicesHeader'
    }
</script>