<template>
    <div class="container pt-100 pb-70">
        <h5 class="mb-4">Key technologies</h5>
        <div class="plateforms d-flex flex-wrap gap-3">
            <img src="../../assets/images/magento-logo.svg" alt="magento logo">
            <img src="../../assets/images/pay-logo.svg" alt="pay logo">
            <img src="../../assets/images/Odoo.svg" alt="Odoo logo">
            <img src="../../assets/images/googlepay.svg" alt="googlepay logo">
        </div>
    </div>
</template>