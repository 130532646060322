<template>
    <div>
        <section class="container dev-custom">
            <header class="section-title">
                <h2>
                    What can you get with our Web <br><span> Development </span> services?
                </h2>
                
            </header>
            <section>
                <div class="row">
                    <div class="col-md-6 col-lg-4 mt-3 ">
                        <div class="dev-custom-content ">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/enterprise-solution.svg" alt="Enterprise Solution Icon">
                                </div>
                                
                                <div class="col-10">
                                    <h3>Enterprise Solution Architecture Design</h3>
                                    <p>
                                        Mangosoft has professional expertise in developing
                                        enterprise architecture. If you seek to reach your
                                        business goals and organizational planning, you
                                        can count on us and our assistance in this process.
                                    </p>
                                </div>
                                
                            </div>
                           
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4  mt-3 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                        <img src="../../assets/images/carbon_analytics.svg" alt="analytics icon">
                                    </div>
                                    <div class="col-10">
                                        <h3>Data Analytics Software Development</h3>
                                        <p>
                                            We find solutions for collecting, storing, and analyzing data at an enterprise level. Our data analytics tools improve the decision-making
                                            process and visual data representation.
                                        </p>
                                    </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-lg-4  mt-3 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                    <img src="../../assets/images/mobile-app.svg" alt="mobile-app">
                                </div>
                                <div class="col-10">
                                    <h3>Business App Development</h3>
                                    <p>
                                        Mangosoft has a talented team of developers who can design mobile, web or cross-platform apps that might enhance user experience, increase the number of downloads
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <!-----------Start Of Second Row------------------->
                    <div class="col-md-6 col-lg-4 mt-5 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                <img src="../../assets/images/open-source.svg" alt="open source development icon">
                                </div>
                                <div class="col-10">
                                    <h3>Open-Source Development & Migration</h3>
                                    <p>
                                        Mangosoft has highly professional developers who are experienced in open source development and platform migration. Once you’ve decided to implement these services in your business.
                                    </p>
                                </div>
                           
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mt-5 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                                <img src="../../assets/images/web-development-svgrepo.svg" alt="web development icon">
                                </div>
                                <div class="col-10">
                                    <h3>Marketplace Development</h3>
                                    <p>
                                        We build engaging, valuable, and highly optimized marketplace platforms that bring
                                        more traffic to your website. Mangosoft knows how to create scalable and top-notch products that increase more profits.
                                    </p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 mt-5 ">
                        <div class="dev-custom-content">
                            <div class="row d-flex justify-content-between align-items-start">
                                <div class="col-2 align-self-baseline">
                               <img src="../../assets/images/dev-icon-1.svg" alt="development icon">
                            </div>
                            <div class="col-10">
                                <h3>Business App Development</h3>
                                <p>
                                    Mangosoft has a talented team of developers who can design mobile, web or cross-platform apps that might enhance user experience, increase the number of downloads
                                </p>
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-------------End Of Second Row------------------->
                </div>
            </section>
        </section>
    </div>
</template>
<script>
    export default{
        name:'DevCustom'
    }
</script>