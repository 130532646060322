<template>
  <div class="whats-bottom-icon" :class="{ 'whats-preload': animateItem }">
    <i class="fa-brands fa-whatsapp"></i>
  </div>
</template>
<script>
export default {
  data() {
    return {
      animateItem: true,
    };
  },
  mounted() {
    setTimeout(() => {
      this.animateItem = false;
    }, 2000);
  },
};
</script>
