<template>
   <div>
    <div class="container ">
        <section class="summary">
            <div>
                <h5>Payement Summary</h5>
            </div>
            <ul class="list-group">
                <li>
                    <div>
                        Items:
                    </div>
                    <div>
                        $169.99
                    </div>
                </li>
                <li>
                    <div>
                        Shipping & handling:
                    </div>
                    <div>
                        $169.99
                    </div>
                </li>
                <li>
                    <div>
                        Total before tax:
                    </div>
                    <div>
                        $169.99
                    </div>
                </li>
                <li>
                    <div>
                        Items:
                    </div>
                    <div>
                        $169.99
                    </div>
                </li>
                <li>
                    <div>
                        Estimated tax to be collected:
                    </div>
                    <div>
                        $0.00
                    </div>
                </li>
                <li class="last">
                    <div>
                        Total
                    </div>
                    <div>
                        $606.24
                    </div>
                </li>
            </ul>
            <div class="track">
                <p>
                    You can track your shipment and view any applicable import fees deposit before placing your order.
                    <a href="#">Learn More</a>
                </p>
            </div>

            
        </section>
        <div class="text-center btn-content">
                <button  class="payment-method">Save and Continue</button>
        </div>
    </div>
   </div>
</template>