<template>
    <div class="services-faq-section pt-35 pb-70 mt-5">
        <div class="container">
            <div class="row align-items-start d-flex">
                <section class="col-lg-6">
                    <header class="services-section-title text-center my-2 my-lg-0 text-lg-start pe-lg-5">
                        <h2>Frequently Asked Questions</h2>
                        <p>
                            Whether you are new to digital marketing or seeking clarification on specific aspects, our FAQ section provides clear and concise answers to help you better understand our services.
                        </p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp"  alt="faq image" width="610" height="343">
                    </div>
                </section>

                <div class="col-lg-6 ps-lg-4 d-flex flex-column justify-content-between ">
                    <section class="services-faq-accordion  mb-5">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is the meaning of web hosting?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Web hosting is a service that enables individuals or organizations to make their websites accessible on the internet. 
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Is regular backup done for your site?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Yes, we regularly create backups to ensure the protection of your data and avoid the loss of any important information.
                                    </p>
                                    
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Can you upgrade hosting plans later if your site's needs increase?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Yes, we provide options to upgrade your hosting plans when you need to expand your site's scope.
                                    </p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </section>
                    <div class="mt-auto text-center">
                            <router-link to="/contact" class="default-btn py-1">Ask More</router-link>
                    </div>
                </div>              
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'HostingFaq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>