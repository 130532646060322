<template>
    <div class="container" >
        <header class="role-header">
            <div class="row">
                <div class="col-lg-8">
                    <section class="d-flex justify-content-start align-items-center flex-column flex-lg-row">
                        <div>
                            <img src="../../assets/images/Images/role-overview.webp" alt="role overview" width="222.16" height="228">
                        </div>
                        <div class="role-header-heading">
                            <h1>Full Stack Developer</h1>
                            <div class="timer-container d-flex justify-content-start align-items-start flex-column flex-lg-row">
                                <p><i class="fa-solid fa-clock"></i> 3 Days Left</p>
                                <p class="justify-self-start justify-lg-self-end ms-lg-auto"><i class="fa-solid fa-location-dot"></i> El Quesier, Red sea </p>
                            </div>
                        </div>
                    </section>
                </div>
                <div class="col-lg-4">
                    <div class="d-flex justify-content-center align-items-center flex-column role-overview-second">
                        <div class="align-self-center ">
                            <button >
                                <router-link :to="{ name: 'application', params: { tabName: 'button2' } }">apply now</router-link>
                            </button>
                        </div>
                        <div>
                            <ul class="d-flex justify-content-between align-items-center p-0 m-0">
                                <li>
                                    <i class="fa-solid fa-share-nodes"></i>
                                    <span> Share</span>
                                </li>
                                <li>
                                    <a href="#" target="_blank"><i class="fa-brands fa-square-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"><i class="fa-brands fa-linkedin"></i></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"><i class="fa-brands fa-square-x-twitter"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </div>
</template>
<script>
export default {
  data() {
    return {
        isNewchangeTab: localStorage.getItem('TabChanged'),
    };
  },
  methods: {
    changeTab() {
        this.isNewchangeTab = true;
        localStorage.setItem('TabChanged', this.isNewchangeTab.toString());
      
    },
  },
};

</script>