<template>
    <div >
        <navbar-style-two></navbar-style-two>
        <Banner />
        <!----<FunFacts />-->
        <Development />
        <new-services></new-services>
        <ServicesBtn></ServicesBtn>
        <Features />
        <!----<MiskNumbers/><Services />
        <Achievements></Achievements>-->
        <Pricing />
        <Audience />
        <Design />
        <OurClients />
        <overview />
        <ClientsOverview/>
        <Footer />
  
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
import Banner from '../HomeOne/Banner'
/*import FunFacts from '../HomeOne/FunFacts'*/
import Features from '../HomeOne/Features'
/*import MiskNumbers from '../HomeOne/MiskNumbers'
import Services from '../HomeOne/Services'
import Achievements from '../HomeOne/Achievements'*/
import Development from '../HomeOne/Development'
import ServicesBtn from '../HomeOne/ServicesBtn';
import Pricing from '../HomeOne/Pricing'
import Audience from '../HomeOne/Audience'
import Design from '../HomeOne/Design'
import OurClients from '../HomeOne/OurClients'
import Overview from '../HomeOne/Overview'
import ClientsOverview from '../HomeOne/ClientsOverview'
import Footer from '../Layout/Footer'


import NewServices from '../HomeOne/NewServices'

export default {
    name: 'HomePageOne',
    components: {
        'navbar-style-two':NavbarStyleTwo,
        Banner,
        ServicesBtn,
        Features,
        Audience,
        Overview,
        Development,
        Design,
        Pricing,
        OurClients,
        ClientsOverview,
        Footer,
        NewServices
  
        /*MiskNumbers,
        Achievements,*/
    },
    head (){
          return{
            title: 'Misk - It solutions and Technology',
            meta: [
                { name: 'description', content: 'This is Misk Home page.' }
            ]
          }  
        }
}
</script>
