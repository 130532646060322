<template>
    <div>
        <div class="container approved">
            <section class="approved-container">
                <section class="approved-list">
                    <header class="text-center">
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="108" height="108" viewBox="0 0 108 108" fill="none">
                            <path d="M47.7 62.1L37.9125 52.3125C37.0875 51.4875 36.075 51.075 34.875 51.075C33.675 51.075 32.625 51.525 31.725 52.425C30.9 53.25 30.4875 54.3 30.4875 55.575C30.4875 56.85 30.9 57.9 31.725 58.725L44.55 71.55C45.375 72.375 46.425 72.7875 47.7 72.7875C48.975 72.7875 50.025 72.375 50.85 71.55L76.3875 46.0125C77.2125 45.1875 77.625 44.175 77.625 42.975C77.625 41.775 77.175 40.725 76.275 39.825C75.45 39 74.4 38.5875 73.125 38.5875C71.85 38.5875 70.8 39 69.975 39.825L47.7 62.1ZM54 99C47.775 99 41.925 97.818 36.45 95.454C30.975 93.093 26.2125 89.8875 22.1625 85.8375C18.1125 81.7875 14.907 77.025 12.546 71.55C10.182 66.075 9 60.225 9 54C9 47.775 10.182 41.925 12.546 36.45C14.907 30.975 18.1125 26.2125 22.1625 22.1625C26.2125 18.1125 30.975 14.9055 36.45 12.5415C41.925 10.1805 47.775 9 54 9C60.225 9 66.075 10.1805 71.55 12.5415C77.025 14.9055 81.7875 18.1125 85.8375 22.1625C89.8875 26.2125 93.093 30.975 95.454 36.45C97.818 41.925 99 47.775 99 54C99 60.225 97.818 66.075 95.454 71.55C93.093 77.025 89.8875 81.7875 85.8375 85.8375C81.7875 89.8875 77.025 93.093 71.55 95.454C66.075 97.818 60.225 99 54 99Z" fill="#2FCC66"/>
                            </svg>
                        </span>
                        <h5>Successful Payment</h5>
                        <hr />
                    </header>
                    <ul class="list-group">
                    <li>
                        <div>
                            Amount
                        </div>
                        <div>
                            $169.99
                        </div>
                    </li>
                    <li>
                        <div>
                            Payment Method
                        </div>
                        <div>
                            Credit Card
                        </div>
                    </li>
                    <li>
                        <div>
                            Name
                        </div>
                        <div>
                            Ahmed Atif
                        </div>
                    </li>
                    <li>
                        <div>
                            Date
                        </div>
                        <div>
                            8/7/2022   10:00:00 AM
                        </div>
                    </li>
                </ul>
                </section>
                <div class="d-flex justify-content-end mt-5">
              
                        <router-link to="/" class="text-capitalize">Back to home</router-link>
                    
                </div>
            </section>
           
        </div>
    </div>
</template>