<template>
    <div class="services-faq-section pt-35 pb-70 mt-5">
        <div class="container">
            <div class="row align-items-center">
                <section class="col-lg-6">
                    <header class="services-section-title text-center my-2 my-lg-0 text-lg-start pe-lg-5">
                        <h2>Frequently Asked Questions</h2>
                        <p>
                            Whether you are new to digital marketing or seeking clarification on specific aspects, our FAQ section provides clear and concise answers to help you better understand our services.
                        </p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp"  alt="faq image" width="610" height="343">
                    </div>
                </section>

                <div class="col-lg-6 ps-lg-4">
                    <section class="services-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What does digital marketing mean?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Using digital technology and related tools to market products and services.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is the difference between digital marketing and traditional marketing?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        <span class="fw-bold">Digital Marketing:</span> 
                                        Uses electronic media to deliver the marketing message through (social media, search engines, email, mobile phones, etc.).
                                    </p>
                                    <p>
                                        <span class="fw-bold">Traditional Marketing: </span>Uses traditional methods to reach the audience such as print advertising, phone calls, TV or radio advertising.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Is digital marketing suitable for all businesses?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Yes, digital marketing strategies can be adapted to suit all businesses, whether small or large.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Can the results of e-marketing be measured?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Yes, digital marketing offers many tools that you can use to measure the performance of your online marketing campaigns.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Can digital marketing services be customized according to your business needs?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        Yes, we can design digital marketing campaigns to suit the needs and goals of each client.
                                    </p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </section>
                </div>

              
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>