<template>
    <div class="software-section ptb-100 overflow-hidden">
        <div class="container">
            <div class="row align-items-start">
                <section class="col-lg-6">
                    <div class="software-content">
                        <h2 class="mb-3">The best Partner to manage and develop your company</h2>
                        <div class="bar"></div>
                        
                        <p class="mt-5">
                            Welcome to our services page, where innovation meets excellence! 
                            As a leading company in the field of information technology, we pride ourselves on delivering cutting-edge solutions tailored to meet your every need. 
                        </p>
                    </div>
                </section>

                <section class="col-lg-5 col-lg-offset-1">
                    <div class="row">
                        <div class="col-12">
                            <div class="software-inner-content mb-30 flex-column flex-md-row text-center text-md-start hover-effect-1">
                                <div class="icon icon-1 me-3">
                                   
                                </div>
                                <div>
                                    <h3>Fast Delivery</h3>
                                    <p>
                                        We understand the importance of timely results, which is why we prioritize swift delivery of our services. 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="software-inner-content mb-30 flex-column flex-md-row text-center text-md-start second hover-effect-1">
                                <div class="icon icon-2 me-3">

                                </div>
                                <div>
                                    <h3>Tailored Solutions</h3>
                                    <p>
                                        We understand that every business is unique, which is why we offer customized solutions to suit your specific needs. 
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="software-inner-content flex-column flex-md-row text-center text-md-start hover-effect-1">
                                <div class="icon icon-3 me-3">
                                </div>
                                <div>
                                    <h3>Exceptional Customer Support</h3>
                                    <p>We prioritize clear communication, prompt responses, and continuous support throughout every stage of your project. </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BestSoftware'
    }
</script>