<template>
    <div>
        <navbar-style-two></navbar-style-two> 
        <JobsHeader></JobsHeader>
        <OurCompany></OurCompany>
        <HowItworks></HowItworks>
        <Vaccancies></Vaccancies>
        <Benefits></Benefits>
        <Footer />
     
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import JobsHeader from '../Jobs/JobsHeader'
    import HowItworks from '../Jobs/HowItworks'
    import OurCompany from '../Jobs/OurCompany'
    import Vaccancies from '../Jobs/Vaccancies'
    import Benefits from '../Jobs/Benefits'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default{
        components:{
            NavbarStyleTwo,
            JobsHeader,
            HowItworks,
            OurCompany,
            Vaccancies,
            Benefits,
            Footer,
        },
        setup(){
            useHead({
                title: 'Misk - Jobs',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>