<template>
    <div>
        <Section class="container ui-overview pt-35 pb-35">
            <div class="row ">
                <div class="col-lg-6 order-2 order-lg-1 mt-4 mt-lg-1 ">
                    <header>
                        <span class="span-overview"></span>
                        <h2>Why is SEO important? </h2>
                    </header>
                    <!----<p>
                        An impressive UI goes beyond capturing the user’s attention, creating outstanding UX, and building the business brand. Hence, every business should focus on creating immersive UI designs that drive improved UX. At OpenXcell, our offshore UI/UX development team comprehends user-centered designs’ value and understands their impact on your user experience. We ensure that users’ perspectives form the core of the development process and deliver enhanced usability in each of the softwares we create.
                    </p>-->
                    <div class="about-title">
                            <div class="tabs">
                                <ul class="list-group list-unstyled">
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                            It is a cost-effective way to promote your website compared to Google ads.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                        <div>
                                            The results of SEO efforts are long-lasting.
                                        </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        SEO helps to increase website traffic organically.
                                       </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        It also builds credibility and trust with your target audience.
                                       </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        By targeting your audience more effectively, SEO can improve your website's performance.
                                       </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        SEO can help you to perform better than your competitors.
                                       </div>
                                    </li>
                                    <li class="hover-effect-2 d-flex justify-content-start">
                                      <span class="d-block">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                            <path d="M20.8261 2.98926L24 6.16322L9.15311 21.0101L0 11.8572L3.17386 8.68323L9.15311 14.6623L20.8261 2.98926Z" fill="#00B79D"/>
                                        </svg>
                                      </span>
                                       <div> 
                                        It enhances online visibility for your business.
                                       </div>
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>
                </div>
                <div class="col-lg-6 text-center order-1 order-lg-2  mt-3 mt-lg-0 d-flex justify-content-center align-items-center">
                    <img src="../../assets/images/ui-overview.jpg" alt="overview image" height="475" width="382" class="rounded-3"/>
                </div>
            </div>
            
        </Section>
    </div>
</template>