<template>
    <div class="pricing-section  pb-35 pt-35 hosting-plan-section">
        <div class="container">
            <div class="section-title">
                <div class="top-head"><span>Hosting Plan</span></div>
                <h2 class="py-3">Choose your suitable hosting plan</h2>
                <!----<p>
                    Want fast hosting with unlimited features and affordable prices.
                </p>-->
                <div class="bar"></div>
            </div>

            <div class="row">
                <section class="col-lg-3 col-md-6">
                    <div class="single-pricing">
                        <div class="pricing-header">
                            <span>1840$<span class="year">/ Year </span></span>
                            <div class="svg-container">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                        <path d="M47.9997 29.332C51.973 29.332 55.653 30.5854 58.6663 32.692V21.332C58.6663 18.372 56.293 15.9987 53.333 15.9987H42.6663V10.6654C42.6663 7.70536 40.293 5.33203 37.333 5.33203H26.6663C23.7063 5.33203 21.333 7.70536 21.333 10.6654V15.9987H10.6663C7.70634 15.9987 5.35967 18.372 5.35967 21.332L5.33301 50.6654C5.33301 53.6254 7.70634 55.9987 10.6663 55.9987H31.1463C29.7934 53.1547 29.1825 50.0145 29.3707 46.8708C29.5589 43.727 30.54 40.6821 32.2225 38.0199C33.9051 35.3576 36.2343 33.1647 38.9931 31.6456C41.7518 30.1264 44.8503 29.3305 47.9997 29.332ZM26.6663 10.6654H37.333V15.9987H26.6663V10.6654Z" fill="#00B79D"/>
                                        <path d="M48.0003 34.668C40.6403 34.668 34.667 40.6413 34.667 48.0013C34.667 55.3613 40.6403 61.3346 48.0003 61.3346C55.3603 61.3346 61.3337 55.3613 61.3337 48.0013C61.3337 40.6413 55.3603 34.668 48.0003 34.668ZM52.4003 54.268L46.667 48.5346V40.0013H49.3337V47.4413L54.267 52.3746L52.4003 54.268Z" fill="#00B79D"/>
                                    </svg>
                                </span>
                            </div>
                            <h3>
                                Works
                            </h3>
                        </div>

                        <ul class="pricing-list px-2">
                            <li class="d-flex justify-content-between">
                                <span>
                                    Random Ram
                                </span> 
                                <span>
                                    2048mb
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Hard Disk
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Traffic
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Calls Number /moment
                                </span> 
                                <span>
                                    500
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Mails Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Databases Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssl certificate
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssh  Access
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Technical Support/24
                                </span> 
                                <span class="free">
                                    Free
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                        </ul>

                        <div class="price-btn">
                            <router-link to='/subscribe' class="default-btn">
                                Order Now
                            </router-link>
                        </div>
                    </div>
                </section>
                <section class="col-lg-3 col-md-6 mt-3">
                    <div class="single-pricing  top-gap">
                        <div class="pricing-header">
                            <span>1840$<span class="year">/ Year </span></span>
                            <div class="svg-container">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                    <path d="M8.00027 25.9653C7.99365 26.3708 8.11042 26.7686 8.33509 27.1062C8.55975 27.4437 8.88171 27.705 9.25827 27.8553L28.4603 35.5373L36.1423 54.7413C36.2907 55.1128 36.547 55.4312 36.8782 55.6554C37.2094 55.8797 37.6003 55.9995 38.0003 55.9993H38.0323C38.4373 55.993 38.8309 55.8637 39.1609 55.6287C39.4909 55.3937 39.7418 55.0641 39.8803 54.6833L55.8803 10.6833C56.0112 10.3254 56.0372 9.93747 55.9551 9.56526C55.873 9.19305 55.6862 8.85206 55.4168 8.58243C55.1474 8.31281 54.8066 8.12578 54.4344 8.04339C54.0623 7.96099 53.6743 7.98665 53.3163 8.11735L9.31627 24.1173C8.9354 24.2562 8.60566 24.5074 8.37067 24.8378C8.13568 25.1681 8.00652 25.562 8.00027 25.9673V25.9653Z" fill="#00B79D"/>
                                    </svg>
                                </span>
                            </div>
                            <h3>
                                Go
                            </h3>
                        </div>

                        <ul class="pricing-list px-2">
                            <li class="d-flex justify-content-between">
                                <span>
                                    Random Ram
                                </span> 
                                <span>
                                    2048mb
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Hard Disk
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Traffic
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Calls Number /moment
                                </span> 
                                <span>
                                    500
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Mails Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Databases Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssl certificate
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssh  Access
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Technical Support/24
                                </span> 
                                <span class="free">
                                    Free
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                        </ul>

                        <div class="price-btn">
                            <router-link to='/subscribe' class="default-btn">
                                Order Now
                            </router-link>
                        </div>
                    </div>
                </section>
                <section class="col-lg-3 col-md-6 mt-3">
                    <div class="single-pricing">
                        <div class="pricing-header">
                            <span>1840$<span class="year">/ Year </span></span>
                            <div class="svg-container">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                    <path d="M60.8 4H3.2C1.433 4 0 5.79125 0 8V56C0 58.2088 1.433 60 3.2 60H60.8C62.567 60 64 58.2088 64 56V8C64 5.79125 62.567 4 60.8 4ZM17.6 40.985V43C17.6 43.5525 17.242 44 16.8 44H15.2C14.758 44 14.4 43.5525 14.4 43V40.9637C13.271 40.8913 12.173 40.3987 11.263 39.545C10.873 39.1787 10.853 38.4487 11.206 38.0275L12.381 36.6263C12.658 36.2962 13.07 36.2812 13.394 36.535C13.781 36.8375 14.22 37 14.676 37H17.487C18.137 37 18.667 36.26 18.667 35.3512C18.667 34.6075 18.306 33.9525 17.79 33.76L13.29 32.0725C11.431 31.375 10.132 29.145 10.132 26.6488C10.132 23.5837 12.037 21.0938 14.399 21.015V19C14.399 18.4475 14.757 18 15.199 18H16.799C17.241 18 17.599 18.4475 17.599 19V21.0362C18.728 21.1087 19.826 21.6 20.736 22.455C21.126 22.8212 21.146 23.5513 20.793 23.9725L19.618 25.3738C19.341 25.7038 18.929 25.7188 18.605 25.465C18.218 25.1613 17.779 25 17.323 25H14.512C13.862 25 13.332 25.74 13.332 26.6488C13.332 27.3925 13.693 28.0475 14.209 28.24L18.709 29.9275C20.568 30.625 21.867 32.855 21.867 35.3512C21.867 38.4175 19.962 40.9062 17.6 40.985ZM41.6 39C41.6 39.5525 41.242 40 40.8 40H29.6C29.158 40 28.8 39.5525 28.8 39V37C28.8 36.4475 29.158 36 29.6 36H40.8C41.242 36 41.6 36.4475 41.6 37V39ZM57.6 39C57.6 39.5525 57.242 40 56.8 40H48.8C48.358 40 48 39.5525 48 39V37C48 36.4475 48.358 36 48.8 36H56.8C57.242 36 57.6 36.4475 57.6 37V39ZM57.6 27C57.6 27.5525 57.242 28 56.8 28H29.6C29.158 28 28.8 27.5525 28.8 27V25C28.8 24.4475 29.158 24 29.6 24H56.8C57.242 24 57.6 24.4475 57.6 25V27Z" fill="#00B79D"/>
                                    </svg>
                                </span>
                            </div>
                            <h3>
                                Save Money
                            </h3>
                        </div>

                        <ul class="pricing-list px-2">
                            <li class="d-flex justify-content-between">
                                <span>
                                    Random Ram
                                </span> 
                                <span>
                                    2048mb
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Hard Disk
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Traffic
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Calls Number /moment
                                </span> 
                                <span>
                                    500
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Mails Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Databases Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssl certificate
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssh  Access
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Technical Support/24
                                </span> 
                                <span class="free">
                                    Free
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                        </ul>

                        <div class="price-btn">
                            <router-link to='/subscribe' class="default-btn">
                                Order Now
                            </router-link>
                        </div>
                    </div>
                </section>
                <section class="col-lg-3 col-md-6 mt-3">
                    <div class="single-pricing top-gap">
                        <div class="pricing-header">
                            <span>1840$<span class="year">/ Year </span></span>
                            <div class="svg-container">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                    <path d="M27.8663 41.3346C28.9775 42.4457 30.3775 42.9675 32.0663 42.9C33.7552 42.8342 34.9775 42.2235 35.733 41.068L50.6663 18.668L28.2663 33.6013C27.1108 34.4013 26.4779 35.612 26.3677 37.2333C26.2557 38.8564 26.7552 40.2235 27.8663 41.3346ZM13.5997 53.3346C12.6219 53.3346 11.7223 53.1231 10.901 52.7C10.0779 52.2786 9.4219 51.6457 8.93301 50.8013C7.77745 48.7124 6.88856 46.5462 6.26634 44.3026C5.64412 42.0573 5.33301 39.7346 5.33301 37.3346C5.33301 33.6457 6.03345 30.1791 7.43434 26.9346C8.83345 23.6902 10.733 20.868 13.133 18.468C15.533 16.068 18.3552 14.1675 21.5997 12.7666C24.8441 11.3675 28.3108 10.668 31.9997 10.668C35.6441 10.668 39.0663 11.3569 42.2663 12.7346C45.4663 14.1124 48.2663 15.9906 50.6663 18.3693C53.0663 20.7462 54.9775 23.5231 56.3997 26.7C57.8219 29.8786 58.5552 33.2902 58.5997 36.9346C58.6441 39.3791 58.3668 41.7684 57.7677 44.1026C57.1668 46.4351 56.2441 48.668 54.9997 50.8013C54.5108 51.6457 53.8557 52.2786 53.0343 52.7C52.2112 53.1231 51.3108 53.3346 50.333 53.3346H13.5997Z" fill="#00B79D"/>
                                    </svg>
                                </span>
                            </div>
                            <h3>
                                Speed
                            </h3>
                        </div>

                        <ul class="pricing-list px-2">
                            <li class="d-flex justify-content-between">
                                <span>
                                    Random Ram
                                </span> 
                                <span>
                                    2048mb
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Hard Disk
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Traffic
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Calls Number /moment
                                </span> 
                                <span>
                                    500
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Mails Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Databases Number
                                </span> 
                                <span>
                                    unlimited
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssl certificate
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Ssh  Access
                                </span> 
                                <span>
                                    Available
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Technical Support/24
                                </span> 
                                <span class="free">
                                    Free
                                </span>
                            </li>
                            <li class="d-flex justify-content-between">
                                <span>
                                    Processor
                                </span> 
                                <span>
                                    5000 MHZ
                                </span>
                            </li>
                        </ul>

                        <div class="price-btn">
                            <router-link to='/subscribe' class="default-btn">
                                Order Now
                            </router-link>
                        </div>
                    </div>
                </section>

                <!--End Of Pricing -->
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pricing'
    }
</script>