<template>
    <section >
        <div id="selectPlan">
        <div class="container">
            <header >
                <h3 class="text-center">How do you want to pay for your order?</h3>
            </header>
          <form v-if="selectPlan">
            <div class="d-flex flex-column flex-md-row justify-content-center align-items-center gap-4" >
                <section  @click="planPrice('credit')" :class="planSection === 'credit' ? 'activePrice':''">
                
                <div class="plan-icon"><img src="../../assets/images/pocket.svg" /></div>
             
                <div class="plan-bottom ms-2 ms-lg-1 text-center">
                    <span class="plan-price ">Credit/Debit <br>Card</span>
                </div>
            </section>
              <section  @click="planPrice('paypal')" :class="planSection === 'paypal' ? 'activePrice':''">
                  <div class="plan-icon"><img src="../../assets/images/paypal.svg" /></div>
                <div class="plan-bottom ms-2 ms-lg-1">
       
                    <span class="plan-price">PayPal</span>
                </div>
                
              </section>
              <section @click="planPrice('pay')" :class="planSection === 'pay' ? 'activePrice':''">
                  <div class="plan-icon"><img src="../../assets/images/pay.svg" /></div>
                <div class="plan-bottom ms-2 ms-lg-1">
          
                    <span class="plan-price">Apple Pay</span>
                </div>
                
              </section>
            </div>
            
            <div class="text-center btn-content">
                <button type="submit" class="payment-method">Save and Continue</button>
            </div>
          </form>
       
        
      </div>
    </div>
      <!--------Start Of Footer Slot---->
        <section v-if="planSection === 'credit'" ref="section2">
            <div class="client-credit-area">
                <div class="row">    
                <div class="col-lg-6 ps-lg-3  order-2 order-lg-1 mt-5 mt-lg-0">
                    <div>
                        <h4>Credit/Debit Card</h4>
                    </div>
                    <div>
                        <div class="mt-3 client-credit">
                            <label>Credit Number</label>
                            <input type="text" class="" placeholder="Card Number">
                        </div>
                        <div class="mt-3 client-credit">
                            <label>Card Holder Name</label>
                            <input type="text" class="" placeholder="Card holder Name">
                        </div>
                        <div class="row">
                            <div class="mt-3 client-credit col-lg-6">
                            <label>Expiry Date</label>
                            <input type="date" placeholder="MM/YY">
                            </div>
                            <div class="mt-3 client-credit col-lg-6">
                                <label>CW2/CV2 Number</label>
                                <input type="date" placeholder="MM/YY">
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div class="col-lg-6 mt-5 mt-lg-0  order-1 order-lg-2">
                    <div class="d-flex justify-content-end gap-5 align-items-center pe-lg-5">
                        <img src="../../assets/images/visa.svg" alt="visa">
                        <img src="../../assets/images/mada.svg" alt="mada">
                        <img src="../../assets/images/master-card.svg" alt="master">
                    </div>
                    <div class="d-flex justify-content-center align-items-center text-center h-100">
                       <img src="../../assets/images/credit.svg" alt="credit" class="main-img">
                    </div>
                </div>
            </div>
            </div>
        </section>
        <section v-if="planSection === 'pay'">
            <div class="row client-credit-area">
                <div class="col-lg-5 offset-lg-1 order-2 order-lg-1 mt-5 mt-lg-0">
                    <div>
                        <h4>Credit/Debit Card</h4>
                    </div>
                    <div>
                        <div class="mt-3 client-credit">
                            <label>Credit Number</label>
                            <input type="text" class="" placeholder="Card Number">
                        </div>
                        <div class="mt-3 client-credit">
                            <label>Card Holder Name</label>
                            <input type="text" class="" placeholder="Card holder Name">
                        </div>
                        <div class="row">
                            <div class="mt-3 client-credit col-lg-6">
                            <label>Expiry Date</label>
                            <input type="date" placeholder="MM/YY">
                            </div>
                            <div class="mt-3 client-credit col-lg-6">
                                <label>CW2/CV2 Number</label>
                                <input type="date" placeholder="MM/YY">
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div class="col-lg-6   order-1 order-lg-2">
                    <div class="d-flex justify-content-end gap-5 align-items-center pe-lg-5">
                        <img src="../../assets/images/visa.svg" alt="visa">
                        <img src="../../assets/images/mada.svg" alt="mada">
                        <img src="../../assets/images/master-card.svg" alt="master">
                    </div>
                    <div class="d-flex justify-content-center align-items-center text-center h-100">
                       <img src="../../assets/images/credit.svg" alt="credit" class="main-img">
                    </div>
                </div>
            </div>
        </section>
        <section v-if="planSection === 'paypal'"  ref="section2">
            <div class="row client-credit-area">
                <div class="col-lg-5 offset-lg-1  order-2 order-lg-1 mt-5 mt-lg-0">
                    <div>
                        <h4>Credit/Debit Card</h4>
                    </div>
                    <div>
                        <div class="mt-3 client-credit">
                            <label>Credit Number</label>
                            <input type="text" class="" placeholder="Card Number">
                        </div>
                        <div class="mt-3 client-credit">
                            <label>Card Holder Name</label>
                            <input type="text" class="" placeholder="Card holder Name">
                        </div>
                        <div class="row">
                            <div class="mt-3 client-credit col-lg-6">
                            <label>Expiry Date</label>
                            <input type="date" placeholder="MM/YY">
                            </div>
                            <div class="mt-3 client-credit col-lg-6">
                                <label>CW2/CV2 Number</label>
                                <input type="date" placeholder="MM/YY">
                            </div>
                        </div>
                      
                    </div>
                </div>
                <div class="col-lg-6 mt-5 mt-lg-0  order-1 order-lg-2">
                    <div class="d-flex justify-content-end gap-5 align-items-center pe-lg-5">
                        <img src="../../assets/images/visa.svg" alt="visa">
                        <img src="../../assets/images/mada.svg" alt="mada">
                        <img src="../../assets/images/master-card.svg" alt="master">
                    </div>
                    <div class="d-flex justify-content-center align-items-center text-center h-100">
                       <img src="../../assets/images/credit.svg" alt="credit" class="main-img">
                    </div>
                </div>
            </div>
        </section>
      <!--------End Of Footer Slot---->
    </section>
  </template>
  
  <script>

  export default {
      components:{
       
      },
      data() {
          return {
            selectPlan: true,
            selectedPrice : 0,
            planSection: '',
           /* payPeriod: '',
            payInfo : null,
            errorMsg : ''*/
          }
      },
      methods:{
          planPrice(x){
            this.planSection = x;
            if (this.$refs.section2) {
                this.$refs.section2.scrollIntoView({ behavior: 'smooth' });
            }
          },
          /*addPlanPrice(){
            if(this.selectedPrice !== 0){
              localStorage.setItem('Pay Info',JSON.stringify(this.payInfo));
  
              localStorage.setItem('selectedPlan',JSON.stringify(this.selectPlan));
  
              this.payPeriod = this.selectPlan ? 'Monthly' : 'Yearly';
  
              localStorage.setItem('payPeriod',this.payPeriod);
  
              this.errorMsg = ''
              this.$router.push('/add')
            }else{
              alert('Please Select A plan')
            }
          }*/
      },
      mounted(){
        this.planPrice;
      }
  }
  </script>
 