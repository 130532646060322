<template>
    <div class="login-section ptb-100 ">
      <div class="container">
        <section class="reset-form" v-if="!getCode">
          <div class="row">
            <section class="col-lg-6 text-center">
                <img
                  src="../../assets/images/Images/forget-pass.webp"
                  alt="log in vector"
                  width="500"
                  height="500"
                />
            </section>
            <section class="col-lg-6">
                <header class="login-title d-flex justify-content-center align-items-center flex-column">
                <h3>Reset Password</h3>
                <p class="text-center">
                    Enter your registered email below to receive password<br />
                    reset code
                </p>
                </header>
              <form @submit.prevent="submitEmail">
                <div class="row">
                  <div class="col-lg-8 offset-lg-2">
                    <div class="form-group position-relative">
                      <label> <i class="fa-regular fa-envelope"></i></label>
                      <input
                        type="email"
                        class="form-control" 
                        placeholder="Email Address"
                        name="useremail" v-model="email" required
                      />
                    </div>
                  </div>
           
                  <div class="col-lg-12 d-flex justify-content-center align-items-center flex-column">
                    <div class="send-btn">
                      <button type="submit" class="default-btn ">
                        Get Code
                        <span></span>
                      </button>
                    </div>   
                  </div>
                </div>
              </form>
            </section>
          </div>
        </section>

        <section v-if="getCode" class="varification-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 offset-lg-3  d-flex justify-content-center align-items-center flex-column ">
                        <header>
                            <h3 class="text-center">Confirm your email or phone number</h3>
                            <p class="text-center">
                                Please enter a 4-digit code sent to<br /> {{ maskedEmail }}
                             </p>
                        </header>
                        <form role="form" method="POST" action="">
                            <!----<div class="row my-4">
                                <div class="col-12 text-center">
                                    <div class="pinBox">
                                        <input class="pinEntry" name="token" type=text maxlength=4 autocomplete=off  v-model="pin"
                                            @focus="selectAllIfFull" >
                                    </div>
                                </div>
                            </div>-->
                            <div class="d-flex justify-content-center align-items-center my-4 w-100">
                                <div class="verificationCode">
                                <input
                                v-for="(digit, index) in codeDigits"
                                :key="index"
                                v-model="codeDigits[index]"
                                ref="codeInputs"
                                @input="focusNextInput(index)"
                                type="tel"
                                pattern="[0-9]"
                                maxlength="1"
                                />
                            </div>
                            </div>
                           <p class="timer">
                            <span>sending time</span>
                            <span>00:15</span>
                           </p>

                            <div class="send-btn text-center">
                                <button type="submit" class="default-btn">
                                    Verify
                                </button>
                            </div> 
                            <div class="text-center resend">
                                <p
                                    >Didn't you get the code? 
                                    <span>Resend the code</span></p>
                                
                            </div>
                        </form>
                        
                    </div>

                </div>
            </div>
        </section>
      </div>
  
      <div class="default-shape">
        <div class="shape-1">
          <img src="../../assets/images/shapes/shape-4.png" alt="image" />
        </div>
  
        <div class="shape-2 rotateme">
          <img src="../../assets/images/shapes/shape-5.svg" alt="image" />
        </div>
  
        <div class="shape-3">
          <img src="../../assets/images/shapes/shape-6.svg" alt="image" />
        </div>
  
        <div class="shape-4">
          <img src="../../assets/images/shapes/shape-7.png" alt="image" />
        </div>
  
        <div class="shape-5">
          <img src="../../assets/images/shapes/shape-8.png" alt="image" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ResetPass",
    data(){
        return{
            getCode: false,
            email:null,
            shortedEmail: null,
            pin: '',
            codeDigits: ['', '', '', ''],

        }
    },
    computed: {
    maskedEmail() {
      if (!this.email) {
        return '';
      }
      const atIndex = this.email.indexOf('@');
      const maskedPart = '*'.repeat(this.email.length - atIndex - 4);
      return this.email.substring(0, 4) + maskedPart + this.email.substring(atIndex)
    },
  },
    methods:{
        submitEmail(){
            if (!this.email) {
                alert('Please enter an email address.');
                return;
            }
            // Additional validation logic for email format
            if (!this.isValidEmail(this.email)) {

                alert('Please enter a valid email address.');
                return;
            }
            this.getCode = true;
            },
            isValidEmail(email) {
            // Basic email format validation
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
            },

            /*selectAllIfFull(event) {
                if (this.pin.length >= 4) {
                    event.target.select();
                }
            },*/

        focusNextInput(index) {
            if (index < this.$refs.codeInputs.length - 1) {
                this.$refs.codeInputs[index + 1].focus();
            }
        },
      
    }
  };
  </script>
  <style scoped>
  


</style>