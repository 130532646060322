<template>
    <div class="set-up-domain pt-70 pb-70">
        <div class="container">
            <div class="row">
                <section class="col-lg-6 text-center">
                    <img src="../../assets/images/set-up-domain.png" alt="set up domain " width="428" height="425">
                </section>
                <section class="col-lg-6 d-flex justify-content-center align-items-center flex-column">
                    <header class="mt-5 mt-lg-0">
                        <h2>Next we'll set up your domain.</h2>
                        <p>
                            Your domain will be your website's address.<br>
You can create a new domain, use one you already own, or make one later.
                        </p>
                    </header>
                </section>
            </div>
            <div class="row pt-70">
                <section class="col-lg-4 offset-lg-2 form-container">
                    <form>
                        <legend>Create a new Domain</legend>
                        <div class="mb-3 first-form">
                            <label for="exampleInputEmail2 d-none" class="form-label d-none">Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail2" aria-describedby="emailHelp">
                            <select class="form-select" aria-label="Default select example">
                                <option selected class="text-muted">.com</option>
                                <option value="1">.org</option>
                                <option value="2">.co</option>  
                            </select>
                            <div id="emailHelp1" class="form-text">search available domains</div>
                        </div>
                        <button type="submit" class="btn btn-primary" @click="submitForm">Next</button>
                    </form>
                </section>
                <section class="col-lg-4 ms-lg-3 form-container">
                    <form>
                        <legend>Use a domain you own</legend>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label d-none" >Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                       
                            <div id="emailHelp" class="form-text">Enter your existing domain name</div>
                        </div>
                        
                        <button type="submit" class="btn btn-primary" @click="submitForm">Next</button>
                    </form>
                </section>
            </div>
            <section class="d-flex justify-content-center align-items-center form-footer">
                <div>
                    <p>Or you can skip this step and create a domain later.</p>
                    <router-link to="/">
                        I’ll create my domain later <span><i class="fa-solid fa-chevron-right"></i></span>
                    </router-link>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import router from '../../router';
  export default{
    methods:{
        submitForm(){
            router.push('/personal-details')
        }
    }
  }
</script>