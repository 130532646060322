<template>
  <div>
    <div class="tab-nav">
      <div class="tab-title"
        v-for="(tab, index) in tabs"
        :key="index"
        @click="selectTab(index)"
        :class="{ active: activeTab === index }"
      >
        {{ tab.title }}
      </div>
    </div>
    <div >
      <slot :tab="tabs[activeTab]"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeTab: 0
    };
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
    }
  }
};
</script>

<style>

</style>
