<template>
   
    <header class="dev-services-heading services-bg-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 ">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content">
                        <h1> Secure your online existence now</h1>
                        <p>
                            We provide custom cybersecurity solutions to secure your business data, including risk assessment, threat detection, intrusion prevention, incident response, and security awareness training.
                        </p>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </header>
 
</template>
<script>
    export default{
        name:'CyberHeader'
    }
</script>