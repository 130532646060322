<template>
    <div class="product-overview">
        <div class="right-circle circle"></div>
        <section class="container">
            <div class="text-center">
                <h2>
                    About Our System

                </h2>
                <p>
                    Our E-learning systems are designed to enhance learning experiences, reduce administrative tasks, and empower educators to deliver impactful teaching to different learners all over the world.
                </p>
            </div>
        </section>
        <div class="left-circle circle"></div>
    </div>
</template>