<template>
    <div class="services-faq-section pt-35 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <section class="col-lg-6">
                    <header class="services-section-title text-center my-2 my-lg-0 text-lg-start pe-lg-5">
                        <h2>Frequently Asked Questions</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </header>
                    <div class="services-faq-image">
                        <img src="../../assets/images/Images/faq_img.webp"  alt="faq image" width="610" height="343">
                    </div>
                </section>

                <div class="col-lg-6 ps-lg-4">
                    <section class="services-faq-accordion">
                        <accordion>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What does SEO mean?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        SEO, which stands for Search Engine Optimization, is a set of practices aimed at enhancing various aspects of your website to boost its ranking on Google unpaid search results. 
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What are the types of SEO processes?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        There are several types of SEO processes, each targeting a specific part of your website. These include:
                                        <ul>
                                            <li> Technical SEO: Focuses on improving the site's structure, codes, and other technical elements to facilitate site indexing by search engines.</li>
                                            <li>
                                                On-Page SEO: Improves on-page elements such as content and other items to make them more compatible with search engines.
                                            </li>
                                            <li>
                                                Off-page SEO: This relates to all efforts made outside the site to enhance the site's strength and impact on search results. This includes building external links (Backlinks).
                                            </li>
                                        </ul>
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        How long does it take to see SEO results?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        We cannot determine a specific duration; it might take a few months to see significant results. This depends on factors such as the size of the site, content quality, the strength of external links (Backlinks), and keyword competition.
                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        What is the difference between SEO and SEM?

                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        The term SEO refers to the process of optimizing websites to appear in free search engine results. 
                                        SEM (Search Engine Marketing) is a broader term that includes all practices aimed at improving the visibility of different websites on search engines, such as Google ads.

                                    </p>
                                </template>
                            </accordion-item>
                            <accordion-item>
                                <template v-slot:accordion-trigger>
                                    <button class="accordion-title">
                                        Is SEO a one-time investment?
                                        <span></span>
                                        <i class="fa-solid fa-angle-down"></i>
                                    </button>
                                </template>
                                <template v-slot:accordion-content>
                                    <p>
                                        While you may see initial improvements from implementing SEO strategies, maintaining and improving your website's SEO performance requires ongoing investment and effort.
                                    </p>
                                </template>
                            </accordion-item>
                        </accordion>
                    </section>
                </div>

              
            </div>
        </div>
    </div>
</template>

<script>
import Accordion from "../Common/Accordion";
import AccordionItem from "../Common/AccordionItem";

export default {
    name: 'Faq',
    components: {
        Accordion,
        AccordionItem
    }
}
</script>