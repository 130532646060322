<template>
    <section class="container pt-35 mb-4 pb-35 hosting-contact">
        <div class="row d-flex align-items-center">
            <section class="col-lg-7 offset-lg-1 d-flex justify-content-between align-items-center ">
                <div class="pe-3">
                    <img src="../../assets/images/hosting-customer-service.svg" alt="hosting customer service icon">
                </div>
                <div>
                    <!----<h4>Do you have a question ?</h4>-->
                    <p>
                        Get global and reliable hosting services for your website/store.
                    </p>
                </div>
            </section>
            <section class="col-4 d-flex">
                <button class="hosting-contact-btn mx-auto">
                    <router-link to="/contact">Book your hosting now</router-link>
                </button>
            </section>
        </div>
    </section>
</template>
<script>

export default{
    name:'HostingContact'
}
</script>
