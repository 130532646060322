<template>
  <div>
    <navbar-style-two></navbar-style-two>
    <digital-header></digital-header>
    <digital-overview></digital-overview>
    <DigitalServices></DigitalServices>
    <DigitalMax></DigitalMax>
    <DigitalMaxEnd></DigitalMaxEnd>
    <DigitalProcess></DigitalProcess>
    <ui-work-with-us class="my-5"></ui-work-with-us>
    <Faq></Faq>
    <Footer />
  </div>
</template>

<script>
import NavbarStyleTwo from "../Layout/NavbarStyleTwo";
import DigitalHeader from "../DigitalMarketing/DigitalHeader";
import DigitalOverview from "../DigitalMarketing/DigitalOverview.vue";
import DigitalServices from "../DigitalMarketing/DigitalServices";
import DigitalMax from "../DigitalMarketing/DigitalMax";
import DigitalMaxEnd from '../DigitalMarketing/DigitalMaxEnd'
import DigitalProcess from '../DigitalMarketing/DigitalProcess'
import UiWorkWithUs from "../DigitalMarketing/DigitalworkWithUs.vue";
import Faq from '../DigitalMarketing/Faq'
import Footer from "../Layout/Footer";
import { useHead } from "@vueuse/head";
export default {
  components: {
    NavbarStyleTwo,
    DigitalHeader,
    DigitalOverview,
    DigitalServices,
    DigitalMax ,
    DigitalMaxEnd,
    UiWorkWithUs,
    DigitalProcess,
    Faq,
    Footer,
    
  },
  setup(){
            useHead({
                title: 'Misk - Digital Marketing service',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
};
</script>
