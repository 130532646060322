<template  >
    <div>
       
       <div class="content">
        
        <navbar-style-two></navbar-style-two>
        <page-title></page-title>
        <Login />
        
       </div>

        <Footer  class="footer"/>

       
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import PageTitle from '../LogIn/PageTitle'
    import Login from '../LogIn/Login'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'
    /*import CopyRight from '../Layout/CopyRight'*/

    export default {
        name: 'LogInPage',
        components: {
            NavbarStyleTwo,
            Login,
            PageTitle,
            Footer,
           /* CopyRight*/
        },
        setup(){
            useHead({
                title: 'Misk - Log in Page',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>