<template>
    <section class="container pt-100 pb-100 hosting-contact">
        <div class="row d-flex align-items-center">
            <section class="col-lg-7 offset-lg-1 d-flex justify-content-between align-items-center ">
                <div class="pe-3">
                    <img src="../../assets/images/hosting-customer-service.svg" alt="hosting customer service icon">
                </div>
                <div>
                    <h4>Do you have a question ?</h4>
                    <p>
                        Our engineers and technical support team at EXA are now ready
                        to answer your questions, around the clock
                    </p>
                </div>
            </section>
            <section class="col-4 d-flex">
                <button class="hosting-contact-btn mx-auto">
                    <router-link to="/contact">Contact Us</router-link>
                </button>
            </section>
        </div>
    </section>
</template>
<script>

export default{
    name:'CloudContact'
}
</script>
