<template>
    <div class="container">
        <div class="what-we-do-container ps-2">
            <div class="row">
                <atricle class="col-lg-8 order-2 order-lg-1 my-4 my-lg-0">
                    <h5>What We Do</h5>
                    <p class="what-we-do-text">
                        Before the Client turned to us at Elogic, their website looked as if it could use a cup of
                        coffee itself. It was quite limited when it came to SEO capabilities, product types, and payment methods.
                        After a series of ecommerce consultations, we suggested the brand to replatform from Shopify to Adobe Commerce,
                            which offers more flexibility in terms of building something custom and unique.
                    </p>
                    <p>
                        The Elogic team developed the store from scratch, migrated all customer & order info, and added the following functionalities:
                    </p>
                    <ul class="list-unstyled">
                        <li>
                            <p>
                                <span> → </span> A wholesale platform: the brand preserved the domain it has for its B2C website but added a 
                                B2B portal with returning customer login, 
                                different pricing policies, and discount systems, among others.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span> → </span> Virtual products: besides physical coffee products, 
                                a shopper can purchase offline and online coffee roasting 
                                workshops choosing a specific day and time.
                            </p>
                        </li>
                        <li>
                            <!-- <i class="fa-solid fa-arrow-right-long"></i>   →  -->
                            <p>
                                <span> → </span> Reward points: each time a registered user places an order, they earn 
                                reward points that can be later spent on future purchases.
                            </p>
                        </li>
                        <li>
                            <!--<span>
                                <i class="fa-solid fa-arrow-right-long"></i>   →  
                            </span>-->
                            <p>
                                <span> → </span> Customized search: to optimize ecommerce search bar conversions, our Client enabled 
                                the autosuggest function which shows relevant products as 
                                soon as a user starts typing in a search bar.
                            </p>
                        </li>
                        <li>
                            <!----<span>
                                <i class="fa-solid fa-arrow-right-long"></i> →  --
                            </span>-->
                            <p>
                                <span> → </span> Saving groups: users can buy products in bundles and pay less.
                            </p>
                        </li>
                    </ul>
                </atricle>
                <section class="col-lg-4 order-1 order-lg-2 text-center">
                    <img src="../../assets/images/albatny-screenshot.jpg" alt="screenshot of Elbalaty website" width="351" height="360">
                </section>
            </div>       
        </div>
    </div>
</template>