<template>
    <div class="container">
        <section class="overview-app">
            <div class="text-center">
                <button @click="showContent('button1')" :class="{ 'active-btn': activeButton === 'button1' }">Role Overview</button>
                <button @click="showContent('button2')" :class="{ 'active-btn': activeButton === 'button2' }">Application</button>
            </div>  
        
            <div v-if="activeButton === 'button1' || tabName === 'button1'" >
                <overview-tab @goToApplication="redirectToApplication"></overview-tab>
            </div>
            <div v-else-if="activeButton === 'button2' || tabName === 'button2'"  >  
               <Application />
            </div>
        </section>
    </div>
</template>
<script>
import Application from './Application'
import OverviewTab from '../Jobs/OverviewTab';

    export default{
        components:{
             Application,
            OverviewTab,
            
        },
        data(){
        return{
            activeButton: 'button1',
            isNewchangeTab: localStorage.getItem('isNewchangeTab') === 'true'
        }
        },
        methods: {
            showContent(button) {
                this.activeButton = button;

                if(button === 'button2'){
                    this.isNewchangeTab = true
                    localStorage.setItem('isNewchangeTab', 'true');
                }else{
                    this.isNewchangeTab = false
                    localStorage.setItem('isNewchangeTab', 'false');
                }
            },
            redirectToApplication() {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.activeButton = 'button2';
                this.isNewchangeTab = true;
                localStorage.setItem('isNewchangeTab', 'true');
            }
        },
  
    }

</script>