<template>
    <div class="container pt-100 pb-100">
        <section class="product-dashboard">
            <div class="row">  
                <div class="col-lg-6 d-flex align-items-center order-lg-1 order-2 mt-3">
                    <div class="pe-lg-5">   
                        <h3>Interactive Online Lectures</h3>
                        <p>
                            We value student engagement for successful learning. Our systems offer interactive online lectures, promoting better communication between students and instructors. Students can ask questions, share thoughts, and engage in interactive activities. Instructors can also deliver lectures easily, share multimedia content, and conduct quizzes to assess comprehension and promote active learning.
                        </p>
                    </div>
                </div>
                <div class="col-lg-6 d-flex justify-content-center align-items-center order-lg-2 order-1">
                    <img src="../../assets/images/Images/dashboardimg.webp" alt="" width="638" height="341">
                </div>
            </div>
        </section>
    </div>
</template>