<template>
    <div>
        <navbar-style-two></navbar-style-two>
       <GraphicHeader/>
        <GraphicOverview/>
        <BrandServices/>
        <graphic-design-process></graphic-design-process>
     
        <graphic-work-with-us></graphic-work-with-us>
        <Faq />
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import GraphicHeader from '../BrandIdentity/GraphicHeader'
    import GraphicOverview from '../BrandIdentity/GraphicOverview'
    import BrandServices from '../BrandIdentity/BrandServices'
    import GraphicDesignProcess from '../BrandIdentity/GraphicDesignProcess'

    import GraphicWorkWithUs from '../BrandIdentity/GraphicWorkWithUs'
    import Faq from '../BrandIdentity/Faq'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default{
        components:{
            NavbarStyleTwo,
            GraphicHeader,
            GraphicOverview,
            GraphicDesignProcess,
            GraphicWorkWithUs,

            BrandServices,
            Faq,
            Footer
        },
        setup(){
            useHead({
                title: 'Misk - Graphic Design',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>