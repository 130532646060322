<template>
    <div class="container ">
        <section class="d-flex justify-content-center privacy-policy-content">
            <tabs :tabs="tabs">
                <template v-slot="{ tab }">
                    <section class="tab-content position-relative">
                        <h1>{{ tab.heading }}</h1>
                        <article v-for="item , index of tab.content" :key="index">
                        <h2>{{ item.articleHeading }}</h2>
                        <p>{{ item.articleContent }}</p>
                        </article>
                        <div class="bottom-div"></div>
                    </section>
                    
                </template>
            </tabs>
        </section>
        <section class="agreeCondition">
            <div class="text-center">
                <button>Decline</button>
                <button>accept</button>
            </div>
        </section>
      
    </div>
  </template>
  
  <script>
  import Tabs from "./Tabs.vue";
  
  export default {
    components: {
      Tabs
    },
    data() {
      return {
        tabs: [
          { 
            title: "Terms of services", 
            heading: "Terms of services",
            content: [
                {
                    articleHeading: 'a-Terms',
                    articleContent: 'Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the https://marsawaves.com/.Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the services we provide, you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.'
                },
                {
                    articleHeading: 'b-Termination',
                    articleContent: 'We may terminate or suspend access to our Service immediately, without prior notice or liability, including without limitation if you breach the Terms by Infringement of property rights. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.'
                },
                {
                    articleHeading: 'c-services',
                    articleContent: `Our Service allows you to access to the best service providers in the Red Sea who have been carefully selected and we deal with them as we are a company that connects the customer with the best service provider in the region. Like any other website on the internet, we might have our website down for maintenance or due to hosting company issues. This will rarely happen, and we will do our best to reduce the downtime, but you have no right to claim damages due to such events.
                                    Links To Other Web Sites
                                    Our Service may contain links to third-party websites or services that are not owned or controlled by marsawaves.
                                    marsawaves has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Pm-tricks. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such web sites or services.`
                },

            ]
        },
        { 
            title: "Refund policy",
            heading: "Refund policy", 
            content: [
                {
                    articleHeading: 'a-Terms',
                    articleContent: 'Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the https://marsawaves.com/.Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the services we provide, you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.'
                },
                {
                    articleHeading: 'b-Termination',
                    articleContent: 'We may terminate or suspend access to our Service immediately, without prior notice or liability, including without limitation if you breach the Terms by Infringement of property rights. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.'
                },
                {
                    articleHeading: 'c-services',
                    articleContent: `Our Service allows you to access to the best service providers in the Red Sea who have been carefully selected and we deal with them as we are a company that connects the customer with the best service provider in the region. Like any other website on the internet, we might have our website down for maintenance or due to hosting company issues. This will rarely happen, and we will do our best to reduce the downtime, but you have no right to claim damages due to such events.
                                    Links To Other Web Sites
                                    Our Service may contain links to third-party websites or services that are not owned or controlled by marsawaves.
                                    marsawaves has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Pm-tricks. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such web sites or services.`
                },

            ]
        },
        { 
            title: "Privacy Policy", 
            heading:"Privacy Policy",
            content: [
                {
                    articleHeading: 'a-Terms',
                    articleContent: 'Please read these Terms of Service (“Terms”, “Terms of Service”) carefully before using the https://marsawaves.com/.Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the services we provide, you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access the Service.'
                },
                {
                    articleHeading: 'b-Termination',
                    articleContent: 'We may terminate or suspend access to our Service immediately, without prior notice or liability, including without limitation if you breach the Terms by Infringement of property rights. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.'
                },
                {
                    articleHeading: 'c-services',
                    articleContent: `Our Service allows you to access to the best service providers in the Red Sea who have been carefully selected and we deal with them as we are a company that connects the customer with the best service provider in the region. Like any other website on the internet, we might have our website down for maintenance or due to hosting company issues. This will rarely happen, and we will do our best to reduce the downtime, but you have no right to claim damages due to such events.
                                    Links To Other Web Sites
                                    Our Service may contain links to third-party websites or services that are not owned or controlled by marsawaves.
                                    marsawaves has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Pm-tricks. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such web sites or services.`
                },
            ]
        }
        ]
      };
    }
  };
  </script>
  