import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      counter: 0
    };
  },
  mutations: {
    increment(state) {
      state.counter++;
    }
  },
  actions: {
    incrementCounter(context) {
      context.commit('increment');
    }
  },
  getters: {
    getCounter(state) {
      return state.counter;
    }
  }
});

export default store;
