<template>
    <div class="pt-70">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="contant-page-title-content">
                        <span>Contact Information</span>
                        <h1>Contact Us</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>