<template>
    <div>
        <Navbar />
        <hosting-header></hosting-header>
        <HostingFeatures></HostingFeatures>
        <Pricing/>
        <HostingTransfer></HostingTransfer>
        <faq></faq>
        <hosting-contact></hosting-contact>
        <Footer />

    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import HostingHeader from '../Hosting/HostingHeader'
    import Pricing from '../Hosting/Pricing'
    import HostingFeatures from '../Hosting/HostingFeatures'
    import HostingTransfer from '../Hosting/HostingTransfer'
    import HostingContact from '../Hosting/HostingContact'
    import Faq from '../Hosting/HostingFaq.vue'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'Hosting',
        components: {
            Navbar,
            HostingHeader,
            Pricing,
            HostingFeatures,
            HostingTransfer,
            HostingContact,
            Faq,
            Footer,
        },
        setup(){
            useHead({
                title: 'Misk - web Hosting',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>