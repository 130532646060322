<template>
    <div class="services-section pt-35 pb-0">
        <div class="container">
            <header class="section-title">
               
                <h2>Our Services</h2>
                <p>
                    At Misk, we offer all you need to turn your idea into a reality. Our services include:

                   <!--Map a strategy, create a user friendly product experience, deliver reliable cross-platform
solution with on-boarding and staff training.--> 
                </p>
                <div class="bar"></div>
            </header>

            <section class="row">
                <div class="col-lg-4 col-md-6">
                    <router-link to="/services-development">
                    <div class="single-services">
                        <div class="icon">
                            <i class="flaticon-setting"></i>
                        </div>
                        <h3>Web Development</h3>
                        <p>
                            Whether you want to create a business website or a personal blog, we are ready to build it professionally. 

                        </p>
                        <!----<router-link to="/single-services" class="read-btn">Read More</router-link>-->
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                    <router-link to="/services-mobile-development" class="d-flex">
                    <div class="single-services flex-size">
                        <div class="icon">
                            <i class="flaticon-cellphone"></i>
                        </div>
                        <h3>Mobile Development</h3>
                        <p>
                            We make your project easily accessible to your customers by creating an app on their phones.
                        </p>  
                       <!--<router-link to="/services-graphic-design" class="read-btn">Read More</router-link>--> 
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                    <router-link to="/services-brand-design" class="d-flex">
                    <div class="single-services flex-size">
                        <div class="icon">
                            <i class="flaticon-optimize"></i>
                        </div>
                        <h3>Brand Identity Design</h3>
                        <p>
                            We make your brand present in your customers' minds with a professional brand identity.
                        </p> 
                        <!----<router-link to="/services-quality-assurance" class="read-btn">Read More</router-link>-->
                    </div>
                    </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                    <router-link to="/services-cyber-security" class="d-flex">
                    <div class="single-services">
                        <div class="icon">
                            <i class="flaticon-it"></i>
                        </div>
                        <h3>Cyber Security</h3>
                        <p>
                            We provide comprehensive cybersecurity solutions in various sectors such as banks, hospitals, universities, etc.
                        </p>  
                        <!----<router-link to="/services-cyber-security" class="read-btn">Read More</router-link>-->
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6">
                    <router-link to="/services-digital-marketing" class="d-flex">
                    <div class="single-services">
                        <div class="icon">
                            <i class="flaticon-promotion"></i>
                        </div>
                        <h3>Digital Marketing</h3>
                        <p>
                            We offer integrated digital marketing services, including professional marketing strategies, and more. To attract more customers, and increase your sales.
                        </p>  
                        <!--<router-link to="/services-digital-marketing" class="read-btn">Read More</router-link>-->
                    </div>
                </router-link>
                </div>

                <div class="col-lg-4 col-md-6 d-flex">
                        <router-link to="/services-seo" class="d-flex">
                            <div class="single-services">
                                <div class="icon">
                                    <i class="flaticon-shopping-cart"></i>
                                </div>
                                <h3>SEO Services</h3>
                                <p>
                                    We help you outperform your competitors and gain credibility with your customers through professional SEO services.
                                </p>  
                                <!----<router-link to="/services-ui-ux" class="read-btn">Read More</router-link>-->
                            </div>
                        </router-link>  
                </div>

            </section>
           
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Services'
        ,
    
    }
</script>
