<template>
    <div>
        <div class="container personal">
            <form @submit.prevent>
                <section class="row px-lg-5">
                    <div class="col-lg-6 input-content">
                        <label for="">First Name</label>
                        <input type="text" class="form-control"  aria-label="First name">
                    </div>
                    <div class="col-lg-6 input-content">
                        <label for="">Last Name</label>
                        <input type="text" class="form-control" aria-label="Last name">
                    </div>
                    <div class="col-lg-12 input-content">
                        <label for="inputEmail4" class="form-label">Mail</label>
                        <input type="email" class="form-control" id="inputEmail4">
                    </div>
                    <div class="col-12 input-content">
                        <label for="inputState" class="form-label">Country</label>
               
                            <select class="form-select" >
                            <option>Egypt
                            </option>
                            <option> UAE
                            </option>
                            <option>Much much much 
                            </option>
                        </select>    
                    </div>
                    
    
                    <div class="col-12 input-content">
                        <label for="phone" class="form-label">Phone</label>
                        <div class="phone">
                            <input type="text" class="form-control" >
                            <input type="tel" class="form-control" id="phone">
                        </div>
                      
                    </div>

                    <div class="col-12 input-content">
                        <label for="inputAddress" class="form-label">Address Line</label>
                        <input type="text" class="form-control" id="inputAddress" placeholder="1234 Main St">
                    </div>


                   
                </section>
                <div class="text-center">
                    <button type="submit" >Save and continue</button>
                </div>
               
            </form>
        </div>
    </div>
</template>