<template>
    <div class="overview-section pt-70 pb-35">
        <div class="container">
            <div class="section-title">
                <span>Our Process</span>
                <h2>Our Working  Process</h2>
                <div class="bar"></div>
            </div>

            <div class="row align-content-center justify-content-between mt-3">
                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content text-center">
                        <div class="icon">
                            <img src="../../assets/images/find-new-ideas.svg" alt="find new Ideas">
                        </div>

                        <h3>Discussion</h3>
                        <p>
                            Digital marketing experts at Misk meet with you to understand your business, needs, and digital marketing goals.
                        </p>
                        <div class="first-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                    
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/digital-analysis.svg" alt="find new Ideas">
                        </div>

                        <h3>Market Analysis</h3>
                        <p>
                            We analyze your market, identify competitors, study their online behavior, and determine their digital marketing strategies' strengths and weaknesses.
                        </p>
                        <div class="second-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/market-research.svg" alt="find new Ideas">
                        </div>

                        <h3>Strategy Development</h3>
                        <p>
                            Based on the previous steps, we develop digital marketing strategies that achieve your marketing goals.
                        </p>
                        <div class="third-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-4">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-creation.svg" alt="find new Ideas">
                        </div>

                        <h3>Action Plan</h3>
                        <p>
                            We create a detailed action plan that includes marketing channels and a timeline for your marketing campaigns.
                        </p>
                        <div class="forth-ellipse position-absolute">
                            <img src="../../assets/images/ellipse.svg" width="" height="" alt="ellipse shape">
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-6 mt-3 mt-lg-0">
                    <div class="overview-content">
                        <div class="icon">
                            <img src="../../assets/images/product-launching.svg" alt="find new Ideas">
                        </div>

                        <h3>Results Tracking</h3>
                        <p>
                            After implementing the action plan, we continuously measure the performance of the marketing campaigns, adjust strategies accordingly, and achieve the best results from your online marketing campaigns.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="default-shape">
            <div class="shape-1">
                <img src="../../assets/images/shapes/shape-4.png" alt="image">
            </div>

            <div class="shape-2 rotateme">
                <img src="../../assets/images/shapes/shape-5.svg" alt="image">
            </div>

            <div class="shape-3">
                <img src="../../assets/images/shapes/shape-6.svg" alt="image">
            </div>

            <div class="shape-4">
                <img src="../../assets/images/shapes/shape-7.png" alt="image">
            </div>

            <div class="shape-5">
                <img src="../../assets/images/shapes/shape-8.png" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DigitalProcess'
    }
</script>