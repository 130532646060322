<template>
    <header class="dev-services-heading services-bg-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-xl-9 ">
                    <div class="ui-line-before d-none d-lg-block"></div>
                    <div class="header-content">
                        <h1>Web Development</h1>
                        <p>
                            If you're looking for a professional and reliable partner to create a stunning website that truly reflects your brand and drives results, you've come to the right place. 
                        </p>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </header>
 
</template>
<script>
    export default{
        name:'DevHeader'
    }
</script>