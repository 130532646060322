<template>
    <div class="more-features-section  mt-lg-1">
        <div class="container">
            <section class="section-title">
                <span>Our values</span>
                <h2>Discover more about us</h2>
                <div class="bar"></div>
            </section>
            <div class="row">
                <section class="col-md-6 ">
                    <div class="more-features-content hover-effect-1">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="../../assets/images/vision-1.svg" alt="our vision icon" width="46" height="46">
                            <h3>OurVision</h3>
                        </div>
                        <p>
                            To revolutionize different areas of life by offering innovative and tailored technological solutions.
                        </p>
                    </div>
                </section>

                <section class=" col-md-6 ">
                    <div class="more-features-content hover-effect-1">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="../../assets/images/mission-1.svg" alt="our vision icon" width="46" height="46">
                            <h3>Our Mission</h3>
                        </div>
                        <p>
                            To assist many project owners incompeting strongly in their field through creative ideas and innovativesoftware solutions.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
       
    }
</script>