<template>
    <div>
        <Error />
    </div>
</template>

<script>
import { useHead } from '@vueuse/head'
    import Error from '../Error/Error'

    export default {
        name: 'ErrorPage',
        components: {
            Error,
        },
        setup(){
            useHead({
                title: 'Misk - Error Page',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>