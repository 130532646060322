<template>
    <div>
        <NavbarStyleTwo />
        <testmonial-header></testmonial-header>
        <PaginationTest/>
        <Footer />
    </div>
</template>

<script>
    import NavbarStyleTwo from '../Layout/NavbarStyleTwo'
    import PaginationTest from '../Testmonial/PaginationTest'
    import TestmonialHeader from '../Testmonial/TestmonialHeader'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'
    export default {
        name: 'TeamPage',
        components: {
            NavbarStyleTwo,
            PaginationTest, 
            TestmonialHeader,          
            Footer,     
        },
        setup(){
            useHead({
                title: 'Misk - Testmonials',
                meta :[
                    {
                        name: 'description',
                        content: 'About page '
                    }
                ]
            })
        }
    }
</script>