<template>
    <div>
        <section class="container growing pt-70 pb-70">
            <div class="row">
                <div class="col-lg-6 mt-4 mt-lg-0">
                    <span>Our Growing</span>
                    <h5>Our Projects In All The World</h5>
                    <p>
                        We provide lots of services that help you achieve your goals, such as mapping a strategy, creating a user-friendly product experience, delivering reliable platform solutions with on-boarding and staff training, UX Strategy and Consulting, Custom UI Creation,
                        <br>Minimal Viable Product, Branding, Graphical Assets
                    </p>
                </div>
                <div class="col-lg-6 mt-4 mt-lg-2 pt-lg-2 map-container">
                    <div class="position-relative">
                        <img src="../../assets/images/our-locations.svg" alt="World map" width="632" height="352">
                        <div class="map-location"><span class="small-circle"> </span></div>
                        <div class="map-location"><span class="small-circle"> </span></div>
                        <div class="map-location"><span class="small-circle"> </span></div>
                        <div class="map-location"><span class="small-circle"> </span></div>
                    </div>
                  
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>

</style>