<template>
    <section class="container pt-70 pb-100">
        <div class="challenge-container">
            <div class="row mt-3">
                <section class="col-lg-4 mb-5 mb-lg-0 text-center">
                    <img src="../../assets/images/albatny-screenshot.jpg" alt="screenshot of Elbalaty website" width="351" height="360">
                </section>
                <section class="col-lg-8 challenge-content">
                    <section>
                        <h4>Challenge</h4>
                        <p>
                            Our redesigning process included meeting with business owners across the United States, helping to form their strategy, and wireframing possible design directions. The 10Pearls team was also forming a branding and conversion strategy for the site, as well as working with the launch team to ensure go live and a consistent experience across the business and personal components of PayPal.com.
                        </p>
                    </section>
                    <section >
                        <h4>Solution</h4>
                        <p>
                            Our redesigning process included meeting with business owners across the United States, helping to form their strategy, and wireframing possible design directions. The 10Pearls team was also forming a branding and conversion strategy for the site, as well as working with the launch team to ensure go live and a consistent experience across the business and personal components of PayPal.com.
                        </p>
                    </section>
                </section>
            </div>
        </div>
    </section>
</template>