<template>
    <div class="container pt-70 pb-70">
        <section class="overview-app">
            <div class="text-center multi-step ">
                <button @click="showContent('button1')" :class="{ 'payment-btn': activeButton === 'button1' }">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <path d="M20.3163 31.8154L33.8288 18.3029L31.1455 15.6195L20.3163 26.4487L14.8538 20.9862L12.1705 23.6695L20.3163 31.8154ZM22.9997 42.1654C20.3483 42.1654 17.8566 41.6619 15.5247 40.655C13.1927 39.6494 11.1643 38.2841 9.43926 36.5591C7.71426 34.8341 6.34895 32.8056 5.34334 30.4737C4.33645 28.1418 3.83301 25.6501 3.83301 22.9987C3.83301 20.3473 4.33645 17.8556 5.34334 15.5237C6.34895 13.1918 7.71426 11.1633 9.43926 9.43828C11.1643 7.71328 13.1927 6.34734 15.5247 5.34045C17.8566 4.33484 20.3483 3.83203 22.9997 3.83203C25.6511 3.83203 28.1427 4.33484 30.4747 5.34045C32.8066 6.34734 34.8351 7.71328 36.5601 9.43828C38.2851 11.1633 39.6504 13.1918 40.656 15.5237C41.6629 17.8556 42.1663 20.3473 42.1663 22.9987C42.1663 25.6501 41.6629 28.1418 40.656 30.4737C39.6504 32.8056 38.2851 34.8341 36.5601 36.5591C34.8351 38.2841 32.8066 39.6494 30.4747 40.655C28.1427 41.6619 25.6511 42.1654 22.9997 42.1654Z" fill="#D3CFDA"/>
                        </svg>
                    </span>
                    <span>1-Personal Details</span>
                </button>
                <button @click="showContent('button2')" :class="{ 'payment-btn': activeButton === 'button2' }">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <path d="M20.3163 31.8154L33.8288 18.3029L31.1455 15.6195L20.3163 26.4487L14.8538 20.9862L12.1705 23.6695L20.3163 31.8154ZM22.9997 42.1654C20.3483 42.1654 17.8566 41.6619 15.5247 40.655C13.1927 39.6494 11.1643 38.2841 9.43926 36.5591C7.71426 34.8341 6.34895 32.8056 5.34334 30.4737C4.33645 28.1418 3.83301 25.6501 3.83301 22.9987C3.83301 20.3473 4.33645 17.8556 5.34334 15.5237C6.34895 13.1918 7.71426 11.1633 9.43926 9.43828C11.1643 7.71328 13.1927 6.34734 15.5247 5.34045C17.8566 4.33484 20.3483 3.83203 22.9997 3.83203C25.6511 3.83203 28.1427 4.33484 30.4747 5.34045C32.8066 6.34734 34.8351 7.71328 36.5601 9.43828C38.2851 11.1633 39.6504 13.1918 40.656 15.5237C41.6629 17.8556 42.1663 20.3473 42.1663 22.9987C42.1663 25.6501 41.6629 28.1418 40.656 30.4737C39.6504 32.8056 38.2851 34.8341 36.5601 36.5591C34.8351 38.2841 32.8066 39.6494 30.4747 40.655C28.1427 41.6619 25.6511 42.1654 22.9997 42.1654Z" fill="#D3CFDA"/>
                        </svg>
                    </span>
                   
                    <span> 2-Payment Methods</span>
                </button>
                <button @click="showContent('button3')" :class="{ 'payment-btn': activeButton === 'button3' }">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <path d="M20.3163 31.8154L33.8288 18.3029L31.1455 15.6195L20.3163 26.4487L14.8538 20.9862L12.1705 23.6695L20.3163 31.8154ZM22.9997 42.1654C20.3483 42.1654 17.8566 41.6619 15.5247 40.655C13.1927 39.6494 11.1643 38.2841 9.43926 36.5591C7.71426 34.8341 6.34895 32.8056 5.34334 30.4737C4.33645 28.1418 3.83301 25.6501 3.83301 22.9987C3.83301 20.3473 4.33645 17.8556 5.34334 15.5237C6.34895 13.1918 7.71426 11.1633 9.43926 9.43828C11.1643 7.71328 13.1927 6.34734 15.5247 5.34045C17.8566 4.33484 20.3483 3.83203 22.9997 3.83203C25.6511 3.83203 28.1427 4.33484 30.4747 5.34045C32.8066 6.34734 34.8351 7.71328 36.5601 9.43828C38.2851 11.1633 39.6504 13.1918 40.656 15.5237C41.6629 17.8556 42.1663 20.3473 42.1663 22.9987C42.1663 25.6501 41.6629 28.1418 40.656 30.4737C39.6504 32.8056 38.2851 34.8341 36.5601 36.5591C34.8351 38.2841 32.8066 39.6494 30.4747 40.655C28.1427 41.6619 25.6511 42.1654 22.9997 42.1654Z" fill="#D3CFDA"/>
                        </svg>
                    </span>
                    <span>Payment Summary</span>
                </button>
                <button @click="showContent('button4')" :class="{ 'payment-btn': activeButton === 'button4' }">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
                        <path d="M20.3163 31.8154L33.8288 18.3029L31.1455 15.6195L20.3163 26.4487L14.8538 20.9862L12.1705 23.6695L20.3163 31.8154ZM22.9997 42.1654C20.3483 42.1654 17.8566 41.6619 15.5247 40.655C13.1927 39.6494 11.1643 38.2841 9.43926 36.5591C7.71426 34.8341 6.34895 32.8056 5.34334 30.4737C4.33645 28.1418 3.83301 25.6501 3.83301 22.9987C3.83301 20.3473 4.33645 17.8556 5.34334 15.5237C6.34895 13.1918 7.71426 11.1633 9.43926 9.43828C11.1643 7.71328 13.1927 6.34734 15.5247 5.34045C17.8566 4.33484 20.3483 3.83203 22.9997 3.83203C25.6511 3.83203 28.1427 4.33484 30.4747 5.34045C32.8066 6.34734 34.8351 7.71328 36.5601 9.43828C38.2851 11.1633 39.6504 13.1918 40.656 15.5237C41.6629 17.8556 42.1663 20.3473 42.1663 22.9987C42.1663 25.6501 41.6629 28.1418 40.656 30.4737C39.6504 32.8056 38.2851 34.8341 36.5601 36.5591C34.8351 38.2841 32.8066 39.6494 30.4747 40.655C28.1427 41.6619 25.6511 42.1654 22.9997 42.1654Z" fill="#D3CFDA"/>
                        </svg>
                    </span>
                    <span>Approved</span>
                </button>
            </div>  
        
            <section v-if="activeButton === 'button1'" >
                <PersonalDetails></PersonalDetails>                
            </section>
            <section v-else-if="activeButton === 'button2'" >  
                <PaymentMethod></PaymentMethod>
            </section>
            <section v-if="activeButton === 'button3'" >
                <Summary></Summary>
                
            </section>
            <section v-else-if="activeButton === 'button4'" >  
                <Approved></Approved>
            </section>
        </section>
    </div>
</template>
<script>
import PaymentMethod from './PaymentMethod';
import PersonalDetails from './Personal';
import Summary from './Summary'
import Approved from './Approved'
    export default{
        components:{
          PaymentMethod,
          PersonalDetails,
           Summary,
           Approved
                
        },
        data(){
            return{
                activeButton: 'button1'
            }
        },
        methods: {
            showContent(button) {
            this.activeButton = button;
        }
    }
    }

</script>
<style scoped>
.payment-btn svg path{
        fill: #2FCC66
}
</style>