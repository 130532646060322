<template>
  <div class="login-section ptb-100 ">
    <div class="container">
      <div class="login-form">
        <div class="row">
          <div class="col-lg-6 text-center">
              <img
                src="../../assets/images/Images/sign-in-vector.webp"
                alt="log in vector"
                width="508"
                height="525"
              />
          </div>
          <div class="col-lg-6">
            <div class="login-title d-flex justify-content-center align-items-center">
            <h3>Sign In</h3>
            </div>
            <form>
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group">
                    <label> Email Address</label>
                    <input
                      type="email"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      class="form-control"
                    />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-check d-flex justify-content-start align-items-end">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="checkme"
                    />
                    <label class="form-check-label ms-2" for="checkme"
                      >Remember me</label
                    >
                  </div>
                </div>

                <div class="col-lg-12 text-right">
                  <p class="forgot-password">
                    <router-link to="/reset-password"> Forgot Password?</router-link>
                   
                  </p>
                </div>

                <div class="col-lg-12 d-flex justify-content-center align-items-center flex-column">
                  <div class="send-btn">
                    <button type="submit" class="default-btn">
                      Sign In
                      <span></span>
                    </button>
                  </div>
                  <br />
                  <span
                    >Don't have account?
                    <router-link to="/sign-up">Register</router-link></span
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="default-shape">
      <div class="shape-1">
        <img src="../../assets/images/shapes/shape-4.png" alt="image" />
      </div>

      <div class="shape-2 rotateme">
        <img src="../../assets/images/shapes/shape-5.svg" alt="image" />
      </div>

      <div class="shape-3">
        <img src="../../assets/images/shapes/shape-6.svg" alt="image" />
      </div>

      <div class="shape-4">
        <img src="../../assets/images/shapes/shape-7.png" alt="image" />
      </div>

      <div class="shape-5">
        <img src="../../assets/images/shapes/shape-8.png" alt="image" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
};
</script>
