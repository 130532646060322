<template>
    <div>
        <Navbar />
        <cloud-header></cloud-header>
        <Pricing/>
        <CloudFeatures></CloudFeatures>
        <CloudTransfer></CloudTransfer>
        <cloud-contact></cloud-contact>
        <Faq/>
        <Footer />
       
    </div>
</template>

<script>
    import Navbar from '../Layout/NavbarStyleTwo'
    import CloudHeader from '../Cloud/CloudHeader'
    import Pricing from '../Cloud/CloudPricing'
    import CloudFeatures from '../Cloud/CloudFeatures'
    import CloudTransfer from '../Cloud/CloudTransfer'
    import CloudContact from '../Cloud/CloudContact'
    import Faq from '../Cloud/Faq'
    import Footer from '../Layout/Footer'
    import { useHead } from '@vueuse/head'

    export default {
        name: 'CloudServers',
        components: {
            Navbar,
            CloudHeader,
            Pricing,
            CloudFeatures,
            CloudTransfer,
            CloudContact,
            Faq,
            Footer,
                
        },
        setup(){
            useHead({
                title: 'Misk - Cloud Servers',
                meta :[
                    {
                        name: 'description',
                        content: 'About page'
                    }
                ]
            })
        }
    }
</script>